// react
import React from "react";

// third-party
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";

// data stubs
import theme from "../../data/theme";

function SitePageReturns() {
    const breadcrumb = [
        { title: "Home", url: "/" },
        { title: "Returns and Refunds", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Returns and Refunds — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">
                                Returns and Refund Policy
                            </h1>
                        </div>
                        <div className="document__content typography">
                            <h2>Returns</h2>
                            <p>We do not accept returns. </p>

                            <h2>Refunds</h2>
                            <p>
                                {" "}
                                Only the insured amount is applicable for
                                refunds{" "}
                            </p>
                            <p>
                                {" "}
                                Refunds are applicable if all the following
                                conditions are true{" "}
                            </p>

                            <ul>
                                <li>
                                    Customer has selected shipping insurance at
                                    the time of order.
                                </li>
                                <li>
                                    Customer has mentioned the amount of
                                    insurance at the time of order.
                                </li>
                                <li>
                                    The package has lost during the shipping
                                    process, and customer has not received the
                                    package.
                                </li>
                            </ul>

                            <h2>Please note the following</h2>
                            <ul>
                                <li>
                                    We are not liable for any damage during the
                                    shipping process.
                                </li>
                                <li>
                                    We are not liable for any damage during the
                                    custom clearance process.
                                </li>
                                <li>We do not return damage products</li>
                                <li>
                                    We do not provide refunds for damage
                                    products
                                </li>
                            </ul>

                            <h2>Consent</h2>

                            <p>
                                By using our website, you hereby consent to our
                                Return and Refund Policy and agree to its terms.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageReturns;
