export default class Address{

    saveAddress = (address) =>{
        return fetch(process.env.REACT_APP_API + '/v1/address/create', {
            method : "POST", 
            headers: {
                "Content-Type": "application/json",
                "domain" : process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(address),
        })
        .then(response => response.json())          
        .then(address => {
            return address;
        })
        .catch(error => {
            return error;
        })  
    }

    getAddress = (address) =>{
        return fetch(process.env.REACT_APP_API + '/v1/address/create', {
            method : "POST", 
            headers: {
                "Content-Type": "application/json",
                "domain" : process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(address),
        })
        .then(response => response.json())          
        .then(address => {
            return address;
        })
        .catch(error => {
            return error;
        })  
    }

    updateUserAddress = (user, address) =>{
        return fetch(process.env.REACT_APP_API + '/v1/users/' + user, {
            method : "PATCH", 
            headers: {
                "Content-Type": "application/json",
                "domain" : process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(address),
        })
        .then(response => response.json())          
        .then(user => {
            return user;
        })
        .catch(error => {
            return error;
        })  
    }
}