import { CURRENCY_CHANGE, CURRENCY_INIT } from "./currencyActionTypes";
const log = require("../../components/shared/helper/MartLogger.js").logger;

/*const initialState = {
    code: process.env.REACT_APP_DEFAULT_CURRENCY_CODE || "PKR",
    symbol: process.env.REACT_APP_DEFAULT_CURRENCY_SYMBOL || "Rs",
    name: process.env.REACT_APP_DEFAULT_CURRENCY_NAME || "Pakistani Rupee",
};*/

const initialState = { code: "" };

export default function currencyReducer(state = initialState, action) {
    log("currency redux state", state, action.type, action.currency);
    if (
        action.type === CURRENCY_CHANGE &&
        state.code !== action.currency.code
    ) {
        return JSON.parse(JSON.stringify(action.currency));
    } else if (action.type === CURRENCY_INIT) {
        return JSON.parse(JSON.stringify(action.currency));
    }

    return state;
}
