import React from "react";
import {
  GoogleReCaptchaProvider,
  withGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const log = require("../helper/MartLogger.js").logger;

class ReCaptchaComponent extends React.Component {
  async componentDidMount() {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "homepage"
    );
    log("ReCaptchaComponent token", token);
  }

  render() {
    return null;
  }
}

export default withGoogleReCaptcha(ReCaptchaComponent);
