 // react
import React, {useState, useEffect} from 'react';

// third party
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import {Helmet} from "react-helmet";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

//Application
import paymentAPI from '../../services/payment';
import utilsAPI from '../../services/utils';



function SwedBankPay(props) {
    console.log("SwedBankPay state", props.location.state)
    const payment = new paymentAPI();
    const utils = new utilsAPI();
    const [src, setSrc] = useState("")
    const [url, setURL] = useState("")

    const {
        cart,
        address,
        locationName,
        currency,
    } = props;

    console.log("window.location.origin", `${window.location.origin}`)
    const params = queryString.parse(props.location.search)
    const parentOrderId = params.id;
    const payeeReference = params.payeeReference;
    const  validated= params.validated;
    console.log("params", params)
    console.log("swedbankpay cart", cart)
    const payload = {
      "payment": {
        "operation": "Purchase",
        "intent": "Authorization",
        "currency": "SEK",
        "prices": [{
                "type": "CreditCard",
                "amount": Number((cart.total * 100).toFixed(0)),
                "vatAmount": Number((cart.vat ? cart.vat * 100 : 0).toFixed(0)),
            }
        ],
        //"description": `Order# ${props.location.state.parentOrderId}`,
        "description": `Order# ${parentOrderId}`,
        "userAgent": navigator.userAgent,
        "language": "sv-SE",
        "urls": { 
            "hostUrls": [`${window.location.origin}`],
            "completeUrl": `${window.location.origin}/confirmation?id=${parentOrderId}`,
            "cancelUrl": `${window.location.origin}/payment-canceled`,
            "paymentUrl": `${window.location.origin}/payment/swedbankpay?validated=1`,
            "callbackUrl": `${process.env.REACT_APP_SWEDBANK_CALLBACK}`,
            "logoUrl": "https://localhost:3000/payment-logo.png",
            "termsOfServiceUrl": `https://local/payment-terms.pdf`,
        },
        "payeeInfo": {
            "payeeId": "53bb4c47-8376-4fc4-8b1a-2b5159c72dfe",
            "payeeReference": payeeReference,
            "payeeName": "Mjälloms Livs",
            "productCategory": "A1233",
            "orderReference": parentOrderId,
           // "orderReference": props.location.state.orderId,
            "subsite": "mySubsite"
        },
        "payer": {  
            "payerReference": "AB11111212",
        },
      },
    }
    useEffect(() => {
        if(validated === "1"){
            console.log("validated..", localStorage.getItem("swedbankViewPayment"))
            setSrc(localStorage.getItem("swedbankViewPayment"))
            setURL(<div id="swedbank-pay-seamless-view-page" />)
      }else{
        console.log("authorizing...")
        authorizePayment()  
      }
    }, []);

    const authorizePayment = async () =>{
        try{
            console.log("authorizePayment", payload )
            const response = await payment.swedBankPayCreatePayment({
                payload: payload
            })
            console.log("swedBankPayCreatePayment response", response)
            console.log("response.data.operations", response.data.operations)
            // to display the seemless view, we need to fetch the url
            response.data.operations.map((operation) => {
                if(operation.rel === 'view-authorization'){
                    setSrc(`${operation.href}`)
                    setURL(<div id="swedbank-pay-seamless-view-page" />)  
                }
                if(operation.rel === "view-payment"){
                    console.log("view-payment", operation.href)
                    localStorage.setItem('swedbankViewPayment', operation.href);
                }      
            })
            const transaction = await payment.createTransaction({
                paymentId : response.data.payment.id,
                parentOrderId : parentOrderId,
                gateway : 'SwedBankPay',
                currentPaymentStatus : response.data.payment.state,
                paymentDetail : response.data.payment,
                payeeReference : payeeReference,
            })
        } catch(error) {
            console.log("error authorizePayment", error)
        }
    }

    useEffect(() => {
        if(src){
            console.log("setting the script", src)
            const script = document.createElement("script");
            script.src = src
            script.id = "payment-page-script"
            script.async = true;
            document.body.appendChild(script);
            script.onload = () => {
                window.test()
            };
        }
    },[src])

    return (
        <React.Fragment>
            {url}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
    locationName: state.map.locationName,
    currency: state.currency,
});


export default connect(mapStateToProps, null)(withRouter(SwedBankPay));
