// react
import React, { useState, useEffect } from "react";

// third-party
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { withRouter } from "react-router-dom";

// data stubs
import theme from "../../data/theme";
import addressAPI from "../../services/address";
import utilsAPI from "../../services/utils";
const log = require("../shared/helper/MartLogger.js").logger;

function AccountPageAddress(props) {
    const a = new addressAPI();
    const utils = new utilsAPI();
    const [address, setAddress] = useState({
        customerLastName: "",
        customerFirstName: "",
        customerEmail: "",
        customerPhone: "",
        customerCity: "",
        customerCountry: "",
        customerState: "",
        customerPostcode: "",
        customerAddress: "",
        customerLat: "",
        customerLong: "",
    });
    if (props.location.state) {
        log("address ID", props.location.state.addressId);
    }

    //input change handle
    const inputChangeHandle = (e) => {
        address[e.target.id] = e.target.value;
        log("inputChangeHandle", address);
        setAddress(address);
    };
    //save address
    const handleAddressSave = () => {
        log("address to be saved", address);
        a.saveAddress(address)
            .then((response) => {
                log("address saved", response);
                const newAddress = {
                    address: [response.data[0]._id],
                };
                const user = utils.getUserFromLocalStorage();
                if (user == null) props.history.push("/account/login");

                log("localStorage user", user);
                user.address.map((existingAddress) => {
                    log("existingAddress", existingAddress);
                    newAddress.address.push(existingAddress._id);
                });
                //log("user token", JSON.parse(localStorage.getItem('fkToken')))
                log("user to be updated", user, newAddress);
                log("user id", user.id, newAddress);
                a.updateUserAddress(user.id, newAddress)
                    .then((user) => {
                        log("user updated", user);
                        localStorage.setItem("fkUser", JSON.stringify(user));

                        props.history.push("/account/addresses");
                    })
                    .catch((error) => {
                        log("error user updated address", error);
                    });
            })
            .catch((error) => {
                log("error in address save", error);
            });
    };

    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Shipping Address</h5>
            </div>
            <div className="card-divider" />
            <div className="card-body">
                <div className="row no-gutters">
                    <div className="col-12 col-lg-7 col-xl-6">
                        <div className="form-group">
                            <label htmlFor="profile-first-name">
                                First Name
                            </label>
                            <input
                                id="customerFirstName"
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                onChange={inputChangeHandle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Last Name</label>
                            <input
                                id="customerLastName"
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                                onChange={inputChangeHandle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-email">Email Address</label>
                            <input
                                id="customerEmail"
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                onChange={inputChangeHandle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-phone">Phone Number</label>
                            <input
                                id="customerPhone"
                                type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                onChange={inputChangeHandle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Address</label>
                            <input
                                id="customerAddress"
                                type="text"
                                className="form-control"
                                placeholder="Address"
                                onChange={inputChangeHandle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-last-name">City</label>
                            <input
                                id="customerCity"
                                type="text"
                                className="form-control"
                                placeholder="City"
                                onChange={inputChangeHandle}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="profile-last-name">Country</label>
                            <input
                                id="customerCountry"
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                onChange={inputChangeHandle}
                            />
                        </div>

                        <div className="form-group mt-5 mb-0">
                            <button
                                onClick={handleAddressSave}
                                type="button"
                                className="btn btn-primary"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(AccountPageAddress);
