// react
import React, { useRef } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg, Cross10Svg } from "../../svg";
import { cartRemoveItem } from "../../store/cart";
import utilsAPI from "../../services/utils";

const log = require("../shared/helper/MartLogger.js").logger;

function IndicatorCart(props) {
    const { cart, cartRemoveItem, address, currency } = props;
    let dropdown;
    let totals;
    const utils = new utilsAPI();
    const result = utils.splitCart(cart);
    const {
        cartQuantityTotal,
        cartItemsTotal,
        cartServiceFeeTotal,
        cartGrandTotal,
        cartShippingFeeTotal,
    } = utils.calculateCartSummary(result.splittedCart, currency);
    const indicatorRef = useRef();
    const close = () => {
        if (indicatorRef.current) {
            indicatorRef.current.close();
        }
    };

    if (cart.extraLines.length > 0) {
        const extraLines = cart.extraLines.map((extraLine, index) => (
            <tr key={index}>
                <th>{extraLine.title}</th>
                <td>
                    <Currency value={extraLine.price} />
                </td>
            </tr>
        ));

        totals = (
            <React.Fragment>
                <tr>
                    <th>Subtotal</th>
                    <td>
                        <Currency value={cart.subtotal} />
                    </td>
                </tr>
                {extraLines}
            </React.Fragment>
        );
    }

    const items = cart.items.map((item) => {
        let options;
        let image;

        const currency = {
            code: item.product.currency,
        };
        log("IndicatorCart item quantity", isNaN(item.quantity));

        if (isNaN(item.quantity)) item.quantity = 1;

        log("IndicatorCart item", item, item.product.merchant);

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li
                            key={index}
                        >{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link
                        to={{
                            pathname:
                                item.product.merchant === "ebay"
                                    ? `/department/ebay-usa/product/${item.product.id}`
                                    : `/shop/product/${item.product.itemCustomId}`,
                        }}
                        className="product-image__body"
                    >
                        <img
                            className="product-image__img"
                            src={item.product.images[0][0]}
                            alt=""
                        />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id, cart, item, address)}
                render={({ run, loading }) => {
                    const classes = classNames(
                        "dropcart__product-remove btn btn-light btn-sm btn-svg-icon",
                        {
                            "btn-loading": loading,
                        }
                    );

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link
                            to={{
                                pathname:
                                    item.product.merchant === "ebay"
                                        ? `/department/ebay-usa/product/${item.product.id}`
                                        : `/shop/product/${item.product.itemCustomId}`,
                            }}
                        >
                            {item.product.name}
                        </Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">
                            {item.quantity}
                        </span>
                        {" × "}
                        <span className="dropcart__product-price">
                            <Currency value={item.price} currency={currency} />
                        </span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    //if (cart.quantity) {
    if (cartQuantityTotal) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {/*totals*/}

                            <tr>
                                <th>
                                    <FormattedMessage
                                        id="cart.subTotal"
                                        defaultMessage="Subtotal"
                                    />
                                </th>
                                <td>
                                    <Currency value={cartItemsTotal} />
                                </td>
                            </tr>
                            {cartServiceFeeTotal !== 0 && (
                                <tr>
                                    <th>
                                        <FormattedMessage
                                            id="cart.serviceFee"
                                            defaultMessage="Service Fee"
                                        />
                                    </th>
                                    <td>
                                        <Currency value={cartServiceFeeTotal} />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th>
                                    <FormattedMessage
                                        id="cart.shipping"
                                        defaultMessage="Shipping"
                                    />
                                </th>
                                {cartShippingFeeTotal !== 0 && (
                                    <td>
                                        <Currency
                                            value={cartShippingFeeTotal}
                                        />
                                    </td>
                                )}
                                {cartShippingFeeTotal === 0 && (
                                    <td>+ 
                                    {" "}
                                    {
                                        process.env
                                            .REACT_APP_PER_KG_COST
                                    }
                       
                                    </td>
                                )}
                            </tr>
                            {/*
                            <tr>
                                <th>
                                    <FormattedMessage id="cart.vat" defaultMessage="Vat (Inclusive)"/>
                                </th>
                                <td><Currency value={cart.vat} /></td>
                            </tr>
                        */}
                            <tr>
                                <th>
                                    <FormattedMessage
                                        id="cart.total"
                                        defaultMessage="Total"
                                    />
                                </th>
                                <td>
                                    <Currency value={cartGrandTotal} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link
                        className="btn btn-secondary"
                        to="/shop/cart"
                        onClick={close}
                    >
                        <FormattedMessage
                            id="cart.view"
                            defaultMessage="View Cart"
                        />
                    </Link>
                    <Link
                        className="btn btn-primary"
                        to="/shop/checkout"
                        onClick={close}
                    >
                        <FormattedMessage
                            id="cart.checkout"
                            defaultMessage="Checkout"
                        />
                    </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                    Your shopping cart is empty!
                </div>
            </div>
        );
    }

    return (
        <Indicator
            ref={indicatorRef}
            url="/shop/cart"
            dropdown={dropdown}
            value={cartQuantityTotal}
            icon={<Cart20Svg />}
        />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
    currency: state.currency,
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
