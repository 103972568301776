const log = require('../components/shared/helper/MartLogger.js').logger;


export default class Categories{

    //to display on home page carousel
    getCategories = (callback, categoryId) =>{
        log("getCategories,",categoryId) 
        fetch(process.env.REACT_APP_API + (categoryId ? '/v1/cuisine/findchildren/' + categoryId : '/v1/cuisine'), {
            method : "GET", 
                headers: {
                    "Content-Type": "application/json",
                    "domain" : process.env.REACT_APP_DOMAIN,
                },
        })
        .then(response => response.json())
        .then(categories => {
            callback(categories);
        })
        .catch(error => {
            log("error",error);
        })  
    } 

    //to display on filters
    getCat = (categoryId) =>{
        log("categories.js getCat,",categoryId) 

        return fetch(process.env.REACT_APP_API + '/v1/cuisine/findchildren/' + categoryId, {
            method : "GET", 
                headers: {
                    "Content-Type": "application/json",
                    "domain" : process.env.REACT_APP_DOMAIN,
                },
        })
        .then(response => response.json())
        .then(categories => {
            return categories
        })
        .catch(error => {
            log("error",error);
            return error
        })  
    } 

    //to display on the home page left menu
    getDepartments = (callback, categoryId) =>{
        log("getDepartments,",categoryId) 
        
        fetch(process.env.REACT_APP_API + '/v1/cuisine/modifiedsearch/' + categoryId, {
            method : "GET", 
                headers: {
                    "Content-Type": "application/json",
                    "domain" : process.env.REACT_APP_DOMAIN,
                },
        })
        .then(response => response.json())
        .then(categories => {
            log("categories.js - response of getDepartments", categories)
            callback(categories);
        })
        .catch(error => {
            log("error in getDepartments",error);
        })  
    } 

    // return the tree structure for mobile menu
    getTree = (categoryId) =>{
        log("get nodes")
        log("process.env.api", process.env.REACT_APP_API)
        return fetch(process.env.REACT_APP_API + '/v1/cuisine/search/'+ categoryId, {
            method : "GET", 
                headers: {
                    "Content-Type": "application/json",
                    "domain" : process.env.REACT_APP_DOMAIN,
                },
        })
        .then(response => response.json())
        .then(categories => {
            return categories;
        })
        .catch(error => {
            log(error);
        })  
    } 

    // return the breadcrumb of category
    breadcrumb = (categoryId) =>{
        log("calling breadcrumb",categoryId)
        return fetch(process.env.REACT_APP_API + '/v1/cuisine/breadcrum/'+ categoryId, {
            method : "GET", 
                headers: {
                    "Content-Type": "application/json",
                    "domain" : process.env.REACT_APP_DOMAIN,
                },
        })
        .then(response => response.json())
        .then(data => {
            log("categories.js - breadcrumb result:", data)
            return data.breadcrumb;
        })
        .catch(error => {
            log("categories.js - breadcrumb error",error);
            return error
        })  
    } 

    // return the category id by url 
    getCategoryByURL = (url) =>{
        log("get category id by url", url)
        return fetch(process.env.REACT_APP_API + '/v1/cuisine/nodebyurl', {
            method : "POST", 
            body: JSON.stringify({url: url}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "domain" : process.env.REACT_APP_DOMAIN,
            },
        })
        .then(response => response.json())
        .then(data => {
            log("getCategoryByURL", data)
            return data;
        })
        .catch(error => {
            log(error);
        })  
    } 

    // return the flat categories based on parameters
    // isBestSeller, isFavorite, isFeatured, isPopular
    getCategoriesByParameters = (parameters) =>{
        log("get category id by parameters..", parameters)
        return fetch(process.env.REACT_APP_API + '/v1/cuisine/byfeature', {
            method : "POST", 
            body: JSON.stringify(parameters),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "domain" : process.env.REACT_APP_DOMAIN,
            },
        })
        .then(response => response.json())
        .then(data => {
            log("getCategoriesByParameters..", data)
            return data;
        })
        .catch(error => {
            log(error);
        })  
    } 

    // return the category based on node ids
    getNodesByIdsWithOutChild = (nodeIds) =>{
        log("getNodesByIdsWithOutChild", nodeIds)
        return fetch(process.env.REACT_APP_API + '/v1/cuisine/getNodesByIdsWithOutChild', {
            method : "POST", 
            body: JSON.stringify({nodes: nodeIds}),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "domain" : process.env.REACT_APP_DOMAIN,
            },
        })
        .then(response => response.json())
        .then(data => {
            log("result getNodesByIdsWithOutChild", data)
            return data;
        })
        .catch(error => {
            log("error in getNodesByIdsWithOutChild" , error);
        })  
    } 

    

}