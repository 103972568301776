import {
    POSTAL_CODE_VERIFIED,
    SET_DELIVERY_METHOD,
    SET_POSTAL_CODE,
    SET_DATE,
    SET_START_TIME,
    SET_END_TIME,
} from "./deliveryActionTypes";
const log = require("../../components/shared/helper/MartLogger.js").logger;

const initialState = {
    status: "not_verified",
    deliveryMethod: "store",
    postalCode: "",
    startTime: "",
    endTime: "",
    date: "",
};

export default function deliveryReducer(state = initialState, action) {
    if (action.type === POSTAL_CODE_VERIFIED) {
        log("delivery Reducer, POSTAL_CODE_VERIFIED", state);
        state.status = action.status;
        return {
            ...state,
        };
    } else if (action.type === SET_DELIVERY_METHOD) {
        log("delivery Reducer, SET_DELIVERY_METHOD", state);
        state.deliveryMethod = action.deliveryMethod;
        return {
            ...state,
        };
    } else if (action.type === SET_POSTAL_CODE) {
        log("delivery Reducer, SET_POSTAL_CODE", state);
        state.postalCode = action.postalCode;
        return {
            ...state,
        };
    } else if (action.type === SET_DATE) {
        log("delivery Reducer, SET_DATE", state);
        state.date = action.date;
        return {
            ...state,
        };
    } else if (action.type === SET_START_TIME) {
        log("delivery Reducer, SET_START_TIME", state);
        state.startTime = action.startTime;
        return {
            ...state,
        };
    } else if (action.type === SET_END_TIME) {
        log("delivery Reducer, SET_END_TIME", state);
        state.endTime = action.endTime;
        return {
            ...state,
        };
    } else {
        log("delivery Reducer, else", state);
        return state;
    }
}
