import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_RESET,
    CART_RECALCULATE_SHIPPING,
} from "./cartActionTypes";
import googleAnalyticsAPI from "../../services/googleAnalytics";
import ReactGA from "react-ga";
import utilsAPI from "../../services/utils";
const log = require("../../components/shared/helper/MartLogger.js").logger;

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
const googleAnalytics = new googleAnalyticsAPI();
const utils = new utilsAPI();

//send cart data to google analytics
const sendToGoogleAnalytics = (action, product, quantity) => {
    log("cartReducer - send to google", action, product, quantity);
    log("getUserFromLocalStorage", utils.getUserFromLocalStorage());
    const user = utils.getUserFromLocalStorage();
    const phone = user !== null ? user.phone : "";
    const name = user !== null ? user.fName : "";
    /*
    googleAnalytics.event({
        eventCategory: "cart",
        eventAction: action,
        eventLabel: product.name + " " + phone + " " + name,
        eventValue: quantity,
        path: window.location.pathname,
    });*/
    ReactGA.event({
        category: "cart",
        action: action,
        label: product.name + " " + phone + " " + name,
        value: quantity,
    });
};

function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (
            item.product.id !== product.id ||
            item.options.length !== options.length
        ) {
            return false;
        }

        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find(
                (itemOption) =>
                    itemOption.optionId === option.optionId &&
                    itemOption.valueId === option.valueId
            );

            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}

function calcShippingCost(shippingDetails) {
    log("calcShippingCost", shippingDetails);
    let totalShippingCost = 0;
    if (shippingDetails && shippingDetails.length) {
        // array and array.length are truthy
        // ⇒ probably OK to process array
        shippingDetails.map((branch, index) => {
            totalShippingCost += branch.shippingCost;
            log("branch", branch);
        });
    }
    return totalShippingCost;
}

//function calcSubtotal(items) {
//    return items.reduce((subtotal, item) => subtotal + item.total, 0);
//}

//function calcTotalVat(items) {
//    return items.reduce((totalVat, item) => totalVat + item.vat, 0);
//}

//function calcQuantity(items) {
//    return items.reduce((quantity, item) => quantity + item.quantity, 0);
//}

function calcTotal(subtotal, extraLines) {
    return (
        subtotal +
        extraLines.reduce((total, extraLine) => total + extraLine.price, 0)
    );
}

//recalculate shipping when location is updated by user
function recalculateShipping(state, shippingDetails) {
    log("recalculateShipping - state, shippingDetails", state, shippingDetails);
    const subtotal = utils.calcSubtotal(state.items);
    const totalShippingCost = calcShippingCost(shippingDetails);
    const totalServiceFee = utils.calcTotalServiceFee(shippingDetails);
    const total = subtotal + totalShippingCost + totalServiceFee;

    log(
        "subtotal, totalShippingCost, total",
        subtotal,
        totalShippingCost,
        total
    );
    return {
        ...state,
        subtotal,
        total,
        extraLines: [
            // shipping, taxes, fees, .etc
            {
                type: "shipping",
                title: "Shipping",
                price: totalShippingCost,
            },
            {
                type: "tax",
                title: "Tax",
                price: 0,
            },
        ],
        shippingDetails: shippingDetails,
        shippingCost: totalShippingCost,
        totalServiceFee: totalServiceFee,
    };
}

function addItem(state, product, options, quantity, shippingDetails) {
    const itemIndex = findItemIndex(state.items, product, options);
    log("cartReducer product", product);
    let newItems;
    let { lastItemId } = state;
    let finalPrice =
        product.salePrice && product.salePrice < product.price
            ? product.salePrice
            : product.price;
    const vat = utils.calcItemVat(finalPrice, product.vat);
    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [
            ...state.items,
            {
                id: lastItemId,
                product: JSON.parse(JSON.stringify(product)),
                options: JSON.parse(JSON.stringify(options)),
                price: finalPrice,
                total: finalPrice * quantity,
                branch: product.branch,
                vat: vat * quantity,
                quantity,
            },
        ];
    } else {
        const item = state.items[itemIndex];

        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
                vat: Number(((item.quantity + quantity) * vat).toFixed(2)),
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }
    const subtotal = utils.calcSubtotal(newItems);
    const totalVat = Number(utils.calcTotalVat(newItems).toFixed(2));
    const totalShippingCost = calcShippingCost(shippingDetails);
    //const total = calcTotal(subtotal, state.extraLines);
    const totalServiceFee = utils.calcTotalServiceFee(shippingDetails);
    const total = subtotal + totalShippingCost + totalServiceFee;
    log("total cost: ", total);
    sendToGoogleAnalytics("add", product, quantity);
    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: utils.calcQuantity(newItems),
        extraLines: [
            // shipping, taxes, fees, .etc
            {
                type: "shipping",
                title: "Shipping",
                price: totalShippingCost,
            },
            {
                type: "tax",
                title: "Tax",
                price: 0,
            },
            {
                type: "vat",
                title: "Vat",
                price: totalVat,
            },
        ],
        shippingDetails: shippingDetails,
        vat: totalVat,
        shippingCost: totalShippingCost,
        totalServiceFee: totalServiceFee,
    };
}

function removeItem(state, itemId, shippingDetails) {
    const { items } = state;
    const newItems = items.filter((item) => item.id !== itemId);
    const subtotal = utils.calcSubtotal(newItems);
    const totalVat = Number(utils.calcTotalVat(newItems).toFixed(2));
    const totalShippingCost = calcShippingCost(shippingDetails);
    //const total = calcTotal(subtotal, state.extraLines);
    const totalServiceFee = utils.calcTotalServiceFee(shippingDetails);
    const total = subtotal + totalShippingCost + totalServiceFee;

    return {
        ...state,
        items: newItems,
        quantity: utils.calcQuantity(newItems),
        subtotal,
        total,
        extraLines: [
            // shipping, taxes, fees, .etc
            {
                type: "shipping",
                title: "Shipping",
                price: totalShippingCost,
            },
            {
                type: "tax",
                title: "Tax",
                price: 0,
            },
        ],
        shippingDetails: shippingDetails,
        vat: totalVat,
        shippingCost: totalShippingCost,
        totalServiceFee: totalServiceFee,
    };
}

function resetCart(state) {
    log("cart reset", state, initialState);
    return initialState;
}

// updateQuantities is used when user update the quantity on
// cart page for a particular product.
function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find(
            (x) => x.itemId === item.id && x.value !== item.quantity
        );
        if (!quantity) {
            return item;
        }

        needUpdate = true;

        log("updateQuantities - item to be updated", item, quantity);
        const vat = utils.calcItemVat(item.price, item.product.vat);

        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
            vat: quantity.value * vat,
        };
    });
    log("updateQuantities - newItems", newItems);

    if (needUpdate) {
        const subtotal = utils.calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);
        const totalVat = Number(utils.calcTotalVat(newItems).toFixed(2));

        return {
            ...state,
            items: newItems,
            quantity: utils.calcQuantity(newItems),
            subtotal,
            total,
            vat: totalVat,
        };
    }

    return state;
}

/*
 * item example:
 * {
 *   id: 1,
 *   product: {...}
 *   options: [
 *     {optionId: 1, optionTitle: 'Color', valueId: 1, valueTitle: 'Red'}
 *   ],
 *   price: 250,
 *   quantity: 2,
 *   total: 500
 * }
 * extraLine example:
 * {
 *   type: 'shipping',
 *   title: 'Shipping',
 *   price: 25
 * }
 */
const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [
        // shipping, taxes, fees, .etc
        {
            type: "shipping",
            title: "Shipping",
            price: parseInt(process.env.REACT_APP_DEFAULT_SHIPPING_COST),
        },
        {
            type: "tax",
            title: "Tax",
            price: 0,
        },
        {
            type: "vat",
            title: "Vat",
            price: 0,
        },
    ],
    total: 0,
    shippingDetails: [],
    vat: 0,
    shippingCost: parseInt(process.env.REACT_APP_DEFAULT_SHIPPING_COST),
    totalServiceFee: 0,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(
                state,
                action.product,
                action.options,
                action.quantity,
                action.shippingDetails
            );

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId, action.shippingDetails);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        case CART_RESET:
            return resetCart(state);

        case CART_RECALCULATE_SHIPPING:
            return recalculateShipping(state, action.shippingDetails);

        default:
            return state;
    }
}
