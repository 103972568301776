import { LOCATIONVIEW_CLOSE, LOCATIONVIEW_OPEN } from './locationviewActionTypes';
const log = require('../../components/shared/helper/MartLogger.js').logger;


const initialState = {
    open: false,
};

export default function locationviewReducer(state = initialState, action) {
    log("locationviewReducer", state)
    let newState = state;

    if (action.type === LOCATIONVIEW_OPEN) {
        newState = {
            ...state,
            open: true,
        };
    } else if (action.type === LOCATIONVIEW_CLOSE) {
        newState = {
            ...state,
            open: false,
        };
    }

    return newState;
}