// react
import React, { useState, useEffect } from "react";

// third-party
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Waypoint } from "react-waypoint";

// application
import CategorySidebar from "./CategorySidebar";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import { sidebarClose } from "../../store/sidebar";
import ShopPageCategory from "./ShopPageCategory";
import SitePageContactUs from "../site/SitePageContactUs";

// data stubs
import MarttimeSwiper from "../shared/swiper/MarttimeSwiper";

import products from "../../data/shopProducts";
import theme from "../../data/theme";
import itemsAPI from "../../services/items";
import categoriesAPI from "../../services/categories";
import utilsAPI from "../../services/utils";
import googleAnalyticsAPI from "../../services/googleAnalytics";
const log = require("../shared/helper/MartLogger.js").logger;

function ShopPageSearch(props) {
    const {
        columns,
        viewMode,
        sidebarPosition,
        source,
        match,
        address,
        startPage,
    } = props;

    const { id } = props.match.params;
    const departmentId =
        props.location.state != undefined
            ? props.location.state.departmentId
            : null;
    const departmentName =
        props.location.state != undefined
            ? props.location.state.departmentName
            : null;
    const googleAnalytics = new googleAnalyticsAPI();
    log(
        "console - ShopPageSearch, props.location.state, address",
        props.location.state,
        address
    );
    log(
        "martlogger - ShopPageSearch, props.location.state, address",
        props.location.state,
        address
    );
    log(
        "ShopPageSearch, handle, departmentId, departmentName",
        id,
        departmentId,
        departmentName
    );

    const [breadcrumb, setBreadcrumb] = useState([{ title: "Home", url: "/" }]);
    let content;
    const [sideBarCategories, setSideBarCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [category, setCategory] = useState(null);
    const [pageDescription, setPageDescription] = useState(
        theme.longDescription
    );
    const [categoryName, setCategoryName] = useState(id);
    const [url, setUrl] = useState(null);
    const item = new itemsAPI();
    const categories = new categoriesAPI();
    const utils = new utilsAPI();
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [page, setPage] = useState(startPage);
    const [hasNextPage, setHasNextPage] = useState(false);
    const ITEMS_PER_PAGE = 12;

    log(
        "match, source",
        match,
        source,
        window.location,
        window.location.pathname
    );

    //load breadcrumb
    const loadBreadCrumb = (category) => {
        log("loadBreadCrumb", category);
        categories
            .breadcrumb(category)
            .then((response) => {
                log("returned breadcrumb", response);
                const data = response.map((item) => ({
                    url: item.hasOwnProperty("menuUrl") ? item.menuUrl : "",
                    title: item.hasOwnProperty("title") ? item.title : "",
                }));
                log("breadcrumb - product", data);
                setBreadcrumb(data);
                //  setCategoryName(data.slice(-1).pop().title)
            })
            .catch((error) => {
                log("ShopPageSearch - breadcrumb error", error);
            });
    };

    //infinite scroll
    const loadMoreData = () => {
        if (page > 1) {
            loadData()
                .then((response) => {
                    log("response of load more data", response);
                })
                .catch((error) => {
                    log("error in load more data -1", error);
                });
        }
    };

    useEffect(() => {
        log("setting the loading", hasNextPage);
    }, [hasNextPage]);

    // this effect will call everytime the component
    // render and set the value of url path
    useEffect(() => {
        setPage(1);
        setHasNextPage(true);
        setProducts([]);
        log("search query -- useEffect", match.params.id);
        setSearchQuery(match.params.id);
    }, [match.params.id]);

    const loadData = async () => {
        try {
            log("in loadData", searchQuery);
            const response = await item.searchItems(
                encodeURIComponent(searchQuery),
                address
                    ? address.latitude
                    : process.env.REACT_APP_DEFAULT_LATITUDE,
                address
                    ? address.longitude
                    : process.env.REACT_APP_DEFAULT_LONGITUDE,
                page,
                ITEMS_PER_PAGE
            );
            if (!response || response.length === 0) {
                throw Error(response);
            }
            log(
                "search total page, current page",
                response.count / ITEMS_PER_PAGE,
                page
            );
            if (page >= response.count / ITEMS_PER_PAGE) {
                setHasNextPage(false);
            }
            log("searchItems result", response);
            const product = utils.parseProductArray(response);
            log("parsed product search", product);
            setProducts((products) => [...products, product]);
            setPage((page) => page + 1);
            return Promise.resolve(product);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    };

    // this will call when there is a change in the searchQuery
    useEffect(() => {
        log("search query effect", searchQuery);
        //setPage(1)
        if (searchQuery !== null) {
            setLoading(true);
            loadData()
                .then((response) => {
                    log("useEffect - response data", response);
                    const distinctCategories = [
                        ...new Set(response.map((x) => x.category[0])),
                    ];
                    const itemDescriptions = [
                        ...new Set(response.map((x) => x.name)),
                    ];
                    log("distinctCategories", distinctCategories);
                    log("itemDescriptions", itemDescriptions);
                    setSideBarCategories(distinctCategories);
                    setPageDescription(itemDescriptions);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    setHasNextPage(false);
                    log("error in load more data -2", error);
                });
        }
    }, [searchQuery]);

    useEffect(() => {
        log("products useEffect", products);
    }, [products]);

    log("columns search page", columns);

    if (columns > 3) {
        //full width
        content = (
            <div className="container">
                <div className="block">
                    <ProductsView
                        products={products}
                        layout={viewMode}
                        grid={`grid-${columns}-full`}
                        limit={15}
                        offcanvas={offcanvas}
                        loading={loading}
                        searchQuery={searchQuery}
                    />
                </div>
                {products.length > 0 &&
                    products[0].length === 0 &&
                    loading === false && (
                        <div>
                            <div class="col-12">
                                <div
                                    class="alert alert-info mb-3"
                                    style={{ fontSize: 20, lineHeight: 1.3 }}
                                >
                                    No results for <b>{searchQuery}</b>. Try
                                    checking your spelling or share the product
                                    detail with us in the below form.
                                </div>
                                <SitePageContactUs embedded={true} />
                            </div>
                            <br />
                        </div>
                    )}
                {
                    <CategorySidebar
                        offcanvas={offcanvas}
                        category={
                            sideBarCategories.length > 0
                                ? sideBarCategories
                                : [process.env.REACT_APP_MENU_ID]
                        }
                        source={source}
                        loading={loading}
                    />
                }
            </div>
        );
    } else {
        //with sidebar
        const sidebar = (
            <div className="shop-layout__sidebar">
                {
                    <CategorySidebar
                        offcanvas={offcanvas}
                        category={
                            sideBarCategories.length > 0
                                ? sideBarCategories
                                : [process.env.REACT_APP_MENU_ID]
                        }
                        source={source}
                        loading={loading}
                    />
                }
            </div>
        );

        content = (
            <div className="container">
                <div
                    className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}
                >
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">
                            {products !== null && (
                                <ProductsView
                                    products={products}
                                    layout={viewMode}
                                    grid="grid-3-sidebar"
                                    limit={15}
                                    offcanvas={offcanvas}
                                    loading={loading}
                                    searchQuery={searchQuery}
                                />
                            )}
                        </div>
                        {
                            //products.length > 0 && loading===false &&
                            products.length > 0 &&
                                products[0].length === 0 &&
                                loading === false && (
                                    <div>
                                        <div class="col-12">
                                            <div
                                                class="alert alert-info mb-3"
                                                style={{ fontSize: 20 }}
                                            >
                                                No results for {searchQuery}.
                                                <br />
                                                <br />
                                                Try checking your spelling
                                                or&nbsp;
                                                <Link to="/site/contact-us">
                                                    CONTACT US
                                                </Link>
                                                {/*<a
                                                    href={
                                                        "https://api.whatsapp.com/send?phone=" +
                                                        process.env
                                                            .REACT_APP_WHATSAPP +
                                                        "&text=Hi there.."
                                                    }
                                                    target="_blanks"
                                                    style={{ color: "blue" }}
                                                >
                                                    contact us
                                                </a>*/}
                                                , and we will help you find your
                                                product.
                                            </div>
                                            <SitePageContactUs />
                                        </div>
                                        <br />
                                        <MarttimeSwiper
                                            category={
                                                process.env.REACT_APP_MENU_ID
                                            }
                                            layout="grid-4"
                                            title="Find out more products"
                                            rows={1}
                                            categoryUrl="/"
                                            slidesPerView={3.4}
                                        />
                                    </div>
                                )
                        }
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${searchQuery} - ${theme.name}`}</title>
                <meta
                    name="description"
                    content={`${searchQuery} - ${pageDescription}`}
                />
                <meta
                    property="og:title"
                    content={`${searchQuery} - ${theme.name}`}
                />
                <meta
                    property="og:description"
                    content={`${searchQuery} - ${pageDescription}`}
                />
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_WEBSITE_URL}${window.location.pathname}`}
                />
                <meta
                    property="og:image"
                    content={`${process.env.REACT_APP_WEBSITE_LOGO}`}
                />
                <meta
                    property="fb:app_id"
                    content={`${process.env.REACT_APP_FACEBOOK_APPID}`}
                />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary"></meta>
            </Helmet>

            <PageHeader header={searchQuery} breadcrumb={breadcrumb} />

            {content}
            {hasNextPage && (
                <div
                    className="container"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "grey",
                    }}
                >
                    <Waypoint onEnter={loadMoreData}>
                        <h4>
                            {" "}
                            Loading more products &nbsp;
                            <i
                                className="fa fa-spinner fa-spin"
                                style={{ color: "black" }}
                            ></i>
                        </h4>
                    </Waypoint>
                </div>
            )}
            {!hasNextPage && (
                <ShopPageCategory
                    columns={4}
                    viewMode="grid"
                    sidebarPosition="start"
                    source="shop"
                    isShopPageCategoryEmbedded={true}
                    title="Items you may like"
                />
            )}
        </React.Fragment>
    );
}

ShopPageSearch.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageSearch.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    address: state.map.address,
});

const mapDispatchToProps = {
    sidebarClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageSearch);
