import {
	MAP_SET_ADDRESS,
	MAP_SET_LOCATIONNAME,
	MAP_SET_COUNTRY,
} from "./mapActionTypes";

const log = require("../../components/shared/helper/MartLogger.js").logger;

export const setAddress = (address = null) => {
	log("action - setAddress", address);
	return {
		type: MAP_SET_ADDRESS,
		address: address,
	};
};

export const setLocationName = (locationName = null) => {
	log("action - setLocationName", locationName);
	return {
		type: MAP_SET_LOCATIONNAME,
		locationName: locationName,
	};
};

export const setCountry = (country = "US") => {
	log("action - setCountry", country);
	return {
		type: MAP_SET_COUNTRY,
		country: country,
	};
};
