// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';


function AppLink(props) {
    const { external, children, ...otherProps } = props;
//    console.log("AppLink - otherProps", otherProps)
    let link;
    if (external) {
        const {
            to,
            replace,
            innerRef,
            ...linkProps
        } = otherProps;

  //      console.log("external- to, linkProps", to, linkProps )
        link = <a href={to} {...linkProps}>{children}</a>;
    } else {
 //       console.log("not external", otherProps)
        link = <Link {...otherProps}>{children}</Link>;
       
    }
//    console.log("link::", link, link.props.to)
    return link;
}

AppLink.propTypes = {
    to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
            hash: PropTypes.string,
            state: PropTypes.any,
        }),
    ]).isRequired,
    replace: PropTypes.bool,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
    ]),
    external: PropTypes.bool,
};
AppLink.defaultProps = {
    external: false,
};

export default AppLink;
