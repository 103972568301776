// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import Indicator from "../header/Indicator";
import {
    Menu18x14Svg,
    LogoSmallSvg,
    Search20Svg,
    Heart20Svg,
    Cart20Svg,
} from "../../svg";
import { mobileMenuOpen } from "../../store/mobile-menu";
import { locationviewOpen } from "../../store/locationview/locationviewActions";
import Search from "../header/Search";
import logo from "../../svg/logo.png";
import LocationView from "../shared/location/LocationView";
import { logout } from "../../store/auth/authActions";
import { auth } from "../../configs/firebase.js";
import { Person20Svg } from "../../svg";
import InstallPWA from "../shared/InstallPWA";
import utilsAPI from "../../services/utils";

const log = require("../shared/helper/MartLogger.js").logger;

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
        this.utils = new utilsAPI();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (
            searchOpen &&
            searchOpen !== prevState.searchOpen &&
            this.searchInput.current
        ) {
            this.searchInput.current.focus();
        }
    }

    handleOpenLocationView = (event) => {
        event.preventDefault();
        log("handleOpenLocationView");
        this.props.locationviewOpen();
    };

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    handlePWA = () => {
        this.setState(() => ({ searchOpen: false }));
    };

    handleLogout = (event) => {
        log("logout", event);

        this.props.logout(); //calling redux
        auth.signOut().then((result) => {
            log("signout from firebase");
        });
        this.props.history.push("/account/login"); //move page to clicked link
    };

    render() {
        const {
            openMobileMenu,
            wishlist,
            cart,
            locationviewOpen,
            locationName,
        } = this.props;
        log("mobileHeader props", this.props);
        const { searchOpen } = this.state;
        const searchClasses = classNames("mobile-header__search", {
            "mobile-header__search--open": searchOpen,
        });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel-wrapper">
                    <div className="mobile-header__panel">
                        <div className="container">
                            <div className="mobile-header__body">
                                <button
                                    type="button"
                                    className="mobile-header__menu-button"
                                    onClick={openMobileMenu}
                                >
                                    <Menu18x14Svg />
                                </button>
                                <Link
                                    to="/"
                                    className="mobile-header__logo"
                                    background-color="lightblue"
                                >
                                    <img
                                        src={
                                            process.env
                                                .REACT_APP_WEBSITE_LOGO_SMALL
                                                ? process.env
                                                      .REACT_APP_WEBSITE_LOGO_SMALL
                                                : process.env
                                                      .REACT_APP_WEBSITE_LOGO
                                        }
                                        width="100%"
                                        height="100%"
                                        alt="logo"
                                    />
                                    {/*<LogoSmallSvg />*/}
                                </Link>
                                <Search
                                    context="mobile-header"
                                    className={searchClasses}
                                    inputRef={this.searchInput}
                                    onClose={this.handleCloseSearch}
                                />
                                <div className="mobile-header__indicators">
                                    {/*<InstallPWA />*/}
                                    <Indicator
                                        className="indicator--mobile indicator--mobile-search d-md-none"
                                        onClick={this.handleOpenSearch}
                                        icon={<Search20Svg />}
                                    />
                                    <Indicator
                                        className="indicator--mobile d-sm-flex d-none"
                                        url="/shop/wishlist"
                                        value={wishlist.length}
                                        icon={<Heart20Svg />}
                                    />
                                    <Indicator
                                        className="indicator--mobile"
                                        url="/shop/cart"
                                        value={cart.quantity}
                                        icon={<Cart20Svg />}
                                    />

                                    {this.props.user ? (
                                        <Indicator
                                            className="indicator--mobile"
                                            //  onClick={this.handleLogout}
                                            url="/account/orders"
                                            icon={<Person20Svg />}
                                            //   icon={<i className="fas fa-sign-out-alt" style={{ fontSize: '20px' }} />}
                                        />
                                    ) : (
                                        <Indicator
                                            className="indicator--mobile"
                                            url="/account/login"
                                            icon={<Person20Svg />}
                                            //  icon={<i className="fas fa-sign-in-alt" style={{ fontSize: '20px' }} />}
                                        />
                                    )}
                                    {/*
                                    <Indicator
                                        url="/site/contact-us"
                                        icon={
                                            <i class="far fa-comment-dots fa-lg"></i>
                                        }
                                    />
                                */}
                                    {
                                        <a
                                            href="https://api.whatsapp.com/send?phone=923033330902&text=Hi there.."
                                            target="_blanks"
                                        >
                                            <Indicator
                                                className="indicator--mobile"
                                                icon={
                                                    <i
                                                        className="fab fa-whatsapp"
                                                        style={{
                                                            fontSize: "23px",
                                                            color: "white",
                                                        }}
                                                    />
                                                }
                                            />
                                        </a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div> Delivering to: </div>
                    <Link to="" onClick={this.handleOpenLocationView}>
                        {locationName ? (
                            this.utils.truncateString(locationName, 40)
                        ) : (
                            <FormattedMessage id="topbar.choose" />
                        )}
                    </Link>
                </div>

                <LocationView />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
    locationName: state.map.locationName,
    token: state.auth.token,
    user: state.auth.user,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
    locationviewOpen: locationviewOpen,
    logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
