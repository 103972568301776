import { productPageUrl } from "../url";
const log = require("../components/shared/helper/MartLogger.js").logger;

export default class Utils {
    extractBeforePipe = (input) => {
        // Find the position of the pipe character
        const pipeIndex = input.indexOf("|");

        // If the pipe character is not found, return the original string
        if (pipeIndex === -1) {
            return input;
        }

        // Extract and return the substring before the pipe character
        return input.substring(0, pipeIndex);
    };

    extractBeforeGreaterThan = (input) => {
        // Find the position of the greater than character
        const greaterThanIndex = input.indexOf(">");

        // If the greater than character is not found, return the original string
        if (greaterThanIndex === -1) {
            return input;
        }

        // Extract and return the substring before the greater than character
        return input.substring(0, greaterThanIndex);
    };
    reCaptchaVerification = (token) => {
        return fetch(
            `${process.env.REACT_APP_API}/v1/utility/recaptcha/siteverify`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify({
                    response: token,
                }),
            },
        )
            .then((response) => response.json())
            .then((parsedJSON) => {
                return parsedJSON;
            })

            .catch((error) => {
                log("reCaptchaVerification error", error);
                return error;
            });
    };

    getBrandOfProduct = (nodes) => {
        log("getBrandOfProduct", nodes);
        var result;
        nodes.some(function (node) {
            if (
                node.hasOwnProperty("nodeType") &&
                node.nodeType.length > 0 &&
                node.nodeType[0].title.toUpperCase() === "BRAND"
            ) {
                result = {
                    id: node._id,
                    title: node.title,
                };
                return true;
            }
        });
        return result;
    };

    parseProductArray = (response) => {
        log("utils - products array to be parsed", response);
        if (response.finalSearchedItem !== undefined) {
            const products = response.finalSearchedItem.map((item) => {
                return this.parseProduct(item);
            });
            log("utils - return product array", products); //remove empty products from array
            return products.filter((n) => n);
        } else return null;
    };

    //calculate the product price
    calcProductPrice = (price, itemObj) => {
        let finalPrice = price;
        if (
            itemObj.branch.hasOwnProperty("costPerWeightUnit") &&
            itemObj.item.hasOwnProperty("weight") &&
            itemObj.branch.costPerWeightUnit &&
            itemObj.item.weight &&
            process.env.REACT_APP_INCLUDE_PRODUCT_WEIGHT_PRICE_IN_SHIPPING.toUpperCase() ===
                "NO"
        ) {
            finalPrice =
                price + itemObj.branch.costPerWeightUnit * itemObj.item.weight; //add weight based shipping price in product
        }
        return finalPrice;
    };

    parseProduct = (item) => {
        log("parseProduct", item);
        try {
            const product = {
                id: item.hasOwnProperty("_id") ? item._id : "",
                itemId:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("_id")
                        ? item.item._id
                        : "",
                name:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("itemTitle")
                        ? item.item.itemTitle
                        : "",
                price: item.hasOwnProperty("price")
                    ? this.calcProductPrice(item.price, item)
                    : 0,
                compareAtPrice: item.hasOwnProperty("price")
                    ? this.calcProductPrice(item.price, item)
                    : 0,
                salePrice: item.hasOwnProperty("salePrice")
                    ? this.calcProductPrice(item.salePrice, item)
                    : 0,
                images:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("itemImages")
                        ? [item.item.itemImages.md]
                        : null,
                availability: "in-stock",
                badges: [],
                category:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("category") &&
                    item.item.category.length !== 0
                        ? item.item.category
                        : null,
                categoryName:
                    item.hasOwnProperty("category") && item.category.length != 0
                        ? item.category[0].name
                        : null,
                business:
                    item.hasOwnProperty("business") && item.business != null
                        ? item.business._id
                        : null,
                description:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("itemDescription")
                        ? item.item.itemDescription
                        : "",
                weight:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("weight")
                        ? item.item.weight
                        : "",
                weightScale:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("weightScale")
                        ? item.item.weightScale
                        : "",
                branch:
                    item.hasOwnProperty("branch") &&
                    item.branch.hasOwnProperty("_id")
                        ? item.branch._id
                        : "",
                currency:
                    item.hasOwnProperty("branch") &&
                    item.branch.hasOwnProperty("currency")
                        ? item.branch.currency
                        : "",
                brand: this.getBrandOfProduct(item.item.category),
                regularPrice:
                    item.hasOwnProperty("regularPrice") &&
                    item.regularPrice.length !== 0
                        ? this.calcProductPrice(
                              item.regularPrice[0].value,
                              item,
                          )
                        : null,
                discountPrice:
                    item.hasOwnProperty("discountPrice") &&
                    item.discountPrice.length !== 0
                        ? this.calcProductPrice(
                              item.discountPrice[0].value,
                              item,
                          )
                        : null,
                vat: item.hasOwnProperty("vat") ? item.vat : 0,
                productFacts:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("productFacts")
                        ? item.item.productFacts
                        : "",
                productSpecs:
                    item.hasOwnProperty("item") &&
                    item.item.hasOwnProperty("productSpecs")
                        ? item.item.productSpecs
                        : "",
                itemCustomId: item.hasOwnProperty("itemCustomId")
                    ? item.itemCustomId
                    : "",
                itemWebUrl: `${window.location.protocol}//${window.location.hostname}${productPageUrl}${item.itemCustomId}`,
                itemUrl: `${productPageUrl}${item.itemCustomId}`,
            };
            log("item after parsing", product);
            return product;
        } catch (error) {
            log("error in parseProduct", error);
            return null;
        }
    };

    getUserFromLocalStorage = () => {
        const user = JSON.parse(localStorage.getItem("fkUser"));
        log("utils.localStorage", user);
        if (user != null && user.hasOwnProperty("code") && user.code == 404) {
            localStorage.removeItem("fkToken");
            localStorage.removeItem("fkUser");
        }
        return user;
    };

    // Random number from 0 to length
    randomNumber = (length) => {
        return Math.floor(Math.random() * length);
    };

    // Generate Pseudo Random String, if safety is important use dedicated crypto/math library for less possible collisions!
    generateID = (length) => {
        const possible = "0123456789";
        let text = "";

        for (let i = 0; i < length; i++) {
            text += possible.charAt(this.randomNumber(possible.length));
        }
        return text;
    };

    endPageLoading = () => {
        const preloader = document.querySelector(".site-preloader");
        preloader.addEventListener("transitionend", (event) => {
            if (event.propertyName === "opacity") {
                preloader.parentNode.removeChild(preloader);
            }
        });
        preloader.classList.add("site-preloader__fade");
    };

    addressFromLatLng = (center) => {
        const geocoder = new window.google.maps.Geocoder();
        return new Promise(function (resolve, reject) {
            geocoder.geocode({ location: center }, function (results, status) {
                if (status === "OK") {
                    if (results[0]) {
                        log("utils - addressFromLatLng ", results[0]);
                        const address = {
                            formattedAddress: results[0].formatted_address,
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                        };
                        resolve(address);
                    } else {
                        reject(new Error("Couldnt't find the location "));
                    }
                } else {
                    reject(new Error("Geocoder failed due to: " + status));
                }
            });
        });
    };

    getCurrentPosition = () => {
        log("utils getCurrentPosition");

        return new Promise(function (resolve, reject) {
            navigator.geolocation.getCurrentPosition(
                successCallback,
                errorCallback,
                { maximumAge: Infinity },
            );
            function successCallback(position) {
                var center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                log("getCurrentPosition - successCallback", center);
                resolve(center);
            }
            function errorCallback(error) {
                log("getCurrentPosition - error", error);
                reject(new Error(error));
            }
        });
    };

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    // return distinct branches from cart products
    getDistinctBranches = (cart) => {
        let branches = [];
        log("getDistinctBranches", cart);
        if (cart === undefined || cart.length === 0) return branches;
        cart.items.map((item, index) => {
            branches.push(item.branch);
        });
        branches = Array.from(new Set(branches));
        return branches;
    };

    // add a product in cart and return distinct branches
    getDistinctBranchesAfterAddingProduct = (cart, product) => {
        let branches = [];
        if (cart === undefined || cart.length === 0) return [product.branch];
        log("product to be added", product);
        cart.items.map((item, index) => {
            log("item in cart1", item);
            branches.push(item.branch);
        });
        log("product.branch", product.branch);
        branches.push(product.branch); //adding the branch of product
        branches = Array.from(new Set(branches));
        log("filtered branches", branches);
        return branches;
    };

    //remove a product from cart and return distinct branches
    getDistinctBranchesAfterRemovingProduct = (cart, product) => {
        let branches = [];
        log("cart before removing items", cart.items);
        const cartItems = cart.items.filter(
            (item) => item.product.id !== product.product.id,
        );
        log("cart after removing items", cartItems);
        if (cartItems.length === 0) return branches;
        cartItems.map((item, index) => {
            branches.push(item.branch);
        });

        branches = Array.from(new Set(branches));
        // branches = branches.filter(item => item !== product.branch) // removing branch of product
        return branches;
    };

    getDistinctCategoriesFromCart = (cart) => {
        let categories = [];
        cart.items.map((item, index) => {
            log("categories -> ", item.product.categoryId);
            item.product.categoryId.map((item, index) => {
                categories.push(item);
            });
        });
        log("categories----->", categories);
    };

    calculateDistanceBetweenUserAndBranch = (
        branchId,
        userLatitude = process.env.REACT_APP_DEFAULT_LATITUDE,
        userLongitude = process.env.REACT_APP_DEFAULT_LONGITUDE,
    ) => {
        log(
            "branchid, userLatitude, userLongitude",
            branchId,
            userLatitude,
            userLongitude,
        );
        return fetch(
            process.env.REACT_APP_API +
                "/v1/branch/getDistance/" +
                branchId +
                "/" +
                userLatitude +
                "/" +
                userLongitude,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            },
        )
            .then((response) => response.json())
            .then((distance) => {
                return distance;
            })
            .catch((error) => {
                log("error in calculateDistanceBetweenUserAndBranch", error);
                return error;
            });
    };

    // get distinct category from parsed products
    getDistinctCategoriesFromProducts = (products, value) => {
        log("getDistinctCategoriesFromProducts - products", products);
        let categories = [];
        products.map((item) => {
            item.category.map((category) => {
                categories.push(category);
            });
        });
        log("getDistinctCategoriesFromProducts - categories", categories);
        const distinctCategories =
            value === "title"
                ? [...new Set(categories.map((x) => x.title))]
                : [...new Set(categories.map((x) => x._id))];
        log("distinct categories -1", distinctCategories);
        return distinctCategories;
    };

    //remove the last slash from the url if there is a slash
    parseCategoryURL = (url) => {
        return url.replace(/\/+$/, "");
    };

    //split cart into multiple orders based on unique branches
    splitCart = (cart, currentCurrency) => {
        log("utils cart", cart);
        let splittedCart = [];
        cart.shippingDetails.map((branch) => {
            let branchwise = {
                items: [],
                shipping: branch,
                subtotal: 0,
                total: 0,
                quantity: 0,
                vat: 0,
                reserveFee: 0,
                currency: branch.currency,
                shippingCostIncludingWeightCost: 0,
            };
            cart.items.map((item) => {
                log("splitcart, item", item.branch, branch.branchId);
                if (item.branch == branch.branchId) {
                    branchwise.items.push(item);
                }
            });
            branchwise.weight = this.calcWeight(branchwise.items);
            log("weight of the cart items", branchwise.weight);
            branchwise.quantity = this.calcQuantity(branchwise.items);
            branchwise.subtotal = this.calcSubtotal(branchwise.items);
            branchwise.vat = this.calcTotalVat(branchwise.items);
            branchwise.reserveFee = Number(
                (
                    branchwise.subtotal *
                    (branch.reserveFeeInPercent
                        ? branch.reserveFeeInPercent / 100
                        : 0)
                ).toFixed(2),
            );

            // Include international shipping cost per pound if costPerWeightUnit is available
            branchwise.shippingCostIncludingWeightCost =
                process.env
                    .REACT_APP_INCLUDE_PRODUCT_WEIGHT_PRICE_IN_SHIPPING &&
                process.env.REACT_APP_INCLUDE_PRODUCT_WEIGHT_PRICE_IN_SHIPPING.toUpperCase() ===
                    "YES" &&
                branch.hasOwnProperty("costPerWeightUnit") &&
                branch.hasOwnProperty("shippingCost")
                    ? branch.shippingCost +
                      branch.costPerWeightUnit * branchwise.weight
                    : branch.shippingCost !== null
                    ? branch.shippingCost
                    : 0;
            log(
                "international shipping, weight, cost per pound",
                branchwise.weight,
                branch.costPerWeightUnit,
                branchwise.shippingCostIncludingWeightCost,
                branch.shippingCost,
            );
            branchwise.total = Number(
                (
                    (branchwise.subtotal ? branchwise.subtotal : 0) +
                    (branchwise.shippingCostIncludingWeightCost
                        ? branchwise.shippingCostIncludingWeightCost
                        : 0) +
                    //(branch.shippingCost ? branch.shippingCost : 0) +
                    (branch.serviceFee ? branch.serviceFee : 0) +
                    (branchwise.reserveFee ? branchwise.reserveFee : 0)
                ).toFixed(2),
            );
            splittedCart.push(branchwise);
        });

        log("utils - new splittedCart", splittedCart);
        return {
            splittedCart,
        };
    };

    //this function will calculate the cart totals based on current selected currency and cart
    calculateCartSummary = (splittedCart, currentCurrency) => {
        const cartItemsTotal = this.calcCartItemsTotal(
            splittedCart,
            currentCurrency,
        );

        const cartCustomDuty = process.env.REACT_APP_APPROX_CUSTOM_DUTY
            ? Math.round(
                  cartItemsTotal *
                      Number(process.env.REACT_APP_APPROX_CUSTOM_DUTY),
              )
            : 0;

        log("customDuty", cartCustomDuty);
        const cartReserveFeeTotal = this.calcCartReserveFeeTotal(
            splittedCart,
            currentCurrency,
        );
        const cartShippingFeeTotal = this.calcCartShippingFeeTotal(
            splittedCart,
            currentCurrency,
        );
        const cartServiceFeeTotal = this.calcCartServiceFeeTotal(
            splittedCart,
            currentCurrency,
        );
        const cartItemsVatTotal = this.calcCartItemsVatTotal(
            splittedCart,
            currentCurrency,
        );
        const cartServiceFeeVatTotal = this.calcCartServiceFeeVatTotal(
            splittedCart,
            currentCurrency,
        );

        let cartServiceFeeVatPercentage = Number(
            ((cartServiceFeeVatTotal / cartServiceFeeTotal) * 100).toFixed(0),
        );
        cartServiceFeeVatPercentage = cartServiceFeeVatPercentage || 0;

        let cartItemsVatPercentage = Number(
            ((cartItemsVatTotal / cartItemsTotal) * 100).toFixed(0),
        );
        cartItemsVatPercentage = cartItemsVatPercentage || 0;

        const cartReserveFeePercentage = Number(
            ((cartReserveFeeTotal / cartItemsTotal) * 100).toFixed(0),
        );
        const cartGrandTotal = Number(
            (
                cartItemsTotal +
                cartReserveFeeTotal +
                cartShippingFeeTotal +
                cartServiceFeeTotal
            ).toFixed(2),
        );
        const cartQuantityTotal = this.calcCartQuantityTotal(splittedCart);

        return {
            cartItemsTotal,
            cartReserveFeeTotal,
            cartShippingFeeTotal,
            cartServiceFeeTotal,
            cartItemsVatTotal,
            cartServiceFeeVatTotal,
            cartServiceFeeVatPercentage,
            cartItemsVatPercentage,
            cartReserveFeePercentage,
            cartGrandTotal,
            cartQuantityTotal,
            cartCustomDuty,
        };
    };

    //calculate sum of quantity of each branch
    calcCartQuantityTotal = (splittedCart) => {
        const result = splittedCart.reduce(
            (totalQuantity, branch) =>
                branch.quantity
                    ? totalQuantity + branch.quantity
                    : totalQuantity + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    //calculate sum of service fee vat
    calcCartServiceFeeVatTotal = (splittedCart, currentCurrency) => {
        const result = splittedCart.reduce(
            (total, branch) =>
                branch.shipping.serviceFee &&
                branch.shipping.vatServiceFeeInPercent
                    ? total +
                      this.convertCurrency(
                          currentCurrency,
                          { code: branch.currency },
                          branch.shipping.serviceFee,
                      ) *
                          (branch.shipping.vatServiceFeeInPercent / 100)
                    : total + 0,
            0,
        );
        log();
        return Number(result.toFixed(2));
    };

    //calculate sum of product vat fees of each branch
    calcCartItemsVatTotal = (splittedCart, currentCurrency) => {
        const result = splittedCart.reduce(
            (totalItemVat, branch) =>
                branch.vat
                    ? totalItemVat +
                      this.convertCurrency(
                          currentCurrency,
                          { code: branch.currency },
                          branch.vat,
                      )
                    : totalItemVat + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    //calculate sum of shipping fees of each branch
    //waive the shipping cost for a branch if items amount is above certain threshold (waiveDeliverFee)
    calcCartShippingFeeTotal = (splittedCart, currentCurrency) => {
        const result = splittedCart.reduce(
            (totalShipping, branch) =>
                branch.shipping.waiveDeliveryFee
                    ? branch.shippingCostIncludingWeightCost &&
                      branch.shipping.waiveDeliveryFee > branch.subtotal
                        ? totalShipping +
                          this.convertCurrency(
                              currentCurrency,
                              { code: branch.currency },
                              branch.shippingCostIncludingWeightCost,
                          )
                        : totalShipping + 0
                    : branch.shippingCostIncludingWeightCost
                    ? totalShipping +
                      this.convertCurrency(
                          currentCurrency,
                          { code: branch.currency },
                          branch.shippingCostIncludingWeightCost,
                      )
                    : totalShipping + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    // calculate total service fee from branches
    calcCartServiceFeeTotal = (splittedCart, currentCurrency) => {
        const result = splittedCart.reduce(
            (totalService, branch) =>
                branch.shipping.serviceFee
                    ? totalService +
                      this.convertCurrency(
                          currentCurrency,
                          { code: branch.currency },
                          branch.shipping.serviceFee,
                      )
                    : totalService + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    //calculate sum of reserve fees of each branch
    calcCartReserveFeeTotal = (splittedCart, currentCurrency) => {
        const result = splittedCart.reduce(
            (totalReserve, branch) =>
                branch.reserveFee
                    ? totalReserve +
                      this.convertCurrency(
                          currentCurrency,
                          { code: branch.currency },
                          branch.reserveFee,
                      )
                    : totalReserve + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    //calculate sum of all items of each branch
    calcCartItemsTotal = (splittedCart, currentCurrency) => {
        const result = splittedCart.reduce(
            (subtotal, branch) =>
                branch.subtotal
                    ? subtotal +
                      this.convertCurrency(
                          currentCurrency,
                          { code: branch.currency },
                          branch.subtotal,
                      )
                    : subtotal + 0,
            0,
        );
        log("calcCartItemsTotal1", result);
        return Number(result.toFixed(2));
    };

    //calculate the vat of a particular item based on it's price
    calcItemVat = (itemPrice, vatInPerc) => {
        const vat = itemPrice ? itemPrice * (vatInPerc / 100) : 0;
        return Number(vat.toFixed(2));
    };

    //calculate total vat of branch items
    calcTotalVat = (items) => {
        const result = items.reduce(
            (subVatTotal, item) => subVatTotal + item.vat,
            0,
        );
        return Number(result.toFixed(2));
    };

    //calculate subtotal of branch items
    calcSubtotal = (items) => {
        const result = items.reduce(
            (subtotal, item) => subtotal + item.total,
            0,
        );
        return Number(result.toFixed(2));
    };

    // calculate weight of the branch items
    calcWeight = (items) => {
        return items.reduce(
            (weight, item) => weight + item.product.weight * item.quantity,
            0,
        );
    };

    // calculate quantity of the branch items
    calcQuantity = (items) => {
        return items.reduce((quantity, item) => quantity + item.quantity, 0);
    };

    // calculate total service fee from branches
    calcTotalServiceFee = (branches) => {
        log("calcTotalServiceFee", branches);
        const result = branches.reduce(
            (total, branch) =>
                branch.serviceFee ? total + branch.serviceFee : total + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    calcTotalVatServiceFee = (branches) => {
        log("calcTotalVatServiceFee", branches);
        const result = branches.reduce(
            (total, branch) =>
                branch.serviceFee && branch.vatServiceFeeInPercent
                    ? total +
                      branch.serviceFee * (branch.vatServiceFeeInPercent / 100)
                    : total + 0,
            0,
        );
        return Number(result.toFixed(2));
    };

    cleanBrandUrl = (url) => {
        let lastPartURL = url.split("/").pop();
        let secondLastPartURL = url.split("/").slice(-2)[0];
        lastPartURL = decodeURI(lastPartURL);
        lastPartURL = lastPartURL.replace(/\s+/g, "-").toLowerCase();
        url = "/" + secondLastPartURL + "/" + lastPartURL;
        return url;
    };
    /*
    formatSlots = (slots) => {
        log("format slots", slots);
        let formattedSlots = [];

        slots.map((item, index) => {
            branches.push(item.branch);
        });
    };prett
*/
    convertCurrency = (currentCurrency, productCurrency, price) => {
        log(
            "currentCurrency, productCurrency",
            currentCurrency,
            productCurrency,
        );
        let convertedPrice = price;
        if (
            currentCurrency &&
            productCurrency.code &&
            currentCurrency.hasOwnProperty("convertTo")
        ) {
            let matched = currentCurrency.convertTo.find(
                (o) => o.code === productCurrency.code,
            );
            if (matched) {
                convertedPrice = matched.value * price;
            }
        }
        log("convertCurrency", Number(convertedPrice.toFixed(2)));
        return Number(convertedPrice.toFixed(2));
    };

    truncateString = (str, length) => {
        return str.length > length ? str.substring(0, length - 3) + "..." : str;
    };

    shuffleArray = (arr) => {
        const newArr = arr.slice();
        for (let i = newArr.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
        }
        return newArr;
    };
}
