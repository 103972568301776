// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import utilsAPI from "../../services/utils";
import ShopPageCategory from "./ShopPageCategory";

// data stubs
import theme from "../../data/theme";
const log = require("../shared/helper/MartLogger.js").logger;

class ShopPageCart extends Component {
    constructor(props) {
        super(props);
        this.utils = new utilsAPI();
        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
        };
        // window.$crisp.push(['do', 'chat:hide'])
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);

        return quantity ? quantity.value : item.quantity;
    }

    handleChangeQuantity = (item, quantity) => {
        this.setState((state) => {
            const stateQuantity = state.quantities.find(
                (x) => x.itemId === item.id
            );

            if (!stateQuantity) {
                state.quantities.push({ itemId: item.id, value: quantity });
            } else {
                stateQuantity.value = quantity;
            }

            return {
                quantities: state.quantities,
            };
        });
    };

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;

        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);

                return item && item.quantity !== x.value;
            }).length > 0
        );
    }

    renderItems() {
        const { cart, cartRemoveItem, address } = this.props;
        log("renderItems - cart", cart);
        return cart.items.map((item) => {
            let image;
            let options;
            const currency = {
                code: item.product.currency,
            };
            if (item.product.images != null && item.product.images.length > 0) {
                image = (
                    <Link to={`${item.product.itemUrl}`}>
                        <img
                            src={item.product.images[0][0]}
                            alt={item.product.name}
                        />
                    </Link>
                );
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li
                                key={index}
                            >{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.id, cart, item, address)}
                    render={({ run, loading }) => {
                        const classes = classNames(
                            "btn btn-light btn-sm btn-svg-icon",
                            {
                                "btn-loading": loading,
                            }
                        );

                        return (
                            <button
                                type="button"
                                onClick={run}
                                className={classes}
                            >
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link
                            to={`${item.product.itemUrl}`}
                            className="cart-table__product-name"
                        >
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                    >
                        <Currency value={item.price} currency={currency} />
                    </td>
                    <td
                        className="cart-table__column cart-table__column--quantity"
                        data-title="Quantity"
                    >
                        <InputNumber
                            onChange={(quantity) =>
                                this.handleChangeQuantity(item, quantity)
                            }
                            value={this.getItemQuantity(item)}
                            min={1}
                        />
                    </td>
                    <td
                        className="cart-table__column cart-table__column--total"
                        data-title="Total"
                    >
                        <Currency value={item.total} currency={currency} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { cart, currency } = this.props;
        const result = this.utils.splitCart(cart);
        log("ShopPageCart - renderTotals", currency);
        const {
            cartItemsTotal,
            cartServiceFeeTotal,
            cartShippingFeeTotal,
            cartReserveFeePercentage,
            cartReserveFeeTotal,
            cartCustomDuty,
        } = this.utils.calculateCartSummary(result.splittedCart, currency);

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage
                                id="cart.subTotal"
                                defaultMessage="Subtotal"
                            />
                        </th>
                        <td>
                            <Currency value={cartItemsTotal} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">
                    {cartServiceFeeTotal !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.serviceFee"
                                    defaultMessage="Service Fee"
                                />
                            </th>
                            <td>
                                <Currency value={cartServiceFeeTotal} />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th>
                            <FormattedMessage
                                id="cart.shipping"
                                defaultMessage="Shipping"
                            />
                        </th>
                        {cartShippingFeeTotal !== 0 && (
                            <td>
                                <Currency value={cartShippingFeeTotal} />
                            </td>
                        )}
                        {cartShippingFeeTotal === 0 && (
                            <td>+ {process.env.REACT_APP_PER_KG_COST}</td>
                        )}
                    </tr>
                    {cartReserveFeePercentage !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.totalReserve"
                                    defaultMessage="Reserve Fee"
                                />{" "}
                                &nbsp; ({cartReserveFeePercentage}%)
                            </th>
                            <td>
                                <Currency value={cartReserveFeeTotal} />
                            </td>
                        </tr>
                    )}
                    {cartCustomDuty !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.customDuty"
                                    defaultMessage="Approximate Custom Duty"
                                />
                            </th>
                            <td>
                                <Currency value={cartCustomDuty} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </React.Fragment>
        );
    }

    renderCart() {
        const { cart, cartUpdateQuantities, currency } = this.props;
        const { quantities } = this.state;
        const result = this.utils.splitCart(cart);
        const { cartGrandTotal } = this.utils.calculateCartSummary(
            result.splittedCart,
            currency
        );
        const updateCartButton = (
            <AsyncAction
                action={() => cartUpdateQuantities(quantities)}
                render={({ run, loading }) => {
                    const classes = classNames(
                        "btn btn-primary cart__update-button",
                        {
                            "btn-loading": loading,
                        }
                    );

                    return (
                        <button
                            type="button"
                            onClick={run}
                            className={classes}
                            disabled={!this.cartNeedUpdate()}
                        >
                            <FormattedMessage
                                id="cart.update"
                                defaultMessage="Update Cart"
                            />
                        </button>
                    );
                }}
            />
        );

        return (
            <div className="cart block">
                <div className="container">
                    <div className="cart-fixed">
                        <Currency value={cartGrandTotal} />
                        <Link
                            to="/shop/checkout"
                            className="btn btn-primary btn-sm"
                        >
                            Checkout
                        </Link>
                    </div>
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">
                                    <FormattedMessage
                                        id="cart.image"
                                        defaultMessage="Image"
                                    />
                                </th>
                                <th className="cart-table__column cart-table__column--product">
                                    <FormattedMessage
                                        id="cart.product"
                                        defaultMessage="Product"
                                    />
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    <FormattedMessage
                                        id="cart.price"
                                        defaultMessage="Price"
                                    />
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    <FormattedMessage
                                        id="product.quantity"
                                        defaultMessage="Quantity"
                                    />
                                </th>
                                <th className="cart-table__column cart-table__column--total">
                                    <FormattedMessage
                                        id="cart.total"
                                        defaultMessage="Total"
                                    />
                                </th>
                                <th
                                    className="cart-table__column cart-table__column--remove"
                                    aria-label="Remove"
                                />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {this.renderItems()}
                        </tbody>
                    </table>
                    <div className="cart__actions">
                        <form className="cart__coupon-form">
                            {/*
                            <label htmlFor="input-coupon-code" className="sr-only">Password</label>
                            <input type="text" className="form-control" id="input-coupon-code" placeholder="Coupon Code" />
                            <button type="submit" className="btn btn-primary">Apply Coupon</button>
                        */}
                        </form>

                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-light">
                                <FormattedMessage
                                    id="cart.continue"
                                    defaultMessage="Continue Shopping"
                                />
                            </Link>
                            {updateCartButton}
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        <FormattedMessage
                                            id="cart.cartTotals"
                                            defaultMessage="Cart Totals"
                                        />
                                    </h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>
                                                    <FormattedMessage
                                                        id="cart.total"
                                                        defaultMessage="Total"
                                                    />
                                                </th>
                                                <td>
                                                    <Currency
                                                        value={cartGrandTotal}
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <Link
                                        to="/shop/checkout"
                                        className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                    >
                                        <FormattedMessage
                                            id="cart.proceed"
                                            defaultMessage="Proceed to checkout"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { cart, currency } = this.props;
        const result = this.utils.splitCart(cart);
        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "" },
        ];

        const { cartQuantityTotal } = this.utils.calculateCartSummary(
            result.splittedCart,
            currency
        );

        let content;

        //if (cart.quantity) {
        if (cartQuantityTotal) {
            content = this.renderCart();
        } else {
            content = (
                <div className="block block-empty">
                    <div className="container">
                        <div className="block-empty__body">
                            <div className="block-empty__message">
                                Your shopping cart is empty!
                            </div>
                            <div className="block-empty__actions">
                                <Link to="/" className="btn btn-primary btn-sm">
                                    Continue
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Shopping Cart — ${theme.name}`}</title>
                </Helmet>
                <FormattedMessage
                    id="cart.shoppingCart"
                    defaultMessage="Shopping Cart"
                >
                    {(msg) => (
                        <PageHeader header={`${msg}`} breadcrumb={breadcrumb} />
                    )}
                </FormattedMessage>
                {content}
                <ShopPageCategory
                    columns={4}
                    viewMode="grid"
                    sidebarPosition="start"
                    source="shop"
                    isShopPageCategoryEmbedded={true}
                    title="Items you may like"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
    currency: state.currency,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
