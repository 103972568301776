// react
import React, { useState, useEffect } from "react";

// third-party
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";

// application
import PageHeader from "../shared/PageHeader";
import notificationAPI from "../../services/notifications";

// blocks
import BlockMap from "../blocks/BlockMap";

// data stubs
import theme from "../../data/theme";

const notification = new notificationAPI();

function SitePageContactUs(props) {
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Contact Us", url: "" },
    ];
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [phone, setPhone] = useState("");
    const { embedded } = props;
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            name: name,
            email: email,
            subject: embedded ? "Product inquiry" : subject,
            message: message,
            phone: phone,
            templateId: process.env.REACT_APP_SENDGRID_TEMPLATE_CUSTOMER_MSG,
            customerEmail: process.env.REACT_APP_TENANT_EMAIL2,
            tenantEmail: process.env.REACT_APP_TENANT_EMAIL,
        };
        notification.sendEmail(data).then((response) => {
            toast.success(`Message has been sent`, {
                autoClose: 2500,
            });
            setName("");
            setEmail("");
            setPhone("");
            setMessage("");
            setSubject("");
        });
    };

    return (
        <React.Fragment>
            {!embedded && (
                <Helmet>
                    <title>{`Contact Us — ${theme.name}`}</title>
                </Helmet>
            )}

            {/*
               <BlockMap /> 
            */}
            {!embedded && (
                <PageHeader header="Contact Us" breadcrumb={breadcrumb} />
            )}
            <div className="block">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    {/*
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">
                                            Our Address
                                        </h4>

                                        <div className="contact-us__address">
                                            <p>
                                                {`Email: ${theme.contacts.email}`}
                                                <br />
                                                {`Phone Number: ${theme.contacts.phone}`}
                                            </p>

                                            <p>
                                                <strong>Opening Hours</strong>
                                                <br />
                                                Monday to Friday: 8am-8pm
                                                <br />
                                                Saturday: 8am-6pm
                                                <br />
                                                Sunday: 10am-4pm
                                            </p>

                                            <p>
                                                <strong>Comment</strong>
                                                <br />
                                                Please feel free to contact us
                                                any time.
                                            </p>
                                        </div>
                                    </div>
                                */}
                                    <div className="col-12 col-lg-6">
                                        {!embedded && (
                                            <h4 className="contact-us__header card-title">
                                                Leave us a Message
                                            </h4>
                                        )}
                                        {embedded && (
                                            <h4 className="contact-us__header card-title">
                                                Request a Quote
                                            </h4>
                                        )}
                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">
                                                        Your Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        onChange={(e) =>
                                                            setName(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={name}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-phone">
                                                        Your Phone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="phone"
                                                        className="form-control"
                                                        placeholder="Your Phone"
                                                        onChange={(e) =>
                                                            setPhone(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={phone}
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">
                                                        Email
                                                    </label>
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                        onChange={(e) =>
                                                            setEmail(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={email}
                                                    />
                                                </div>
                                            </div>
                                            {!embedded && (
                                                <div className="form-group">
                                                    <label htmlFor="form-subject">
                                                        Subject
                                                    </label>
                                                    <input
                                                        id="subject"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Subject"
                                                        onChange={(e) =>
                                                            setSubject(
                                                                e.target.value
                                                            )
                                                        }
                                                        value={subject}
                                                    />
                                                </div>
                                            )}
                                            <div className="form-group">
                                                <label htmlFor="form-message">
                                                    {!embedded && "Message"}
                                                    {embedded &&
                                                        "Product Description and URL"}
                                                </label>

                                                <textarea
                                                    id="message"
                                                    className="form-control"
                                                    rows="4"
                                                    onChange={(e) =>
                                                        setMessage(
                                                            e.target.value
                                                        )
                                                    }
                                                    value={message}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={handleSubmit}
                                            >
                                                Send Message
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageContactUs;
