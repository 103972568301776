import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import classNames from "classnames";
import itemsAPI from "../../../services/items";
import utilsAPI from "../../../services/utils";
import ProductCard from "../ProductCard";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "swiper/css/swiper.css";
import { connect } from "react-redux";
const log = require("../helper/MartLogger.js").logger;

const MarttimeSwiper = (props) => {
  const { category, layout, rows, title, categoryUrl, slidesPerView, address } =
    props;
  const params = {
    slidesPerView: 5.4,
    spaceBetween: 30,
    freeMode: true,
    rebuildOnUpdate: true,
    observer: true,
    breakpoints: {
      1024: {
        slidesPerView: 4.4,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: 3.4,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 3.4,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 1.4,
        spaceBetween: 10,
      },
    },
  };

  const items = new itemsAPI();
  const utils = new utilsAPI();
  const [swiper, setSwiper] = useState([]);
  const [loading, setLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;
  const [page, setPage] = useState(1);

  const productsColumns = (products) => {
    const columns = [];
    log("productsColumns - products", products);

    if (rows > 0) {
      products = products.slice();

      while (products.length > 0) {
        columns.push(products.splice(0, rows));
      }
    }

    return columns;
  };

  useEffect(() => {
    log("MarttimeSwiper effect - category", category);
    items
      .getNearestItemsByCategory(
        category,
        address ? address.latitude : process.env.REACT_APP_DEFAULT_LATITUDE,
        address ? address.longitude : process.env.REACT_APP_DEFAULT_LONGITUDE,
        page,
        ITEMS_PER_PAGE
      )
      .then((response) => {
        log("MarttimeSwiper-itemsAPIResponse", response);
        const data = utils.parseProductArray(response);
        log("MarttimeSwiper-parseddata", data);
        setLoading(false);

        const columns = productsColumns(data).map((column, index) => {
          const products = column.map((product) => (
            <div key={product.id} className="block-products-carousel__cell">
              <ProductCard product={product} />
            </div>
          ));

          return (
            <div key={index} className="block-products-carousel__column">
              {products}
            </div>
          );
        });

        setSwiper(columns);
      })
      .catch((error) => {
        console.error("error in MarttimeSwiper - getitems", error);
      });
  }, [category]);

  const blockClasses = classNames("block block-products-carousel", {
    "block-products-carousel--loading": false,
  });
  const containerClasses = classNames({
    container: !false,
  });

  return (
    <div className={blockClasses} data-layout={layout}>
      <div className={containerClasses}>
        <div className="block-header">
          <h3 className="block-header__title">{title}</h3>
          <div className="block-header__divider" />
          {categoryUrl !== null && (
            <Link
              className="product__viewmore"
              style={{ float: "right", display: "block" }}
              to={categoryUrl}
            >
              &nbsp;&nbsp;View more >
            </Link>
          )}
        </div>

        {loading ? (
          <Skeleton height={340} />
        ) : (
          <Swiper {...params} shouldSwiperUpdate>
            {swiper}
          </Swiper>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  address: state.map.address,
});

export default connect(mapStateToProps, null)(MarttimeSwiper);
