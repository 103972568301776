// react
import React, { useState, useEffect } from "react";

// third-party
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

// application
import Dropdown from "./Dropdown";
import { currencyChange, currencyInitialize } from "../../store/currency";
import configurationAPI from "../../services/configurations";

// data stubs
//import currencies from '../../data/shopCurrencies';
const log = require("../shared/helper/MartLogger.js").logger;

function DropdownCurrency(props) {
    const {
        currency,
        currencyChange: changeCurrency,
        currencyInitialize,
    } = props;

    log("DropdownCurrency currency", currency);
    const [currencies, setCurrencies] = useState([]);
    const configuration = new configurationAPI();

    const title = (
        <React.Fragment>
            <FormattedMessage id="topbar.currency" defaultMessage="Currency" />
            {": "}
            <span className="topbar__item-value">{currency.code}</span>
        </React.Fragment>
    );

    useEffect(() => {
        configuration.getCurrencies().then((response) => {
            setCurrencies(response);
            log("get currencies",  response);
            if (currency.code === "") currencyInitialize(response[0].currency);
            //check if local storage contain different currency then we have in the list
            const result = response.filter((obj) => {
                return obj.currency.code === currency.code;
            });
            log("currency matched", result);
            //update the currency rate because it may be updated from backend
            result.length === 0 ? currencyInitialize(response[0].currency) : currencyInitialize(result[0].currency)
        });
    }, []);
    return (
        <Dropdown
            title={title}
            items={currencies}
            onClick={(item) => changeCurrency(item.currency)}
        />
    );
}

const mapStateToProps = (state) => ({
    currency: state.currency,
    locale: state.locale,
});

const mapDispatchToProps = {
    currencyChange,
    currencyInitialize,
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCurrency);
