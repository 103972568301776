// react
import React, { useState, useEffect } from "react";

// third-party
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";

// data stubs
import theme from "../../data/theme";

// store
import { cartReset } from "../../store/cart";

// services
import googleAnalyticsAPI from "../../services/googleAnalytics";
import orderAPI from "../../services/order";
import notificationAPI from "../../services/notifications";
import ReactGA from "react-ga";
const log = require("../shared/helper/MartLogger.js").logger;

const googleAnalytics = new googleAnalyticsAPI();
const order = new orderAPI();
const notification = new notificationAPI();

function OrderConfirmation(props) {
    const { layout, sidebarPosition, match } = props;
    log("OrderConfirmation", match.params.id);
    log("order confirmation - props.location.state", props.location.state);
    const [orders, setOrders] = useState([]);
    const params = queryString.parse(props.location.search);
    const parentOrderId = params.id;

    useEffect(() => {
        //calling action to reset the cart
        props.cartReset();
        const { auth } = props;
        const user = auth.user.phone;
        log("user phone", user);

        // get the list of order(s)
        order
            .getOrderByParentOrderId(parentOrderId)
            .then((orders) => {
                log("getOrderByParentOrderId", orders);
                orders.map((order, index) => {
                    //send notifcations
                    //notification.sendEmail(orders[index], process.env.REACT_APP_SENDGRID_TEMPLATE_CREATE_ORDER)
                    sendGoogleAnalytics(orders[index], user);
                    notification.sendEmail(prepareEmailData(orders[index]));
                    notification.sendSMS(orders[index], "CREATE_ORDER");
                });

                setOrders(orders);
            })
            .catch((error) => {
                log("error on getOrderByParentOrderId", error);
            });
    }, []);

    const sendGoogleAnalytics = (order, user) => {
        ReactGA.event({
            category: "checkout",
            action: "order confirmation",
            label: user,
            value: 1,
        });

        ReactGA.event({
            category: "checkout",
            action: "order value",
            label: user,
            value: Number(order.cost),
        });

        ReactGA.ga("event", "purchase", {
            transaction_id: order.orderId,
            affiliation: "online store",
            value: Number(order.cost),
            currency: order.currency,
            /*
            items: [
                {
                    id: "P12345",
                    name: "Android Warhol T-Shirt",
                    list_name: "Search Results",
                    brand: "Google",
                    category: "Apparel/T-Shirts",
                    variant: "Black",
                    list_position: 1,
                    quantity: 2,
                    price: "2.0",
                },
                {
                    id: "P67890",
                    name: "Flame challenge TShirt",
                    list_name: "Search Results",
                    brand: "MyBrand",
                    category: "Apparel/T-Shirts",
                    variant: "Red",
                    list_position: 2,
                    quantity: 1,
                    price: "3.0",
                },
            ],*/
        });
    };

    const prepareEmailData = (order) => {
        const data = {
            orderId: order.orderId,
            customerFirstName: order.shippingAddress[0].customerFirstName,
            customerLastName: order.shippingAddress[0].customerLastName,
            customerPhone: order.shippingAddress[0].customerPhone,
            customerHouseNumber: order.shippingAddress[0].customerHouseNumber,
            customerStreet: order.shippingAddress[0].customerStreet,
            items: order.pickedItems.concat(order.nonRegisteredItems),
            customerEmail: order.shippingAddress[0].customerEmail,
            customerLocation: order.shippingAddress[0].customerLocation,
            orderCost: order.cost,
            customerNotes: order.customerNotes,
            templateId: process.env.REACT_APP_SENDGRID_TEMPLATE_CREATE_ORDER,
            tenantEmail: process.env.REACT_APP_TENANT_EMAIL,
            tenantPhone: process.env.REACT_APP_TENANT_PHONE,
            tenantinstagram: process.env.REACT_APP_TENANT_INSTAGRAM_PROFILE_URL,
            tenantfacebook: process.env.REACT_APP_TENANT_FACEBOOK_PROFILE_URL,
            tenantBusinessName: process.env.REACT_APP_TENANT_BUSINESS_NAME,
            tenantBusinessLogo: process.env.REACT_APP_WEBSITE_LOGO,
            tenantBusinessBanner: process.env.REACT_APP_WEBSITE_BANNER,
            tenantBusinessUrl: process.env.REACT_APP_TENANT_BUSINESS_SITE_URL,
            currency: order.currency,
            subject:
                "Congratulations  - Your " +
                process.env.REACT_APP_TENANT_BUSINESS_NAME +
                " order# is " +
                order.orderId,
        };
        return data;
    };

    return (
        <div className="block">
            <Helmet>
                <title>{`Confirmation — ${theme.name}`}</title>
            </Helmet>

            <div className="container">
                <div className="not-found">
                    <div className="not-found__404">Congratulations</div>

                    <div className="not-found__content">
                        <h1 className="not-found__title">
                            Thank you for submitting the request.
                        </h1>

                        <p className="not-found__text">
                            Your booking number is:
                            <br />
                            {orders &&
                                orders.map((value, index) => {
                                    return (
                                        <div>
                                            <Link
                                                to={`/account/orders/orderDetail/${value._id}`}
                                            >
                                                {value.orderId}
                                            </Link>
                                        </div>
                                    );
                                })}
                        </p>
                        <p>
                            {" "}
                            We will calculate the international shipping and get
                            back to you within 48 hours.
                        </p>
                        <br />
                        {/*
                        <form className="not-found__search">
                            <input type="text" className="not-found__search-input form-control" placeholder="Search Query..." />
                            <button type="submit" className="not-found__search-button btn btn-primary">Search</button>
                        </form>

                        <p className="not-found__text">
                            Or go to the home page to start over.
                        </p>
                        */}

                        <Link to="/" className="btn btn-secondary btn-sm">
                            Go To Home Page
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
    locationName: state.map.locationName,
    currency: state.currency,
    auth: state.auth,
});

const mapDispatchToProps = {
    cartReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation);
