// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import { Cart16Svg } from "../../svg";
import { cartAddItem } from "../../store/cart";
import { ebayUsaUrl } from "../../url";
const log = require("../shared/helper/MartLogger.js").logger;

function Suggestions(props) {
    const { context, className, products, cartAddItem, cart, address, query } =
        props;
    const rootClasses = classNames(
        `suggestions suggestions--location--${context}`,
        className
    );

    log("suggestions.jsx", products);
    const list =
        products &&
        products.map((product) => {
            const currency = {
                code: product.currency,
            };
            return (
                <li key={product.id} className="suggestions__item">
                    {product.images &&
                        product.images.length > 0 &&
                        product.images[0] !== undefined && (
                            <div className="suggestions__item-image">
                                <img
                                    src={product.images[0][0]}
                                    alt={product.images}
                                />
                            </div>
                        )}
                    <div className="suggestions__item-info">
                        {window.location.pathname.includes(ebayUsaUrl) ? (
                            <Link
                                className="suggestions__item-name"
                                to={`${ebayUsaUrl}/product/${product.id}`}
                            >
                                {product.name}
                            </Link>
                        ) : (
                            <Link
                                className="suggestions__item-name"
                                to={`/shop/product/${product.itemCustomId}`}
                            >
                                {product.name}
                            </Link>
                        )}
                        {/*
                   <div className="suggestions__item-meta">SKU: 83690/32</div> 
                */}
                    </div>
                    {product.price <= product.salePrice && (
                        <div className="suggestions__item-price-new">
                            <Currency
                                value={product.price}
                                currency={currency}
                            />
                        </div>
                    )}
                    {product.price > product.salePrice && (
                        <React.Fragment>
                            <span className="suggestions__item-price-new">
                                <Currency
                                    value={product.salePrice}
                                    currency={currency}
                                />
                            </span>{" "}
                            &nbsp;
                            <span className="suggestions__item-price-old">
                                <Currency
                                    value={product.price}
                                    currency={currency}
                                />
                            </span>
                        </React.Fragment>
                    )}
                    {context === "header" && (
                        <div className="suggestions__item-actions">
                            <AsyncAction
                                action={() =>
                                    cartAddItem(product, [], 1, cart, address)
                                }
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        title="Add to cart"
                                        className={classNames(
                                            "btn btn-primary btn-sm btn-svg-icon",
                                            {
                                                "btn-loading": loading,
                                            }
                                        )}
                                    >
                                        <Cart16Svg />
                                    </button>
                                )}
                            />
                        </div>
                    )}
                </li>
            );
        });

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
            <div className="suggestions__footer">
                <Link to={`/search/${query}`}>View all results</Link>
            </div>
        </div>
    );
}

//const mapStateToProps = () => ({});

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
