// react
import React, { useEffect, useRef, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import { Cross20Svg } from "../../svg";
import { sidebarClose } from "../../store/sidebar";

// widgets
import WidgetFilters from "../widgets/WidgetFilters";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import filters from "../../data/shopFilters";
import products from "../../data/shopProducts";
import categoriesAPI from "../../services/categories";
import itemsAPI from "../../services/items";
import utilsAPI from "../../services/utils";
import Skeleton from "react-loading-skeleton";

const log = require("../shared/helper/MartLogger.js").logger;

function CategorySidebar(props) {
    const { sidebarClose, sidebarState, offcanvas, category, source, address } =
        props;

    const [filters, setFilters] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    const [categoryLoading, setCateogoryLoading] = useState(true);
    const [productLoading, setProductLoading] = useState(true);

    log("sidebar category..", category, source);

    const classes = classNames("block block-sidebar", {
        "block-sidebar--open": sidebarState.open,
        "block-sidebar--offcanvas--always": offcanvas === "always",
        "block-sidebar--offcanvas--mobile": offcanvas === "mobile",
        // 'btn-loading': loading,
    });
    const categories = new categoriesAPI();
    const item = new itemsAPI();
    const utils = new utilsAPI();
    const backdropRef = useRef(null);
    const bodyRef = useRef(null);

    const loadSideBar = (response) => {
        //transform response to show the data in sidebar
        const data = response.map((category) => ({
            id: category.hasOwnProperty("_id") ? category._id : "",
            type: "parent",
            count: category.hasOwnProperty("count") ? category.count : 500,
            name: category.hasOwnProperty("name") ? category.name : "",
            url: category.hasOwnProperty("menuUrl") ? category.menuUrl : "",
        }));
        log("sidebar list ", data);
        const f = [
            {
                id: 1,
                name: "Explore more",
                type: "categories",
                options: {
                    items: data,
                },
            },
        ];
        log("categorysidebar - filters", f);
        setFilters(f);
        setLoading(false);
    };

    //load categories in the side bar
    useEffect(() => {
        log("sidebar category in useEffect - 1", category);
        // if(source == 'search'){
        log("categorysidebar, calling getNodesByIdsWithOutChild", category);
        if (category && category.length > 0) {
            categories
                .getNodesByIdsWithOutChild(category)
                .then((response) => {
                    log("sidebar Categories response", response);
                    loadSideBar(response);
                    setCateogoryLoading(false);
                })
                .catch((error) => {
                    log("ShopPageCategory - breadcrumb error", error);
                });
        }

        //}
        /*
        else if(source == 'shop'){
            log("categorysidebar, calling getCategory",  category)
            categories.getCat(category).then(response=>{
                log("sidebar Categories response", response)
                loadSideBar(response)
            })
            .catch(error => {
                log("ShopPageCategory - breadcrumb error",error);
            })  
        }*/
    }, [category]);

    //load products in the sidebar

    useEffect(() => {
        log("useEffect, category - categorySidebar", category);
        if (category && category.length > 0) {
            log("categorysidebar, calling getItemsByCategoryId", category);

            item.getNearestItemsByCategory(
                category[0],
                address
                    ? address.latitude
                    : process.env.REACT_APP_DEFAULT_LATITUDE,
                address
                    ? address.longitude
                    : process.env.REACT_APP_DEFAULT_LONGITUDE
            )
                .then((response) => {
                    log(
                        "categorySidebar -  show products on shop page",
                        response
                    );
                    if (response === undefined) return;
                    const product = utils.parseProductArray(response);
                    log("categorysidebar -  bestSellers products", product);
                    if (product !== null) setProducts(product);
                    setProductLoading(false);
                })
                .catch((error) => {
                    log(
                        "error in getItemsByCategoryId - CategorySidebar",
                        error
                    );
                });
        }
    }, [category]);

    useEffect(() => {
        const media = matchMedia("(max-width: 991px)");
        let changedByMedia = false;

        const onChange = () => {
            if (offcanvas === "mobile") {
                if (sidebarState.open && !media.matches) {
                    sidebarClose();
                }
                // this is necessary to avoid the transition hiding the sidebar
                if (!sidebarState.open && media.matches && changedByMedia) {
                    /** @var {HTMLElement} */
                    const backdrop = backdropRef.current;
                    /** @var {HTMLElement} */
                    const body = bodyRef.current;

                    backdrop.style.transition = "none";
                    body.style.transition = "none";

                    backdrop.getBoundingClientRect(); // force reflow

                    backdrop.style.transition = "";
                    body.style.transition = "";
                }
            }
        };

        if (media.addEventListener) {
            media.addEventListener("change", onChange);
        } else {
            media.addListener(onChange);
        }

        onChange();

        changedByMedia = true;

        return () => {
            if (media.removeEventListener) {
                media.removeEventListener("change", onChange);
            } else {
                media.removeListener(onChange);
            }
        };
    }, [offcanvas, sidebarState.open, sidebarClose]);

    useEffect(() => {
        if (sidebarState.open) {
            const width = document.body.clientWidth;

            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = `${
                document.body.clientWidth - width
            }px`;
        } else {
            document.body.style.overflow = "";
            document.body.style.paddingRight = "";
        }
    }, [sidebarState.open]);

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
                className="block-sidebar__backdrop"
                ref={backdropRef}
                onClick={() => sidebarClose()}
            />
            <div className="block-sidebar__body" ref={bodyRef}>
                <div className="block-sidebar__header">
                    <div className="block-sidebar__title">
                        Related Categories
                    </div>
                    <button
                        className="block-sidebar__close"
                        type="button"
                        onClick={() => sidebarClose()}
                    >
                        <Cross20Svg />
                    </button>
                </div>

                <div className="block-sidebar__item">
                    {categoryLoading ? (
                        <Skeleton height={200} />
                    ) : (
                        <WidgetFilters
                            title="Categories"
                            filters={filters}
                            offcanvas={offcanvas}
                        />
                    )}
                </div>
                {offcanvas !== "always" && (
                    <div className="block-sidebar__item d-none d-lg-block">
                        {productLoading && products.length === 0 ? (
                            <Skeleton height={200} />
                        ) : (
                            <WidgetProducts
                                title="Latest Products"
                                products={products.slice(0, 10)}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

CategorySidebar.propTypes = {
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

CategorySidebar.defaultProps = {
    offcanvas: "mobile",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    address: state.map.address,
});

const mapDispatchToProps = {
    sidebarClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorySidebar);
