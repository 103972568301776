const log = require("../components/shared/helper/MartLogger.js").logger;

export default class Payment {
    getPaymentMethodList = async () => {
        return fetch(`${process.env.REACT_APP_API}/v1/configurations/payment`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
        })
            .then((response) => response.json())
            .then((list) => {
                log("payment list", list);
                return list;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    // generate hash for easypay
    generateHashEasyPay = async (data) => {
        log("generateHashEasyPay", data);
        return fetch(
            `${process.env.REACT_APP_API}/v1/payment/generateHashEasyPay`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },

                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((hash) => {
                log("easypay hash data", hash);
                return hash;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    // for payson payment gateway
    paysonCreateCheckout = async (data) => {
        log("paysonCreateCheckout", data);
        return fetch(
            `${process.env.REACT_APP_API}/v1/payment/paysoncreatecheckout`,
            {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },

                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                log("payson data", data);
                return data;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    // for payson payment gateway
    swedBankPayCreatePayment = async (data) => {
        log("swedBankPayCreatePayment", data);
        return fetch(
            `${process.env.REACT_APP_API}/v1/payment/swedBankPay/createPayment`,
            {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },

                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                log("swedBankPay data", data);
                return data;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    // create or update transaction
    createTransaction = async (data) => {
        log("createTransaction", data);
        return fetch(`${process.env.REACT_APP_API}/v1/transaction/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                log("createTransaction response", data);
                return data;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };
}
