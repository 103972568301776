// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import { cartRemoveItem, cartUpdateQuantities } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import orderAPI from "../../services/order";
import utilsAPI from "../../services/utils";

// data stubs
import theme from "../../data/theme";
import PropTypes from "prop-types";

const log = require("../shared/helper/MartLogger.js").logger;

class AccountPageOrderDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            /** example: [{itemId: 8, value: 1}] */
            quantities: [],
            order: [],
            sessionExpired: false,
        };
        this.utils = new utilsAPI();
        this.order = new orderAPI();
        log("AccountPageOrderDetail", this.props.match.params.orderId);
    }

    componentDidMount() {
        this.order
            .getOrderByOrderId(this.props.match.params.orderId)
            .then((response) => {
                log("order detail response", response);
                if (response == 401 || response == 403) {
                    this.setState({
                        sessionExpired: true,
                    });
                    this.props.history.push("/account/login");
                }
                this.setState(
                    {
                        order: response,
                    },
                    () => {
                        log("order state updated", this.state.order);
                    }
                );
            })
            .catch((error) => {
                log("error in order detail", error);
            });
    }

    renderOrder() {
        const { cart, cartRemoveItem, address } = this.props;
        const { order } = this.state;

        log("ORDER::", order);

        if (order.length > 0) {
            const orderItems = order[0].pickedItems.concat(
                order[0].nonRegisteredItems
            );
            return orderItems.map((item) => {
                let image;
                let options;

                log("item renderOrder", item);

                if (item.image != null && item.image.length > 0) {
                    image = (
                        //<Link to={`/shop/product/${item._id}`}>
                        <Link to={`/shop/product/${item.itemCustomId}`}>
                            <img src={item.image[0]} alt="" />
                        </Link>
                    );
                }

                return (
                    <tr key={item._id} className="cart-table__row">
                        <td className="cart-table__column cart-table__column--image">
                            {image}
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                            <Link
                                to={`/shop/product/${item.itemCustomId}`}
                                className="cart-table__product-name"
                            >
                                {this.utils.truncateString(item.name, 70)}
                            </Link>
                            {options}
                        </td>

                        <td
                            className="cart-table__column cart-table__column--price"
                            data-title="Price"
                        >
                            <Currency value={item.unitPrice} />
                        </td>

                        <td
                            className="cart-table__column cart-table__column--quantity"
                            data-title="Quantity"
                        >
                            {item.quantity}
                        </td>

                        <td
                            className="cart-table__column cart-table__column--total"
                            data-title="Total"
                        >
                            <Currency value={item.unitPrice * item.quantity} />
                        </td>

                        <td className="cart-table__column cart-table__column--remove"></td>
                    </tr>
                );
            });
        }
    }

    renderCart() {
        log("render -- ");
        const { cart, cartUpdateQuantities } = this.props;
        const { quantities, order } = this.state;

        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">
                                    Image
                                </th>
                                <th className="cart-table__column cart-table__column--product">
                                    Product
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Price
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    Quantity
                                </th>
                                <th className="cart-table__column cart-table__column--total">
                                    Total
                                </th>
                                <th
                                    className="cart-table__column cart-table__column--remove"
                                    aria-label="Remove"
                                />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">
                            {/*this.rendserItems()*/}
                            {this.renderOrder()}
                        </tbody>
                    </table>

                    {order.length > 0 && (
                        /*<div className="row justify-content-end pt-md-5 pt-4">*/
                        <div className="row mt-3 no-gutters mx-n2">
                            {/*<div className="col-12 col-md-7 col-lg-6 col-xl-5">*/}
                            <div className="col-sm-6 col-12 px-2">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            Order Totals
                                        </h3>
                                        <table className="cart__totals">
                                            <thead className="cart__totals-header">
                                                <tr>
                                                    <th>Subtotal</th>
                                                    <td>
                                                        <Currency
                                                            value={
                                                                order[0].cost
                                                                    .itemsCost
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody className="cart__totals-body">
                                                <tr key={0}>
                                                    <th>Shipping</th>
                                                    <td>
                                                        <Currency
                                                            value={
                                                                order[0].cost
                                                                    .shippingCost
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                                <tr key={1}>
                                                    <th>Vat</th>
                                                    <td>
                                                        <Currency
                                                            value={
                                                                order[0].cost
                                                                    .vat
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot className="cart__totals-footer">
                                                <tr>
                                                    <th>Total</th>
                                                    <td>
                                                        <Currency
                                                            value={Number(
                                                                order[0]
                                                                    .orderAmount
                                                            )}
                                                        />
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                                <div className="card address-card address-card--featured">
                                    <div className="address-card__body">
                                        <div className="address-card__badge address-card__badge--muted">
                                            Shipping Address
                                        </div>
                                        <div className="address-card__name">{`${order[0].shippingAddress[0].customerFirstName} ${order[0].shippingAddress[0].customerLastName}`}</div>
                                        <div className="address-card__row">
                                            {`${order[0].shippingAddress[0].customerHouseNumber}`}
                                            <br />
                                            {`${order[0].shippingAddress[0].customerStreet}`}
                                            <br />
                                        </div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">
                                                Phone Number
                                            </div>
                                            <div className="address-card__row-content">{`${order[0].shippingAddress[0].customerPhone}`}</div>
                                        </div>
                                        <div className="address-card__row">
                                            <div className="address-card__row-title">
                                                Email Address
                                            </div>
                                            <div className="address-card__row-content">{`${order[0].shippingAddress[0].customerEmail}`}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const { cart } = this.props;
        const { match, location, history } = this.props;
        const { order, sessionExpired } = this.state;
        log("order id", order);
        /*
        const breadcrumb = [
            { title: 'Home', url: '' },
            { title: 'Accounts', url: '/accounts/order/ ' },
        ];
    */
        let content;
        content = this.renderCart();

        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Order Detail — ${theme.name}`}</title>
                </Helmet>

                {/* <PageHeader header={"Order# " + this.props.match.params.orderId} />*/}
                <PageHeader
                    header={
                        order.length > 0 ? "Order# " + order[0].orderId : ""
                    }
                />

                {content}
                {sessionExpired && (
                    <FormattedMessage
                        id="common.sessionExpired"
                        defaultMessage="Your session is expired. Please login."
                    >
                        {(msg) => toast.success(`${msg}`, { autoClose: 10000 })}
                    </FormattedMessage>
                )}
            </React.Fragment>
        );
    }
}

AccountPageOrderDetail.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
});

const mapDispatchToProps = {
    cartRemoveItem,
    cartUpdateQuantities,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageOrderDetail);
