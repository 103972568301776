import axios from "axios";
import googleAnalyticsAPI from "./googleAnalytics";
import ReactGA from "react-ga";
import utilsAPI from "./utils";
import { ebayUsaUrl } from "../url";
import TagManager from "react-gtm-module";
const CancelToken = axios.CancelToken;
let cancel;
const googleAnalytics = new googleAnalyticsAPI();
const utils = new utilsAPI();
const log = require("../components/shared/helper/MartLogger.js").logger;

export default class Ebay {
    //return ebay items by keyword
    search = (keyword, limit = 200, offset = 0) => {
        log("ebay search keyword ", keyword);
        TagManager.dataLayer({
            dataLayer: {
                event: "ebaySearch",
                searchTerm: keyword,
            },
        });
        return fetch(
            process.env.REACT_APP_API +
                "/v1/ebay/buy/browse/search" +
                "?q=" +
                keyword +
                "&limit=" +
                limit +
                "&offset=" +
                offset,

            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            },
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("items.js", error);
                return error;
            });
    };

    searchByCategory = (category, limit = 200, offset = 0) => {
        log("ebay search by category ", category);
        TagManager.dataLayer({
            dataLayer: {
                event: "ebaySearchByCategory",
                category: category,
            },
        });
        return fetch(
            process.env.REACT_APP_API +
                "/v1/ebay/buy/browse/search?" +
                "limit=" +
                limit +
                "&offset=" +
                offset +
                "&category_ids=" +
                category,

            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            },
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("items.js", error);
                return error;
            });
    };

    //return ebay item detail
    getItemLocalDb = (itemId) => {
        log("ebay  getItemfromlocaldb ", itemId);
        return fetch(
            process.env.REACT_APP_API +
                "/v1/ebay/ebayItemDetail?" +
                "itemId=" +
                itemId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            },
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("getItemLocalDb", error);
                return error;
            });
    };

    //return ebay item detail
    getItem = (itemId) => {
        log("ebay  getItem ", itemId);
        TagManager.dataLayer({
            dataLayer: {
                event: "ebayItem",
                itemId: itemId,
            },
        });
        return fetch(
            process.env.REACT_APP_API +
                "/v1/ebay/buy/browse/getItem" +
                "?itemId=" +
                itemId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            },
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("items.js", error);
                return error;
            });
    };

    //return ebay items by item group id
    getItemsByItemGroup = (itemGroupId) => {
        log("ebay  getItem ", itemGroupId);
        TagManager.dataLayer({
            dataLayer: {
                event: "ebayItemGroup",
                itemGroupId: itemGroupId,
            },
        });
        return fetch(
            process.env.REACT_APP_API +
                "/v1/ebay/buy/browse/getItemsByItemGroup" +
                "?itemGroupId=" +
                itemGroupId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            },
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("items.js", error);
                return error;
            });
    };

    parseEbayProductArray = (response) => {
        log("ebay product array to be parsed", response);
        if (response.itemSummaries.length > 0) {
            const products = response.itemSummaries.map((item) => {
                let obj = item.categories.find(
                    (x) => x.categoryId === "176992",
                );
                console.log("categoryId 176992", obj);
                if (obj === undefined) return this.parseEbayProduct(item);
            });
            log("utils - return product array", products); //remove empty products from array
            return products.filter((n) => n);
        } else return null;
    };

    calculateProductPrice = (item) => {
        log("calculate price for ebay item", item);

        //const roundedPrice = Math.round(item.price.value * 100) / 100;
        //log("roundedPrice", roundedPrice, item.price.value);
        //add 55 dollar if it is mobile category
        if (item.hasOwnProperty("categoryId") && item.categoryId === "9355") {
            log("calculateProductPrice item found", item);
            return {
                ebayProductPrice:
                    Math.round((Number(item.price.value) + 55) * 100) / 100,
                freeShipping: true,
            };
        }
        if (item.hasOwnProperty("categories")) {
            const result = item.categories.find((obj) => {
                return obj.categoryId === "9355";
            });
            log("9355 item", result, item);
            if (result) {
                return {
                    ebayProductPrice:
                        Math.round((Number(item.price.value) + 55) * 100) / 100,
                    freeShipping: true,
                };
            }
        }

        return {
            ebayProductPrice:
                Math.round((Number(item.price.value) + 1) * 100) / 100,
            freeShipping: false,
        };
    };

    randomNumber = (min, max) => {
        return Math.floor(Math.random() * (max - min) + min);
    };

    parseEbayProduct = (item) => {
        log("ebay item to be parsed", item);
        const { ebayProductPrice, freeShipping } =
            this.calculateProductPrice(item);
        log("ebayProductPrice, freeShipping", ebayProductPrice, freeShipping);

        try {
            const product = {
                id: item.hasOwnProperty("itemId") ? item.itemId : "",
                itemId: item.hasOwnProperty("legacyItemId")
                    ? item.legacyItemId
                    : "",
                name: item.hasOwnProperty("title") ? item.title : "",

                price: item.hasOwnProperty("price") ? item.price.value : 0,
                compareAtPrice: item.hasOwnProperty("price")
                    ? item.price.value
                    : 0,
                salePrice: item.hasOwnProperty("price") ? item.price.value : 0,
                images: item.hasOwnProperty("image")
                    ? new Array(new Array(item.image.imageUrl))
                    : null,
                availability: "in-stock",
                badges: [],
                category:
                    item.hasOwnProperty("categories") &&
                    item.categories.length > 0
                        ? item.categories[0].categoryId
                        : null,
                categoryName: null,
                business: null,
                description: item.hasOwnProperty("title") ? item.title : "",
                weight: null,
                weightScale: "",
                branch: process.env.REACT_APP_DEFAULT_BRANCH,
                currency: item.hasOwnProperty("price")
                    ? item.price.currency
                    : "",
                brand: item.hasOwnProperty("brand")
                    ? {
                          title: item.brand,
                      }
                    : { title: "" },
                regularPrice: item.hasOwnProperty("price")
                    ? item.price.value
                    : null,
                discountPrice: item.hasOwnProperty("price")
                    ? item.price.value
                    : null,
                vat: 0,
                productFacts: item.hasOwnProperty("description")
                    ? item.description
                    : [],
                productSpecs: [],
                ebaySpecs: item.hasOwnProperty("localizedAspects")
                    ? item.localizedAspects
                    : [],
                itemCustomId: "",
                merchant: "ebay",
                itemWebUrl: item.hasOwnProperty("itemAffiliateWebUrl")
                    ? item.itemAffiliateWebUrl
                    : item.hasOwnProperty("itemWebUrl")
                    ? item.itemWebUrl
                    : null,
                itemGroupId: item.hasOwnProperty("primaryItemGroup")
                    ? item.primaryItemGroup.itemGroupId
                    : null,
                itemUrl: `${ebayUsaUrl}/product/${item.itemId}`,
                condition: item.hasOwnProperty("condition")
                    ? item.condition
                    : null,
                conditionId: item.hasOwnProperty("conditionId")
                    ? item.conditionId
                    : null,
                freeShipping: freeShipping,
                itemAffiliateWebUrl: item.hasOwnProperty("itemAffiliateWebUrl"),
            };
            log("ebay item after parsing", product);
            return product;
        } catch (error) {
            log("error in parseProduct", error);
            return null;
        }
    };
    saveProductsToLocalDB = (products) => {
        return fetch(`${process.env.REACT_APP_API}/v1/ebay/createEbayItem`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(products),
        })
            .then((response) => response.json())
            .then((parsedJSON) => {
                return parsedJSON;
            })

            .catch((error) => {
                log("saveProductsToLocalDB error", error);
                return error;
            });
    };

    saveProductsToGoogleMerchant = (products) => {
        log("calling saveProductsToGoogleMerchant");
        return fetch(`${process.env.REACT_APP_API}/v1/googleapis/insert`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(products),
        })
            .then((response) => response.json())
            .then((parsedJSON) => {
                return parsedJSON;
            })

            .catch((error) => {
                log("saveProductsToGoogleMerchant error", error);
                return error;
            });
    };

    setSiteMap = (products) => {
        return fetch(`${process.env.REACT_APP_API}/v1/utility/sitemap`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(products),
        })
            .then((response) => response.json())
            .then((parsedJSON) => {
                return parsedJSON;
            })

            .catch((error) => {
                log("setSiteMap error", error);
                return error;
            });
    };
}
