// react
import React, { Component } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
//import { Helmet } from "react-helmet";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

// application
import languages from "../i18n";
import { localeChange } from "../store/locale";
import { currencyChange } from "../store/currency";
import { setCountry } from "../store/map";

// pages
import Layout from "./Layout";
import HomePageOne from "./home/HomePageOne";
import HomePageTwo from "./home/HomePageTwo";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

class Root extends Component {
    componentDidMount() {
        //ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG);

        const advancedMatching = { em: "shiptohome.pk@gmail.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
        };
        const tagManagerArgs = {
            gtmId: "GTM-WLGPGZT",
        };
        ReactPixel.init("1273047469720725", advancedMatching, options);
        ReactPixel.pageView(); // For tracking page view
        //initialize google tag manager
        TagManager.initialize(tagManagerArgs);

        // preloader
        setTimeout(() => {
            const preloader = document.querySelector(".site-preloader");

            preloader.addEventListener("transitionend", (event) => {
                if (event.propertyName === "opacity") {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add("site-preloader__fade");
        }, 0);

        /*
           const { currencyChange: changeCurrency } = this.props;
        
            changeCurrency({
                code: process.env.REACT_APP_DEFAULT_CURRENCY_CODE,
                symbol: process.env.REACT_APP_DEFAULT_CURRENCY_SYMBOL,
                name: process.env.REACT_APP_DEFAULT_CURRENCY_NAME,
            })
        */
        const { setCountry: changeCountry } = this.props;
        changeCountry("US");

        // this is for demo only, you can delete it
        const { localeChange: changeLocale } = this.props;
        const direction = new URLSearchParams(window.location.search).get(
            "dir",
        );

        if (direction !== null) {
            changeLocale(direction === "rtl" ? "ar" : "en");
        }
    }

    render() {
        const { locale, currency, country } = this.props;
        const { messages, direction } = languages[locale];
        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <GoogleReCaptchaProvider
                            reCaptchaKey={
                                process.env
                                    .REACT_APP_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY
                            }
                        >
                            <Helmet
                                htmlAttributes={{
                                    lang: locale,
                                    dir: direction,
                                }}
                            />
                            <ScrollContext>
                                <Switch>
                                    <Route
                                        path="/home-two"
                                        render={(props) => (
                                            <Layout
                                                {...props}
                                                headerLayout="compact"
                                                homeComponent={HomePageTwo}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        render={(props) => (
                                            <Layout
                                                {...props}
                                                headerLayout="default"
                                                homeComponent={HomePageOne}
                                            />
                                        )}
                                    />
                                    <Redirect to="/" />
                                </Switch>
                            </ScrollContext>
                        </GoogleReCaptchaProvider>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
    currency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    currency: state.currency,
    country: state.map.country,
});

const mapDispatchToProps = {
    localeChange,
    currencyChange,
    setCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
