import notificationAPI from "./notifications";
import Swal from "sweetalert2";
const notification = new notificationAPI();
const convertedVapidKey = urlBase64ToUint8Array(
  process.env.REACT_APP_PUBLIC_VAPID_KEY
);
const log = require("../components/shared/helper/MartLogger.js").logger;

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription) {
  log("print subscription", subscription);
  //console.log("localStorage user", localStorage.getItem('fkUser'))
  const data = {
    subscription: subscription,
    user: JSON.parse(localStorage.getItem("fkUser"))
      ? JSON.parse(localStorage.getItem("fkUser"))._id
      : null,
  };

  log("sendSubscription", data);
  return fetch(`${process.env.REACT_APP_API}/v1/notifications/push/subscribe`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      domain: process.env.REACT_APP_DOMAIN,
    },
  })
    .then((response) => response.json())
    .then((response) => {
      log("subscribed response", response);
      //send welcome push notification
      const data = {
        subscription: response.subscription,
        title: "Hello",
        body: `Welcome to ${process.env.REACT_APP_TENANT_BUSINESS_NAME}`,
        clickAction: "/",
        icon: `https://${process.env.REACT_APP_TENANT_BUSINESS_DOMAIN_NAME}/${process.env.REACT_APP_FAVICON}`,
      };
      log("push notification - to be done", data);
      notification.sendPush(data);
      return response;
    })
    .catch((error) => {
      log("error in subscribed", error);
      return error;
    });
}

const beFriends = (registration) => {
  log("in beFriends");
  Swal.fire({
    //title: "Ship wants to be friend with you",
    text: "Let's befriend to be the first one to know exclusive deals.",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "Not Interested",
    confirmButtonText: "Be Friends",
    position: "top-start",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      registration.pushManager
        .subscribe({
          applicationServerKey: convertedVapidKey,
          userVisibleOnly: true,
        })
        .then(function (newSubscription) {
          log("New subscription added.", newSubscription);
          sendSubscription(newSubscription);
        })
        .catch(function (e) {
          if (Notification.permission !== "granted") {
            log("Permission was not granted.");
          } else {
            console.error(
              "An error ocurred during the subscription process.",
              e
            );
          }
        });
    }
  });
};

/*
export function subscribeUser() {
  log("subscribeUser...", navigator);
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          log("Push manager unavailable.");
          return;
        }
        registration.pushManager
          .getSubscription()
          .then(function (existedSubscription) {
            if (existedSubscription === null) {
              log("No subscription detected, make a request.");
              //first show the custom popup
              beFriends(registration);
            } else {
              log("Existed subscription detected.", existedSubscription);
              sendSubscription(existedSubscription);
            }
          });
      })
      .catch(function (e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e
        );
      });
  }
}
*/

export function subscribeUser() {
  log("subscribeUser...", navigator);
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) {
          log("Push manager unavailable.");
          return;
        }
        registration.pushManager
          .getSubscription()
          .then(function (existedSubscription) {
            if (existedSubscription === null) {
              log("No subscription detected, make a request.");
              registration.pushManager
                .subscribe({
                  applicationServerKey: convertedVapidKey,
                  userVisibleOnly: true,
                })
                .then(function (newSubscription) {
                  log("New subscription added.", newSubscription);
                  sendSubscription(newSubscription);
                })
                .catch(function (e) {
                  if (Notification.permission !== "granted") {
                    log("Permission was not granted.");
                  } else {
                    console.error(
                      "An error ocurred during the subscription process.",
                      e
                    );
                  }
                });
            } else {
              log("Existed subscription detected.", existedSubscription);
              sendSubscription(existedSubscription);
            }
          });
      })
      .catch(function (e) {
        console.error(
          "An error ocurred during Service Worker registration.",
          e
        );
      });
  }
}
