// react
import React, { useState, useEffect } from "react";

// third-party
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
// application
import Dropdown from "./Dropdown";
import DropdownCurrency from "./DropdownCurrency";
import DropdownLanguage from "./DropdownLanguage";
import utilsAPI from "../../services/utils";
import { connect } from "react-redux";
import { locationviewOpen } from "../../store/locationview/locationviewActions";
import { logout } from "../../store/auth/authActions";
import { auth } from "../../configs/firebase.js";

const log = require("../shared/helper/MartLogger.js").logger;

function Topbar(props) {
    const utils = new utilsAPI();
    const links = [
        //{
        //    title: (
        //        <FormattedMessage id="topbar.aboutUs" defaultMessage="About" />
        //    ),
        //    url: "/site/about-us",
        //},
        {
            title: (
                <FormattedMessage
                    id="topbar.contacts"
                    defaultMessage="Contact"
                />
            ),
            url: "/site/contact-us",
        },
        //  { title: <FormattedMessage id="topbar.storeLocation" defaultMessage="Store Location" />, url: '' },
        //  { title: <FormattedMessage id="topbar.trackOrder" defaultMessage="Track Order" />, url: '/shop/track-order' },
        //  { title: <FormattedMessage id="topbar.blog" defaultMessage="Blog" />, url: '/blog/category-classic' },
    ];

    //call redux action to open location view if address is not available
    /*
    useEffect(()=>{
        if(JSON.parse(localStorage.getItem('fkAddress'))==null){
            props.locationviewOpen()
        } 
        log("props.locationName", props.locationName)
    },[])
    */

    const accountLinks = [
        //    { title: 'Dashboard', url: '/account/dashboard' },
        { title: "Edit Profile", url: "/account/profile" },
        { title: "Order History", url: "/account/orders" },
        { title: "Addresses", url: "/account/addresses" },
        //    { title: 'Password', url: '/account/password' },
        { title: "Logout", url: "/account/login" },
    ];

    const linksList = links.map((item, index) => (
        <div key={index} className="topbar__item topbar__item--link">
            <Link className="topbar-link" to={item.url}>
                {item.title}
            </Link>
        </div>
    ));
    const handleOnlickLocationView = (event) => {
        event.preventDefault();
        props.locationviewOpen(); //calling redux action to open the locationViewer
    };

    //log("localstorage - topbar", utils.getUserFromLocalStorage())

    const handleOnClick = (event) => {
        log("topbar, on click", event);
        if (event.title == "Logout") {
            props.logout(); //calling redux
            //log("localstorage after logout", JSON.parse(localStorage.getItem('fkUser')))
            auth.signOut().then((result) => {
                //log("signout from firebase")
            });
        }
        props.history.push(event.url); //move page to clicked link
    };

    return (
        <div className="site-header__topbar topbar">
            <div className="topbar__container container">
                <div className="topbar__row">
                    {linksList}
                    <div className="topbar__item topbar__item--link">
                        <FormattedMessage
                            id="topbar.deliveryAddress"
                            defaultMessage="Language"
                        />
                        <Link
                            to=""
                            onClick={handleOnlickLocationView}
                            style={{ paddingLeft: 4 }}
                        >
                            {props.locationName ? (
                                utils.truncateString(props.locationName, 50)
                            ) : (
                                <FormattedMessage id="topbar.choose" />
                            )}
                        </Link>
                    </div>

                    <div className="topbar__spring" />
                    {!JSON.parse(localStorage.getItem("fkUser")) && (
                        <div className="topbar__item">
                            <Link className="topbar-link" to="/account/login">
                                Login &nbsp;
                            </Link>
                        </div>
                    )}
                    {JSON.parse(localStorage.getItem("fkUser")) && (
                        <div className="topbar__item">
                            <Dropdown
                                title={
                                    <FormattedMessage
                                        id="topbar.myAccount"
                                        defaultMessage="My Account"
                                    />
                                }
                                items={accountLinks}
                                onClick={handleOnClick}
                            />
                        </div>
                    )}
                    {
                        <div className="topbar__item">
                            <DropdownCurrency />
                        </div>
                    }
                    {
                        <div className="topbar__item">
                            <DropdownLanguage />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
    address: state.map.address,
    locationName: state.map.locationName,
    token: state.auth.token,
    user: state.auth.user,
});

const mapDispatchToProps = {
    locationviewOpen: locationviewOpen,
    logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar));
