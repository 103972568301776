// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import ProductTabDescription from "./ProductTabDescription";
import ProductTabSpecification from "./ProductTabSpecification";
import ProductTabReviews from "./ProductTabReviews";
import MarttimeCalendar from "../shared/datepicker/MarttimeCalendar";
import PostalCode from "../shared/postalcode/PostalCode";
import { setDeliveryMethod } from "../../store/delivery";

class DeliveryTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //   currentTab: 'home',
            currentTab: this.props.deliveryMethod,
        };

        console.log("deliveryMethod", this.props.deliveryMethod);
    }

    componentWillReceiveProps(nextProps) {
        console.log(
            "componentWillReceiveProps",
            nextProps.deliveryMethod,
            this.props.deliveryMethod
        );
        if (nextProps.deliveryMethod !== this.props.deliveryMethod) {
            this.setState({
                currentTab: nextProps.deliveryMethod,
            });
        }
    }

    setTab = (newTab) => {
        this.setState(() => ({ currentTab: newTab }));
        this.props.setDeliveryMethod(newTab);
    };

    render() {
        const { currentTab } = this.state;
        const { withSidebar, deliveryMethod, deliverySlots } = this.props;
        const classes = classNames("product-tabs", {
            "product-tabs--layout--sidebar": withSidebar,
        });

        console.log("render deliveryMethod", deliveryMethod);

        const tabs = [
            {
                key: "store",
                title: "Get in Store",
                content: <div> Store Address will be displayed here </div>,
            },
            {
                key: "home",
                title: "Home Delivery",
                content: <MarttimeCalendar deliverySlots={deliverySlots} />,
            },
        ];

        const tabsButtons = tabs.map((tab) => {
            const classes = classNames("product-tabs__item", {
                "product-tabs__item--active": currentTab === tab.key,
            });

            return (
                <button
                    key={tab.key}
                    type="button"
                    onClick={() => this.setTab(tab.key)}
                    className={classes}
                >
                    {tab.title}
                </button>
            );
        });

        const tabsContent = tabs.map((tab) => {
            const classes = classNames("product-tabs__pane", {
                "product-tabs__pane--active": currentTab === tab.key,
            });

            return (
                <div key={tab.key} className={classes}>
                    {tab.content}
                </div>
            );
        });

        return (
            <div className={classes}>
                <div className="product-tabs__list">{tabsButtons}</div>
                <div className="product-tabs__content">{tabsContent}</div>
            </div>
        );
    }
}

DeliveryTabs.propTypes = {
    withSidebar: PropTypes.bool,
};

DeliveryTabs.defaultProps = {
    withSidebar: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    status: state.delivery.status,
    deliveryMethod: state.delivery.deliveryMethod,
});

const mapDispatchToProps = {
    setDeliveryMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryTabs);
