import {
    MAP_SET_ADDRESS,
    MAP_SET_LOCATIONNAME,
    MAP_SET_COUNTRY,
} from "./mapActionTypes";
import axios from "axios";
const log = require("../../components/shared/helper/MartLogger.js").logger;

const initState = {
    address: JSON.parse(localStorage.getItem("fkAddress")) || null,
    locationName:
        JSON.parse(localStorage.getItem("fkAddress")) !== null
            ? JSON.parse(localStorage.getItem("fkAddress")).formattedAddress
            : null,
};

const fetchCountry = async () => {
    try {
        const response = await axios.get(
            "https://ipinfo.io?token=cf9d7763268b78",
        );
        log("fetchCountry", response);
        return Promise.resolve(response.data.country);
    } catch (error) {
        log("Error fetching the country:", error);
        return Promise.resolve("US");
    }
};

const mapReducer = (state = initState, action) => {
    log("mapReducer - initial state:", state);
    log("mapReducer - action:", action);

    if (action.type === MAP_SET_ADDRESS) {
        state.address = action.address;
        localStorage.setItem("fkAddress", JSON.stringify(state.address));
        //console.log("mapReducer - localStorage",JSON.parse(localStorage.getItem('fkAddress')))
        log("action type, updated state: ", state);
        //console.log("JSON.stringify updated state: ", JSON.stringify(state))
        return {
            ...state,
        };
    } else if (action.type === MAP_SET_LOCATIONNAME) {
        state.locationName = action.locationName;
        return {
            ...state,
        };
    } else if (action.type === MAP_SET_COUNTRY) {
        state.country = fetchCountry();
        return {
            ...state,
        };
    } else {
        return state;
    }
};
export default mapReducer;
