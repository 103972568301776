import { POSTAL_CODE_VERIFIED, SET_DELIVERY_METHOD, SET_POSTAL_CODE, SET_DATE, SET_START_TIME, SET_END_TIME } from './deliveryActionTypes';

export const postalCodeVerify = (status) => ({
    type: POSTAL_CODE_VERIFIED,
    status : status,
});

export const setDeliveryMethod = (deliveryMethod) => ({
    type: SET_DELIVERY_METHOD,
    deliveryMethod : deliveryMethod,
});

export const setPostalCode = (postalCode) => ({
    type: SET_POSTAL_CODE,
    postalCode : postalCode,
});

export const setDate = (date) => ({
    type: SET_DATE,
    date : date,
});

export const setStartTime = (startTime) => ({
    type: SET_START_TIME,
    startTime : startTime,
});

export const setEndTime = (endTime) => ({
    type: SET_END_TIME,
    endTime : endTime,
});



