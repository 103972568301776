// react
import React, { Component } from "react";

// third-party
import { Link } from "react-router-dom";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";

// application
import Pagination from "../shared/Pagination";

// data stubs
//import dataOrders from '../../data/accountOrders';
import theme from "../../data/theme";
import orderAPI from "../../services/order";
const log = require("../shared/helper/MartLogger.js").logger;

export default class AccountPageOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            page: 1,
            sessionExpired: false,
        };

        this.order = new orderAPI();
    }

    componentDidMount() {
        log("OrderHistory - user", JSON.parse(localStorage.getItem("fkUser")));
        if (JSON.parse(localStorage.getItem("fkUser")) != null) {
            this.order
                .getOrderByUserId(
                    JSON.parse(localStorage.getItem("fkUser"))._id
                )
                .then((response) => {
                    if (response == 401 || response == 403) {
                        this.setState({
                            sessionExpired: true,
                        });
                        this.props.history.push("/account/login");
                    }
                    const data = response.map((order) => ({
                        _id: order._id,
                        orderId: order.orderId,
                        date: order.createdAt,
                        status: order.orderPaymentStatus,
                        total: order.orderAmount,
                    }));
                    this.setState({
                        orders: data,
                    });
                })
                .catch((error) => {
                    log("error in orders history", error);
                });
        }
    }

    handlePageChange = (page) => {
        this.setState(() => ({ page }));
    };

    render() {
        const { page, orders, sessionExpired } = this.state;

        const ordersList = orders.map((order) => (
            <tr key={order._id}>
                <td>
                    <Link
                        to={`/account/orders/orderDetail/${order._id}`}
                    >{`${order.orderId}`}</Link>
                </td>
                <td>{order.date}</td>
                <td>{order.status}</td>
                <td>{order.total}</td>
            </tr>
        ));

        return (
            <div className="card">
                <Helmet>
                    <title>{`Order History — ${theme.name}`}</title>
                </Helmet>

                <div className="card-header">
                    <h5>Order History</h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>{ordersList}</tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    <Pagination
                        current={page}
                        total={3}
                        onPageChange={this.handlePageChange}
                    />
                </div>
                {sessionExpired && (
                    <FormattedMessage
                        id="common.sessionExpired"
                        defaultMessage="Your session is expired. Please login."
                    >
                        {(msg) => toast.success(`${msg}`, { autoClose: 10000 })}
                    </FormattedMessage>
                )}
            </div>
        );
    }
}
