// react
import React, { useCallback, useEffect, useRef, useState } from "react";

// third-party
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

// application
import { Cross20Svg, Search20Svg } from "../../svg";
import Suggestions from "./Suggestions";

// data stubs
import products from "../../data/shopProducts";
import itemsAPI from "../../services/items";
import utilsAPI from "../../services/utils";
import ebayAPI from "../../services/ebay";
import { ebayUsaUrl } from "../../url";
import { createBrowserHistory } from "history";
import TagManager from "react-gtm-module";
const log = require("../shared/helper/MartLogger.js").logger;

function Search(props) {
    const offset = 0;
    const limit = 300;
    const [searchPlaceholder, setSearchPlaceholder] = useState("");
    const { context, className, inputRef, onClose, location, address } = props;
    const utils = new utilsAPI();
    const item = new itemsAPI();
    const ebay = new ebayAPI();
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [query, setQuery] = useState("");
    const wrapper = useRef(null);
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setSuggestionsOpen(false);
    }, [onClose]);

    useEffect(() => {
        //location.pathname.includes(ebayUsaUrl)
        //    ? setSearchPlaceholder("Search eBay")
        //    : setSearchPlaceholder("Search Shiptohome");
        setSearchPlaceholder("Search worldwide products");
    }, [location]);

    useEffect(() => close(), [close, location]);

    useEffect(() => {
        const onGlobalClick = (event) => {
            if (wrapper.current && !wrapper.current.contains(event.target)) {
                close();
            }
        };

        document.addEventListener("mousedown", onGlobalClick);

        return () => document.removeEventListener("mousedown", onGlobalClick);
    }, [close]);

    const searchDBItems = () => {
        item.searchItems(
            encodeURIComponent(query),
            address ? address.latitude : process.env.REACT_APP_DEFAULT_LATITUDE,
            address
                ? address.longitude
                : process.env.REACT_APP_DEFAULT_LONGITUDE,
        )
            .then((items) => {
                log("search result raw..", items);
                if (
                    items.finalSearchedItem !== undefined &&
                    items.finalSearchedItem.length
                ) {
                    const products = utils.parseProductArray(items);
                    log("suggested products", products);
                    setSuggestedProducts(products);
                    setHasSuggestions(true);
                    setSuggestionsOpen(true);
                } else {
                    log("search result is empty");
                    setHasSuggestions(false);
                    setSuggestedProducts([]);
                }
            })
            .catch((error) => {
                log("error in searchItems - search.jsx", error);
            });
    };

    const ebaySearch = async () => {
        try {
            const response = await ebay.search(
                encodeURIComponent(query),
                limit,
                offset,
            );
            log("ebay search response", response);
            const products = ebay.parseEbayProductArray(response);
            log("ebay suggested products", products);
            setSuggestedProducts(products);
            setHasSuggestions(true);
            setSuggestionsOpen(true);
            return Promise.resolve(products);
        } catch (error) {
            log("error in ebay search", error);
            setHasSuggestions(false);
            setSuggestedProducts([]);
            return Promise.reject(new Error(error));
        }
    };

    useEffect(() => {
        log("window.location", window.location.pathname);
        if (query === "") {
            setHasSuggestions(false);
            setSuggestedProducts([]);
            return undefined;
        }
        log("encoded query", encodeURIComponent(query));
        /*
        window.location.pathname.includes(ebayUsaUrl)
            ? ebaySearch()
            : searchDBItems();
            */

        //for now, only show suggestion if it is not ebay due to rate limit of 5000 api calls
        //if (!window.location.pathname.includes(ebayUsaUrl)) searchDBItems();
    }, [query]);

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (
                !document.activeElement ||
                document.activeElement === document.body
            ) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (
                wrapper.current &&
                !wrapper.current.contains(document.activeElement)
            ) {
                close();
            }
        }, 10);
    };

    const handleKeyDown = (event) => {
        // Escape.
        if (event.which === 27) {
            close();
        }
    };

    const sendGoogleTag = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: "clickHeaderSearch",
                searchTerm: query,
            },
        });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (inputRef && inputRef.current) {
            inputRef.current.blur();
        }

        //const searchPath = window.location.pathname.includes(ebayUsaUrl)
        //    ? process.env.REACT_APP_EBAY_SEARCH_PATH
        //    : process.env.REACT_APP_SEARCH_PATH;

        sendGoogleTag();

        const searchPath = window.location.pathname.includes(ebayUsaUrl)
            ? process.env.REACT_APP_EBAY_SEARCH_PATH
            : process.env.REACT_APP_CJ_SEARCH_PATH;
        window.location = searchPath + encodeURIComponent(query);
    };

    const rootClasses = classNames(
        `search search--location--${context}`,
        className,
        {
            "search--suggestions-open": suggestionsOpen,
            "search--has-suggestions": hasSuggestions,
        },
    );

    const closeButton =
        context !== "mobile-header" ? (
            ""
        ) : (
            <button
                className="search__button search__button--type--close"
                type="button"
                onClick={close}
            >
                <Cross20Svg />
            </button>
        );

    return (
        <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
            <div className="search__body">
                <form
                    className="search__form"
                    onSubmit={handleSubmit}
                    action="."
                >
                    <input
                        ref={inputRef}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        value={query}
                        className="search__input"
                        name="search"
                        placeholder={searchPlaceholder}
                        aria-label="Site search"
                        type="search"
                        autoComplete="off"
                    />
                    <button
                        className="search__button search__button--type--submit"
                        type="submit"
                    >
                        <Search20Svg />
                    </button>
                    {closeButton}
                    <div className="search__border" />
                </form>

                <Suggestions
                    className="search__suggestions"
                    context={context}
                    products={suggestedProducts}
                    query={encodeURIComponent(query)}
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    address: state.map.address,
});

export default connect(mapStateToProps)(withRouter(Search));
