import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
//import NodeGeocoder from "node-geocoder";
import { connect } from "react-redux";
import { setAddress, setLocationName } from "../../../store/map/mapActions";
import { locationviewClose } from "../../../store/locationview/locationviewActions";
import { cartRecalculateShipping } from "../../../store/cart/cartActions";
import utilsAPI from "../../../services/utils";
import MapControl from "./MapControl";

const log = require("../helper/MartLogger.js").logger;

function Map(props) {
  const utils = new utilsAPI();
  const localStorageAddress = JSON.parse(localStorage.getItem("fkAddress"));
  const latitude = localStorageAddress
    ? localStorageAddress.latitude
    : 31.4828641;
  const longitude = localStorageAddress
    ? localStorageAddress.longitude
    : 74.1943058;
  const [center, setCenter] = useState({
    lat: latitude,
    lng: longitude,
  });
  const [address, setAddress] = useState(localStorageAddress);
  const refMap = useRef(null);
  const geocoder = new window.google.maps.Geocoder();

  const options = {
    provider: "google",
    // Optional depending on the providers
    httpAdapter: "https", // Default
    apiKey: process.env.REACT_APP_GOOGLE_MAPS, // for Mapquest, OpenCage, Google Premier
    formatter: null, // 'gpx', 'string', ...
  };

  //const geocoder = NodeGeocoder(options);

  const defaultMapOptions = {
    fullscreenControl: false,
    gestureHandling: "greedy",
    streetViewControl: false,
    mapTypeControl: false,
  };

  const google = (window.google = window.google ? window.google : {});
  log("google::", google);
  log(
    "props - address",
    props.address,
    "localStorageAddress",
    localStorageAddress
  );
  log("map.jsx address", address);
  log("localStorage latitude:", latitude, "localStorage longitude:", longitude);

  const getCurrentPosition = () => {
    log("getCurrentPosition");

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
      maximumAge: Infinity,
    });

    function successCallback(position) {
      var center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      log("getCurrentPosition - successCallback", center);
      setCenter(center); // move the marker to new location
      refMap.current.panTo(center); //move the map to new location
      addressFromLatLng(center);
    }

    function errorCallback(error) {
      log("getCurrentPosition - error", error);
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Woaah..Please enable location permission in your browser.");

        case error.POSITION_UNAVAILABLE:
          log("Location information is unavailable.");

        case error.TIMEOUT:
          log("The request to get user location timed out.");

        case error.UNKNOWN_ERROR:
          log("An unknown error occurred.");
      }
    }
  };

  useEffect(() => {
    if (localStorageAddress == null) {
      //  getCurrentPosition(); //your live position
    }
  }, []);
  /*
  navigator.permissions.query({
    name: 'geolocation'
  }).then(function(result) {
    log(result)
    if (result.state == 'granted') {

    } else if (result.state == 'prompt') {
      getCurrentPosition()
    } else if (result.state == 'denied') {
        alert('Location permission is denied. Please enable the permission in your browser settings.');
    }
  });
  */

  //call when you drag the map
  const handleBoundsChanged = () => {
    log("handleBoundsChanged");
    const lat = refMap.current.getCenter().lat();
    const lng = refMap.current.getCenter().lng();
    const mapCenter = {
      lat: lat,
      lng: lng,
    };
    setCenter(mapCenter); // move the marker to new location
  };

  const addressFromLatLng = (center) => {
    geocoder.geocode({ location: center }, function (results, status) {
      if (status === "OK") {
        if (results[0]) {
          log("addressFromLatLng - results", results[0]);
          setAddress({
            formattedAddress: results[0].formatted_address,
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          });
        } else {
          log("addressFromLatLng - no results");
        }
      } else {
        log("Geocoder failed due to: " + status);
      }
    });
  };
  /*
  const addressFromLatLng = (center) => {
    geocoder.reverse({lat:center.lat, lon:center.lng}, function(err, res) {
      log("handleDragend - address",res,err);
      if(res!=undefined) {
        //update the address in searchbar when the drag ends
        setAddress({
          formattedAddress : res[0].formattedAddress,
          latitude : res[0].latitude,
          longitude : res[0].longitude,
          streetName : res[0].streetName,
          city : res[0].city,
          country : res[0].country,
        })
      }
    }); 
  }   
*/
  const handleOnClickContinue = () => {
    props.locationviewClose();
    window.location.reload();
  };

  //call when drag ends
  const handleDragend = () => {
    try {
      utils.addressFromLatLng(center).then(
        (address) => {
          log("address from utils -", address);
          setAddress(address);
        },
        (error) => {
          log("error in handleDragend", error);
        }
      );
    } catch (error) {
      log("error in handleDragend", error);
    }
  };

  //on address update
  useEffect(() => {
    log("props updates", props.address, props.locationName);
    if (props.address !== null && props.address.source == "searchbar") {
      log("in the searchbar props");
      const mapCenter = {
        lat: props.address.latitude,
        lng: props.address.longitude,
      };
      refMap.current.panTo(mapCenter); //move the map to new location
      setCenter(mapCenter); // move the marker to new location
    }
  }, [props.address]);

  //call redux action when address is updated.
  useEffect(() => {
    log("calling redux action - address", address);
    props.setAddress(address);
    props.setLocationName(address !== null ? address.formattedAddress : null);
    recalculateShipping(address);
  }, [address]);

  //calling action to recalculate shipping based on new address
  const recalculateShipping = (address) => {
    const { cart } = props;
    props.cartRecalculateShipping(cart, address);
  };

  const handleOnLoad = (map) => {
    log("handleOnLoad");
    const controlButtonDiv = document.createElement("div");
    ReactDOM.render(
      <div onClick={() => log("hi")}> hllo</div>,
      controlButtonDiv
    );
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);
  };

  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={13}
      defaultCenter={{ lat: center.lat, lng: center.lng }}
      onBoundsChanged={handleBoundsChanged}
      fullscreenControl={false}
      defaultOptions={defaultMapOptions}
      onDragEnd={handleDragend}
    >
      <Marker position={center} />
      <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
        <button
          type="submit"
          className="footer-newsletter__form-button btn btn-primary"
          style={{ marginBottom: 30 }}
          onClick={handleOnClickContinue}
        >
          Find Products Nearby
        </button>
      </MapControl>
      {/*
        <div position={google.maps.ControlPosition.TOP_CENTER}>
          <div>AAAAA</div>
        </div>
      */}
    </GoogleMap>
  );
}

//get updated props
const mapStateToProps = (state) => {
  log("calling mapStateToProps, map.jsx", state.map.address);
  return {
    address: state.map.address,
    locationName: state.map.locationName,
    cart: state.cart,
  };
};

//dispatch action
const mapDispatchToProps = (dispatch) => {
  log("mapDispatchToProps calling of map.jsx", dispatch);
  return {
    setAddress: (address) => {
      dispatch(setAddress(address));
    },
    setLocationName: (locationName) => {
      dispatch(setLocationName(locationName));
    },
    locationviewClose: () => {
      dispatch(locationviewClose());
    },
    cartRecalculateShipping: (cart, address) => {
      dispatch(cartRecalculateShipping(cart, address));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withGoogleMap(Map)));
