// react
import React, { Component } from "react";

// third-party
import { connect } from "react-redux";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Link, Redirect } from "react-router-dom";
import parse from "html-react-parser";
import { FormattedMessage } from "react-intl";

// application
import ReCaptchaComponent from "../shared/recaptcha/ReCaptchaComponent";
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

// data stubs
import payments from "../../data/shopPayments";
import theme from "../../data/theme";

// services
import orderAPI from "../../services/order";
import notificationAPI from "../../services/notifications";
import utilsAPI from "../../services/utils";
import paymentAPI from "../../services/payment";

// store
import { cartReset } from "../../store/cart";

// form
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
    isPossiblePhoneNumber,
    parsePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import flags from "react-phone-number-input/flags";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import LocationSearchInput from "../shared/location/LocationSearchInput";
import {
    GoogleReCaptcha,
    GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import googleAnalyticsAPI from "../../services/googleAnalytics";
import DeliveryTabs from "../shop/DeliveryTabs";

const log = require("../shared/helper/MartLogger.js").logger;

class ShopPageCheckout extends Component {
    payments = [];

    constructor(props) {
        super(props);
        this.transformItemsForOrder = this.transformItemsForOrder.bind(this);
        this.order = new orderAPI();
        this.notification = new notificationAPI();
        this.utils = new utilsAPI();
        this.payment = new paymentAPI();
        log("thispayment", this.payments);

        this.analytics = new googleAnalyticsAPI();
        let customerStreet =
            this.props.address !== null
                ? this.props.address.formattedAddress
                : null;
        this.state = {
            paymentList: [],
            payment: "cash",
            reCaptchaVerify: false,
            notes: "",
            shippingAddress: {
                customerFirstName: null,
                customerLastName: null,
                customerEmail: null,
                customerPhone: null,
                customerHouseNumber: null,
                customerStreet: null,
                customerLocation: null,
                notes: null,
                customerPostcode: null,
            },
            userAddresses: [],
            selectedAddress: [],
            checked: true,
        };
    }

    componentDidMount() {
        const { cart } = this.props;
        this.payment.getPaymentMethodList().then((response) => {
            this.setState({
                paymentList: response,
            });
            log("paylist", response);
        });
        log("mycart", cart);
        log("user localStorage", JSON.parse(localStorage.getItem("fkUser")));
        this.user = this.utils.getUserFromLocalStorage();
        //this.generateHashEasyPay()
        log("this.props.address", this.props.address);
        log(
            "componentDidMount ShopPageCheckout - user localStorage",
            this.user
        );
        if (this.user == null) {
            this.props.history.push("/account/login?source=checkout");
        } else {
            this.setState(
                {
                    userAddresses: this.user.address,
                    selectedAddress: this.user.address[0],
                    shippingAddress: {
                        customerPhone: this.user.phone,
                        customerStreet:
                            this.props.address !== null
                                ? this.props.address.formattedAddress
                                : null,
                        customerLat:
                            this.props.address !== null
                                ? this.props.address.latitude
                                : "",
                        customerLng:
                            this.props.address !== null
                                ? this.props.address.longitude
                                : "",
                        customerLocation:
                            this.props.address !== null
                                ? "https://www.google.com/maps/search/?api=1&query=" +
                                  this.props.address.latitude +
                                  "," +
                                  this.props.address.longitude
                                : "",
                    },
                },
                () => {
                    log("componentDidMount state", this.state);
                }
            );
            log("random number", Math.floor(Math.random() * 10));

            //send google analytics on checkout page load
            this.analytics.event({
                eventCategory: "checkout",
                eventAction: "page load",
                eventLabel: this.user.phone,
                eventValue: 1,
                path: window.location.pathname,
            });
        }
        /*
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY}`
        script.addEventListener("load", () => {
          window.grecaptcha.ready(function() {
            window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY, {action: 'homepage'}).then(function(token) {
                log("call the server", token)
                this.setState({
                    reCaptchaVerify : true
                }, () => {
                    log("reCaptchaVerify....")
                })

            });
          });
        })
        document.body.appendChild(script)
    */
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({ payment: event.target.value });
        }
    };

    handleEasypay = (props) => {
        log("handleEasypay..");
        props.history.push("/payment/easypaisa");
    };

    //input change handle
    selectChangeHandle = (event) => {
        const { id, value } = event.target;
        log("selected address", this.state.userAddresses[value]);
        this.setState(
            {
                selectedAddress: this.state.userAddresses[value],
            },
            () => {
                log(
                    "state updated - selected address",
                    this.state.selectedAddress
                );
            }
        );
    };

    phoneInputHandle = (value) => {
        log("phoneInputHandle", value);
        let { shippingAddress } = this.state;
        shippingAddress["customerPhone"] = value;
        this.setState(
            {
                shippingAddress,
            },
            () => {
                log("address state", this.state);
            }
        );
    };

    // route the user based on selected payment method
    afterOrderSaved = (data) => {
        const { payment } = this.state;
        if (payment === "payson") {
            this.props.history.push("/payment/payson", {
                orderId: data[0].parentOrderId,
            });
        } else if (payment === "swedbankpay") {
            const payeeReference = `${this.utils.generateID(
                8
            )}-${this.utils.generateID(8)}-${this.utils.generateID(12)}`;
            this.props.history.push(
                `/payment/swedbankpay?id=${data[0].parentOrderId}&payeeReference=${payeeReference}`,
                { orderId: data[0].parentOrderId }
            );
            //this.props.history.push(`/payment/swedbankpay`, { orderId : data[0].parentOrderId })
        } else {
            // cash on delivery
            this.props.history.push(
                `/confirmation?id=${data[0].parentOrderId}`,
                { data: data, user: this.user.phone }
            );
        }
    };

    // handleSubmit
    onSubmit = () => {
        let { shippingAddress } = this.state;
        const {
            cartReset,
            deliveryMethod,
            status,
            postalCode,
            date,
            startTime,
            endTime,
            address,
        } = this.props;
        shippingAddress["customerStreet"] = address.formattedAddress;
        shippingAddress["customerLat"] = address.latitude;
        shippingAddress["customerLng"] = address.longitude;
        shippingAddress["customerPostcode"] = postalCode;

        this.setState(
            {
                shippingAddress,
            },
            () => {
                log("streetAddress - state", this.state);
                if (
                    process.env.REACT_APP_SPLIT_ORDER_PER_BRANCH.toUpperCase() ===
                    "TRUE"
                ) {
                    this.saveSplittedOrder()
                        .then((data) => {
                            // multiple orders are saved
                            log("splitOrder then", data);
                            this.afterOrderSaved(data);
                        })
                        .catch((error) => {
                            log("error in saveSplittedOrder", error);
                            return;
                        });
                } else {
                    this.saveOrder()
                        .then((data) => {
                            // (single order is saved)
                            log("single order save then", data, [data]);
                            this.afterOrderSaved([data]);
                        })
                        .catch((error) => {
                            log("error in saveOrder", error);
                            return;
                        });
                }
            }
        );
    };

    //address change handle
    addressChangeHandle = (event) => {
        log("addressChangeHandle - e", event);
        const { id, value } = event.target;
        let { shippingAddress } = this.state;
        shippingAddress[id] = value;
        log("addressChangeHandle - id,value", id, value);
        this.setState(
            {
                shippingAddress,
            },
            () => {
                log("address state", this.state);
            }
        );
    };

    handleCheckbox = (event) => {
        this.setState(
            {
                checked: !this.state.checked,
            },
            () => {
                log("checkbox state updated", this.state.checked);
            }
        );
    };

    verifyRecaptchaToken = (token) => {
        log("reCaptchaKey token", token);

        this.utils
            .reCaptchaVerification(token)
            .then((response) => {
                log("success verifyRecaptchaToken", response);
                if (response.success === true) {
                    this.setState(
                        {
                            reCaptchaVerify: true,
                        },
                        () => {
                            log("reCaptchaVerify", this.state.reCaptchaVerify);
                        }
                    );
                }
            })
            .catch((error) => {
                log("error in verifyRecaptchaToken", error);
            });
    };

    executeSaveAsync = async (order) => {
        try {
            const response = await this.order.saveOrder(order);
            log("response from save order", response);
            if (response.code === 500) {
                return Promise.reject({
                    code: response.code,
                    message: response.message,
                    stack: response.stack,
                });
            }
            //this.notification.sendEmail(order, process.env.REACT_APP_SENDGRID_TEMPLATE_CREATE_ORDER)
            //this.notification.sendSMS(order, 'CREATE_ORDER')
            return Promise.resolve({
                _id: response._id,
                orderId: response.orderId,
                parentOrderId: response.parentOrderId,
            });
        } catch (error) {
            log("error in executeSaveAsync", error);
            //this.notification.sendEmail(order, process.env.REACT_APP_SENDGRID_TEMPLATE_CREATE_ORDER)
            //this.notification.sendSMS(order, 'CREATE_ORDER')
            return Promise.reject(error);
        }
    };

    saveSplittedOrder = async () => {
        try {
            const {
                cart,
                cartReset,
                currency,
                deliveryMethod,
                status,
                postalCode,
                date,
                startTime,
                endTime,
            } = this.props;
            const result = this.utils.splitCart(cart);
            const { cartCustomDuty } = this.utils.calculateCartSummary(
                result.splittedCart,
                currency
            );
            let data = [];
            const orderId = this.utils.generateID(9);
            log("saveSplittedOrder - cart", cart);
            log("saveSplittedOrder - splittedCart", result);

            return Promise.all(
                result.splittedCart.map((cart, i) => {
                    const transformedItems = this.transformItemsForOrder(cart);
                    log("transformedItems in cart", transformedItems, cart);

                    const cost = {
                        shippingCost: cart.shippingCostIncludingWeightCost
                            ? this.utils.convertCurrency(
                                  currency,
                                  { code: cart.currency },
                                  cart.shippingCostIncludingWeightCost
                              )
                            : 0, //save individual shipping cost for each splitted order
                        itemsCost: this.utils.convertCurrency(
                            currency,
                            { code: cart.currency },
                            cart.subtotal
                        ),
                        totalCost: this.utils.convertCurrency(
                            currency,
                            { code: cart.currency },
                            cart.total
                        ),
                        vat: this.utils.convertCurrency(
                            currency,
                            { code: cart.currency },
                            cart.vat
                        ),
                        customDuty: cartCustomDuty,
                    };

                    const order = {
                        parentOrderId: orderId,
                        orderId:
                            result.splittedCart.length === 1
                                ? orderId
                                : this.utils.generateID(9),
                        pickedItems: transformedItems.registeredItems,
                        nonRegisteredItems: transformedItems.nonRegisteredItems,
                        orderAmount: cost.totalCost,
                        cost: cost,
                        user: JSON.parse(localStorage.getItem("fkUser"))._id,
                        //business: process.env.REACT_APP_MARTTIME_BUSINESS,
                        branch: cart.shipping.branchId,
                        customerNotes: this.state.shippingAddress.notes,
                        shippingAddress: [this.state.shippingAddress],
                        orderStatus: "Booked",
                        currency: currency.symbol,
                        createdBy: JSON.parse(localStorage.getItem("fkUser"))
                            ._id,
                        deliveryMethod: deliveryMethod,
                        startSlot: new Date(
                            `${date} ${startTime} ${cart.shipping.timezone}`
                        ),
                        endSlot: new Date(
                            `${date} ${endTime} ${cart.shipping.timezone}`
                        ),
                    };
                    log("order to save", order);

                    return this.executeSaveAsync(order);
                })
            );
        } catch (error) {
            log("error in saveSplittedOrder", error);
            return Promise.reject(error);
        }
    };

    transformItemsForOrder = (cart) => {
        const { currency } = this.props;
        let registeredItems = [];
        let nonRegisteredItems = []; //such as third party merchant i.e ebay
        //cart items
        log("transformItemsForOrder cart", cart);
        cart.items.forEach((value, index, arry) => {
            let finalPrice =
                value.product.salePrice &&
                value.product.salePrice < value.product.price
                    ? value.product.salePrice
                    : value.product.price;

            log(
                "transformedItems data1",
                currency.code,
                cart.currency,
                finalPrice
            );
            finalPrice = this.utils.convertCurrency(
                currency,
                { code: cart.currency },
                finalPrice
            );

            let data = {
                id: value.product.id,
                quantity: value.quantity,
                unitPrice: finalPrice,
                name: value.product.name,
                image: value.product.images[0],
                totalPrice: finalPrice * value.quantity,
                itemCustomId: value.product.itemCustomId,
                itemWebUrl: value.product.itemWebUrl,
            };
            value.product.merchant
                ? nonRegisteredItems.push(data)
                : registeredItems.push(data);

            log(
                "registeredItems, nonRegisteredItems",
                registeredItems,
                nonRegisteredItems
            );
        });
        return {
            registeredItems,
            nonRegisteredItems,
        };
    };

    //we need to update this method as per our changes in saveSplittedOrder
    saveOrder = async () => {
        try {
            const {
                cart,
                cartReset,
                currency,
                deliveryMethod,
                status,
                postalCode,
                date,
                startTime,
                endTime,
            } = this.props;
            log("checkout - cart", cart);

            const transformedItems = this.transformItemsForOrder(cart);

            log(
                "localStorage - checkout",
                JSON.parse(localStorage.getItem("fkUser")).id
            );
            log("transformedItems in cart", transformedItems);

            const cost = {
                //shippingCost: process.env.REACT_APP_DEFAULT_SHIPPING_COST,
                shippingCost: cart.extraLines[0].price
                    ? cart.extraLines[0].price
                    : 0,
                itemsCost: cart.subtotal,
                totalCost: cart.total,
                vat: cart.vat,
            };

            const orderId = this.utils.generateID(9);

            const order = {
                parentOrderId: orderId,
                orderId: orderId,
                pickedItems: transformedItems.registeredItems,
                nonRegisteredItems: transformedItems.nonRegisteredItems,
                orderAmount: cart.total,
                cost: cost,
                user: JSON.parse(localStorage.getItem("fkUser")).id,
                business: process.env.REACT_APP_MARTTIME_BUSINESS,
                branch: process.env.REACT_APP_MARTTIME_BRANCH,
                customerNotes: this.state.shippingAddress.notes,
                shippingAddress: [this.state.shippingAddress],
                orderStatus: "Booked",
                currency: currency.symbol,
                createdBy: JSON.parse(localStorage.getItem("fkUser"))._id,
            };

            log("order to save", order);
            return Promise.resolve(this.executeSaveAsync(order));
        } catch (error) {
            log("error in saveOrder", error);
            return Promise.reject(error);
        }
    };
    // handle order save
    /*
    saveOrder = () => {
        const { cart, cartReset } = this.props;
        log("checkout - cart", cart)
        
        const transformedItems = this.transformItemsForOrder(cart)

        log("localStorage - checkout", JSON.parse(localStorage.getItem('fkUser')).id)
        log("transformedItems in cart", transformedItems)

        const cost = {
            //shippingCost: process.env.REACT_APP_DEFAULT_SHIPPING_COST,
            shippingCost: cart.extraLines[0].price,
            itemsCost: cart.subtotal,
            totalCost: cart.total,
        }

        const order = {
            orderId: this.utils.generateID(9),
            pickedItems: transformedItems,  
            orderAmount: cart.total,
            cost: cost,
            user: JSON.parse(localStorage.getItem('fkUser')).id,
            business: process.env.REACT_APP_MARTTIME_BUSINESS,
            branch: process.env.REACT_APP_MARTTIME_BRANCH,
            customerNotes: this.state.shippingAddress.notes,
            shippingAddress: [this.state.shippingAddress],
        }

        log("order to save", order)

        this.order.saveOrder(order).then(response =>{
            log("order saved successfully", response)
            //localStorage.removeItem('state');
            cartReset(); //calling action to reset the cart
            this.notification.sendEmail(order, process.env.REACT_APP_SENDGRID_TEMPLATE_CREATE_ORDER)
            this.notification.sendSMS(order, 'CREATE_ORDER')

            this.props.history.push('/confirmation/'+ response._id + '/' + response.orderId)

        })
        .catch(error =>{
            this.notification.sendEmail(order, process.env.REACT_APP_SENDGRID_TEMPLATE_CREATE_ORDER)
            this.notification.sendSMS(order)

            log("error in saving order", error)
        })

    }
    */

    renderVat() {
        const { cart, currency } = this.props;
        const result = this.utils.splitCart(cart);
        const {
            cartItemsVatPercentage,
            cartItemsVatTotal,
            cartServiceFeeVatPercentage,
            cartServiceFeeVatTotal,
        } = this.utils.calculateCartSummary(result.splittedCart, currency);

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    {cartItemsVatPercentage !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.vat"
                                    defaultMessage="Products Vat (Inclusive)"
                                />
                                &nbsp;(
                                {cartItemsVatPercentage}
                                %)
                            </th>
                            <td>
                                <Currency value={cartItemsVatTotal} />
                            </td>
                        </tr>
                    )}
                    {cartServiceFeeVatPercentage !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.serviceFeeVat"
                                    defaultMessage="Service Fee Vat (Inclusive)"
                                />
                                &nbsp;(
                                {cartServiceFeeVatPercentage}
                                %)
                            </th>
                            <td>
                                <Currency value={cartServiceFeeVatTotal} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </React.Fragment>
        );
    }

    renderTotals() {
        const { cart, currency } = this.props;
        const result = this.utils.splitCart(cart);
        const {
            cartItemsTotal,
            cartReserveFeeTotal,
            cartShippingFeeTotal,
            cartServiceFeeTotal,
            cartItemsVatTotal,
            cartServiceFeeVatTotal,
            cartServiceFeeVatPercentage,
            cartItemsVatPercentage,
            cartReserveFeePercentage,
            cartGrandTotal,
            cartQuantityTotal,
            cartCustomDuty,
        } = this.utils.calculateCartSummary(result.splittedCart, currency);

        return (
            <React.Fragment>
                <tbody className="checkout__totals-subtotals">
                    <tr>
                        <th>
                            <FormattedMessage
                                id="cart.subTotal"
                                defaultMessage="Subtotal"
                            />
                        </th>
                        <td>
                            <Currency value={cartItemsTotal} />
                        </td>
                    </tr>
                    {/*extraLines*/}
                    {cartServiceFeeTotal !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.totalServiceFee"
                                    defaultMessage="Total Service Fee"
                                />
                            </th>
                            <td>
                                <Currency value={cartServiceFeeTotal} />
                            </td>
                        </tr>
                    )}
                    <tr>
                        <th>
                            <FormattedMessage
                                id="cart.totalShipping"
                                defaultMessage="Total Shipping"
                            />
                        </th>
                        {cartShippingFeeTotal !== 0 && (
                            <td>
                                <Currency value={cartShippingFeeTotal} />
                            </td>
                        )}
                        {cartShippingFeeTotal === 0 && (
                            <td>
                            {process.env.REACT_APP_PER_KG_COST && (
                                <div>
                                    {" "}
                                    {
                                        process.env
                                            .REACT_APP_PER_KG_COST
                                    }
                                </div>
                            )}</td>

                        )}
                    </tr>

                    {cartReserveFeePercentage !== 0 && (
                        <tr>
                            <th>
                                <FormattedMessage
                                    id="cart.totalReserve"
                                    defaultMessage="Reserve Fee"
                                />{" "}
                                &nbsp; ({cartReserveFeePercentage}%)
                            </th>
                            <td>
                                <Currency value={cartReserveFeeTotal} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </React.Fragment>
        );
    }

    splitCart() {
        const { cart } = this.props;
        const result = this.utils.splitCart(cart);
        return result.splittedCart.map((cart) => {
            const currency = {
                code: cart.currency,
            };
            return (
                <React.Fragment>
                    {cart.items.map((item, i) => {
                        return (
                            <tr key={item.id}>
                                <td>{`${item.product.name} × ${item.quantity}`}</td>
                                <td>
                                    <Currency
                                        value={item.total}
                                        currency={currency}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                    {result.splittedCart.length > 1 && (
                        <React.Fragment>
                            <tr>
                                <td>
                                    <FormattedMessage
                                        id="cart.serviceFee"
                                        defaultMessage="ServiceFee"
                                    />
                                </td>
                                <td>
                                    <Currency
                                        value={cart.shipping.serviceFee}
                                        currency={currency}
                                    />
                                </td>
                            </tr>
                            <tr className="checkout__totals-split">
                                <td>
                                    <FormattedMessage
                                        id="cart.shipping"
                                        defaultMessage="Shipping"
                                    />
                                </td>
                                <td>
                                    <Currency
                                        value={
                                            cart.shippingCostIncludingWeightCost
                                        }
                                        currency={currency}
                                    />
                                </td>
                            </tr>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        });
    }

    renderCart() {
        const { cart, currency } = this.props;
        const items = this.splitCart();
        const result = this.utils.splitCart(cart);
        log("checkout - splitted cart", result, currency);
        const { cartGrandTotal } = this.utils.calculateCartSummary(
            result.splittedCart,
            currency
        );

        return (
            <table className="checkout__totals">
                <thead className="checkout__totals-header">
                    <tr>
                        <th>
                            <FormattedMessage
                                id="cart.product"
                                defaultMessage="Product"
                            />
                        </th>
                        <th>
                            <FormattedMessage
                                id="cart.total"
                                defaultMessage="Total"
                            />
                        </th>
                    </tr>
                </thead>
                <tbody className="checkout__totals-products">{items}</tbody>
                {this.renderTotals()}
                {this.renderVat()}
                <tfoot className="checkout__totals-footer">
                    <tr>
                        <th>
                            <FormattedMessage
                                id="cart.total"
                                defaultMessage="Total"
                            />
                        </th>
                        <td>
                            <Currency value={cartGrandTotal} />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    renderPaymentsList() {
        const { payment: currentPayment, paymentList } = this.state;

        const payments = paymentList.map((payment, index) => {
            const renderPayment = ({ setItemRef, setContentRef }) => (
                <li className="payment-methods__item" ref={setItemRef}>
                    <label className="payment-methods__item-header">
                        <span className="payment-methods__item-radio input-radio">
                            <span className="input-radio__body">
                                <input
                                    type="radio"
                                    className="input-radio__input"
                                    name="checkout_payment_method"
                                    value={payment.key}
                                    checked={index === 0}
                                    //checked={currentPayment === payment.key}
                                    onChange={this.handlePaymentChange}
                                />
                                <span className="input-radio__circle" />
                            </span>
                        </span>
                        <span className="payment-methods__item-title">
                            {payment.title}
                        </span>
                    </label>
                    <div
                        className="payment-methods__item-container"
                        ref={setContentRef}
                    >
                        <div className="payment-methods__item-description text-muted">
                            {payment.description}
                        </div>
                    </div>
                </li>
            );

            return (
                <Collapse
                    key={payment.key}
                    open={currentPayment === payment.key}
                    toggleClass="payment-methods__item--active"
                    render={renderPayment}
                />
            );
        });

        return (
            <div className="payment-methods">
                <ul className="payment-methods__list">{payments}</ul>
            </div>
        );
    }

    render() {
        const {
            cart,
            address,
            locationName,
            deliveryMethod,
            status,
            postalCode,
            date,
            startTime,
            endTime,
            currency,
        } = this.props;
        const { match, location, history } = this.props;
        const result = this.utils.splitCart(cart);
        const { cartCustomDuty } = this.utils.calculateCartSummary(
            result.splittedCart,
            currency
        );
        log("checkout page - cart", cart);
        log("checkout render splittedCart", result);

        log("this.state.snippet", this.state.snippet);
        log("location change", locationName, address);
        log(
            "deliveryMethod --",
            deliveryMethod,
            status,
            postalCode,
            date,
            startTime,
            endTime
        );

        if (cart.items.length < 1) {
            return <Redirect to="cart" />;
        }

        const breadcrumb = [
            { title: "Home", url: "" },
            { title: "Shopping Cart", url: "/shop/cart" },
            { title: "Checkout", url: "" },
        ];

        return (
            <React.Fragment>
                {/*<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY}>*/}
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>

                <PageHeader header="Checkout" breadcrumb={breadcrumb} />
                {/*
                <div
                    className="g-recaptcha"
                    data-sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY}
                    data-size="invisible"
                />
            */}

                <div className="checkout block">
                    <div className="container">
                        {/*
                        <div class="col-12">
                            <div class="alert alert-primary alert-lg mb-3 alert-dismissible fade show">
                                Due to lockdown situation, you may experience some delays in deliveries. We are currently delivering in LAHORE only. Thanks for your understanding.
                            </div>
                        </div>
                    */}
                        {result.splittedCart[0].shipping &&
                            result.splittedCart[0].shipping.deliverySlots &&
                            result.splittedCart[0].shipping.deliverySlots
                                .length > 0 && (
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <DeliveryTabs
                                            deliverySlots={
                                                result.splittedCart[0].shipping
                                                    .deliverySlots
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                        <Formik
                            enableReinitialize
                            validateOnChange={false}
                            initialValues={{
                                customerFirstName:
                                    this.state.shippingAddress
                                        .customerFirstName,
                                customerLastName:
                                    this.state.shippingAddress.customerLastName,
                                customerHouseNumber:
                                    this.state.shippingAddress
                                        .customerHouseNumber,
                                // customerStreet: this.state.shippingAddress.customerStreet,
                                customerStreet: locationName,
                                customerEmail:
                                    this.state.shippingAddress.customerEmail,
                                customerPhone:
                                    this.state.shippingAddress.customerPhone,
                                notes: this.state.shippingAddress.notes,
                                "checkout-terms": this.state.checked,
                                // reCaptchaVerify : this.state.reCaptchaVerify,
                            }}
                            validate={(values) => {
                                const errors = {};
                                log("validating..", values, errors);

                                if (!values.customerFirstName) {
                                    errors.customerFirstName = "Required";
                                }
                                if (!values.customerLastName) {
                                    errors.customerLastName = "Required";
                                }
                                if (!values.customerHouseNumber) {
                                    errors.customerHouseNumber = "Required";
                                }
                                if (!values.customerStreet) {
                                    errors.customerStreet = "Required";
                                }
                                if (!values.customerEmail) {
                                    errors.customerEmail = "Required";
                                }
                                if (!values.customerPhone) {
                                    errors.customerPhone = "Required";
                                }

                                if (
                                    isPossiblePhoneNumber(
                                        values.customerPhone
                                    ) === false
                                ) {
                                    errors.customerPhone =
                                        "Invalid phone number";
                                }

                                //if(values.reCaptchaVerify === false){
                                //    errors.reCaptchaVerify = "invalid"
                                //}

                                if (!this.utils.isEmpty(errors))
                                    window.scrollTo(0, 350);
                                return errors;
                            }}
                            onSubmit={(values, { validate, setSubmitting }) => {
                                log("onsubmit..", values, setSubmitting);
                                //if(!this.utils.isEmpty(errors)) window.scrollTo(0,150)
                                this.onSubmit(); //handle on submit
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-7">
                                            <div className="card mb-lg-0">
                                                <div className="card-body">
                                                    <h3 className="card-title">
                                                        <FormattedMessage
                                                            id="checkout.shippingDetails"
                                                            defaultMessage="Shipping details"
                                                        />
                                                    </h3>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="checkout-first-name">
                                                                <FormattedMessage
                                                                    id="checkout.firstName"
                                                                    defaultMessage="First Name"
                                                                />
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={
                                                                    "form-control " +
                                                                    (errors.customerFirstName
                                                                        ? "is-invalid"
                                                                        : "")
                                                                }
                                                                id="customerFirstName"
                                                                name="customerFirstName"
                                                                placeholder="First Name"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleChange(
                                                                        e
                                                                    );
                                                                    this.addressChangeHandle(
                                                                        e
                                                                    );
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .shippingAddress
                                                                        .customerFirstName
                                                                }
                                                            />

                                                            <div className="text-danger">
                                                                <small>
                                                                    {
                                                                        errors.customerFirstName
                                                                    }
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="checkout-last-name">
                                                                <FormattedMessage
                                                                    id="checkout.lastName"
                                                                    defaultMessage="Last Name"
                                                                />
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className={
                                                                    "form-control " +
                                                                    (errors.customerLastName
                                                                        ? "is-invalid"
                                                                        : "")
                                                                }
                                                                id="customerLastName"
                                                                placeholder="Last Name"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleChange(
                                                                        e
                                                                    );
                                                                    this.addressChangeHandle(
                                                                        e
                                                                    );
                                                                }}
                                                                value={
                                                                    this.state
                                                                        .shippingAddress
                                                                        .customerLastName
                                                                }
                                                            />

                                                            <div className="text-danger">
                                                                <small>
                                                                    {
                                                                        errors.customerLastName
                                                                    }
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="checkout-city">
                                                            <FormattedMessage
                                                                id="checkout.house"
                                                                defaultMessage="House/Apt #"
                                                            />
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={
                                                                "form-control " +
                                                                (errors.customerHouseNumber
                                                                    ? "is-invalid"
                                                                    : "")
                                                            }
                                                            id="customerHouseNumber"
                                                            placeholder="Enter your House/Apt #"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.addressChangeHandle(
                                                                    e
                                                                );
                                                            }}
                                                            value={
                                                                this.state
                                                                    .shippingAddress
                                                                    .customerHouseNumber
                                                            }
                                                        />

                                                        <div className="text-danger">
                                                            <small>
                                                                {
                                                                    errors.customerHouseNumber
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                    {/*
                                            <div className="form-group">
                                                <label htmlFor="checkout-street-address">Street Address</label>
                                                <textarea 
                                                    type="text"
                                                    id="customerStreet" 
                                                    className={"form-control " + (errors.customerStreet ? 'is-invalid' : '')}
                                                    rows="4" 
                                                    onChange={(e) => {handleChange(e);this.addressChangeHandle(e)}} 
                                                    value={this.state.shippingAddress.customerStreet}
                                                />  
                                                <div className="invalid-feedback">{errors.customerStreet}</div>
                                            </div>
                                        */}

                                                    <div className="form-group">
                                                        <label htmlFor="checkout-street-address">
                                                            <FormattedMessage
                                                                id="checkout.streetAddress"
                                                                defaultMessage="Street Address"
                                                            />
                                                        </label>
                                                        <LocationSearchInput
                                                            errors={
                                                                errors.customerStreet
                                                            }
                                                        />
                                                        <div className="text-danger">
                                                            <small>
                                                                {
                                                                    errors.customerStreet
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="checkout-email">
                                                            <FormattedMessage
                                                                id="checkout.email"
                                                                defaultMessage="Email Address"
                                                            />
                                                        </label>
                                                        <input
                                                            type="email"
                                                            className={
                                                                "form-control " +
                                                                (errors.customerEmail
                                                                    ? "is-invalid"
                                                                    : "")
                                                            }
                                                            id="customerEmail"
                                                            placeholder="Email address"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.addressChangeHandle(
                                                                    e
                                                                );
                                                            }}
                                                            value={
                                                                this.state
                                                                    .shippingAddress
                                                                    .customerEmail
                                                            }
                                                        />

                                                        <div className="text-danger">
                                                            <small>
                                                                {
                                                                    errors.customerEmail
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                    {/*
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="checkout-phone">Phone</label>
                                                    <input type="tel" 
                                                        className={"form-control " + (errors.customerPhone ? 'is-invalid' : '')}
                                                        id="customerPhone" 
                                                        placeholder="Phone"
                                                        onChange={(e) => {handleChange(e);this.addressChangeHandle(e)}} 
                                                        value={this.state.shippingAddress.customerPhone}
                                                    />
                                                    <div className="invalid-feedback">{errors.customerPhone}</div>                                            
                                                </div>
                                            */}

                                                    <div className="form-group">
                                                        <label htmlFor="checkout-phone">
                                                            <FormattedMessage
                                                                id="checkout.phone"
                                                                defaultMessage="Phone"
                                                            />
                                                        </label>
                                                        <PhoneInput
                                                            flags={flags}
                                                            defaultCountry="PK"
                                                            // onChange={(e) => {handleChange(e);this.phoneInputHandle(e)}}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    "customerPhone",
                                                                    e
                                                                );
                                                                this.phoneInputHandle(
                                                                    e
                                                                );
                                                            }}
                                                            type="tel"
                                                            placeholder="Phone"
                                                            value={
                                                                this.state
                                                                    .shippingAddress
                                                                    .customerPhone
                                                            }
                                                        />
                                                        <div className="text-danger">
                                                            <small>
                                                                {
                                                                    errors.customerPhone
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-divider" />
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="checkout-comment">
                                                            <FormattedMessage
                                                                id="checkout.orderNotes"
                                                                defaultMessage="Order Notes"
                                                            />
                                                        </label>
                                                        <textarea
                                                            id="notes"
                                                            className="form-control"
                                                            type="text"
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.addressChangeHandle(
                                                                    e
                                                                );
                                                            }}
                                                            value={
                                                                this.state
                                                                    .shippingAddress
                                                                    .notes
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                            <div className="card mb-0">
                                                <div className="card-body">
                                                    <h3 className="card-title">
                                                        <FormattedMessage
                                                            id="checkout.yourOrder"
                                                            defaultMessage="Your Order"
                                                        />
                                                    </h3>

                                                    {this.renderCart()}
                                                    {/*cartCustomDuty !== 0 && (
                                                        <div>
                                                            {" "}
                                                            Custom duty will be
                                                            approximately{" "}
                                                            <Currency
                                                                value={
                                                                    cartCustomDuty
                                                                }
                                                                currency={
                                                                    currency
                                                                }
                                                            />
                                                        </div>
                                                    )*/}

                                                    {cartCustomDuty !== 0 && (
                                                        <div>
                                                            {" "}
                                                            Custom duty will be
                                                            as per Government
                                                            regulations.
                                                            Customer will pay
                                                            the duty upon
                                                            arrival of the
                                                            product.
                                                        </div>
                                                    )}

                                                    {process.env
                                                        .REACT_APP_PER_KG_COST && (
                                                        <div>
                                                            {" "}
                                                            {"Shipping cost from USA to PK is "}
                                                            {
                                                                process.env
                                                                    .REACT_APP_PER_KG_COST
                                                            }
                                                        </div>
                                                    )}
                                                    <br />

                                                    {this.renderPaymentsList()}

                                                    <div className="checkout__agree form-group">
                                                        <div className="form-check">
                                                            <span className="form-check-input input-check">
                                                                <span className="input-check__body">
                                                                    <input
                                                                        className="input-check__input"
                                                                        type="checkbox"
                                                                        id="checkout-terms"
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .checked
                                                                        }
                                                                        defaultChecked={
                                                                            this
                                                                                .state
                                                                                .checked
                                                                        }
                                                                        onChange={
                                                                            this
                                                                                .handleCheckbox
                                                                        }
                                                                    />
                                                                    <span className="input-check__box" />
                                                                    <Check9x7Svg className="input-check__icon" />
                                                                </span>
                                                            </span>
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="checkout-terms"
                                                            >
                                                                I have read and
                                                                agree to the
                                                                website &nbsp;
                                                                <Link to="site/terms">
                                                                    terms and
                                                                    conditions
                                                                </Link>
                                                                *
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="document__content typography">
                                                        <blockquote>
                                                            <p>
                                                                <cite>
                                                                    This site is
                                                                    protected by
                                                                    reCAPTCHA
                                                                    and the
                                                                    Google
                                                                    &nbsp;
                                                                    <a href="https://policies.google.com/privacy">
                                                                        Privacy
                                                                        Policy
                                                                    </a>{" "}
                                                                    and &nbsp;
                                                                    <a href="https://policies.google.com/terms">
                                                                        Terms of
                                                                        Service
                                                                    </a>{" "}
                                                                    apply.
                                                                </cite>
                                                            </p>
                                                            <p />
                                                        </blockquote>
                                                    </div>

                                                    <GoogleReCaptcha
                                                        onVerify={
                                                            this
                                                                .verifyRecaptchaToken
                                                        }
                                                    />

                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary btn-xl btn-block"
                                                        disabled={isSubmitting}
                                                    >
                                                        {/*<FormattedMessage
                                                            id="checkout.placeOrder"
                                                            defaultMessage="Place Order"
                                                        />*/}
                                                        {
                                                            process.env
                                                                .REACT_APP_ORDER_BUTTON_TEXT
                                                        }
                                                    </button>
                                                    {process.env.REACT_APP_EASYPAY_PAYMENT.toUpperCase() ===
                                                        "TRUE" && (
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    "/payment/easypaisa",
                                                                state: {
                                                                    departmentId:
                                                                        "1",
                                                                    departmentName:
                                                                        "1",
                                                                },
                                                            }}
                                                        >
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary btn-xl btn-block"
                                                            >
                                                                Easypay
                                                            </button>
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
                {/*</GoogleReCaptchaProvider>*/}
            </React.Fragment>
        );
    }
}

ShopPageCheckout.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
    locationName: state.map.locationName,
    currency: state.currency,
    deliveryMethod: state.delivery.deliveryMethod,
    status: state.delivery.status,
    postalCode: state.delivery.postalCode,
    date: state.delivery.date,
    startTime: state.delivery.startTime,
    endTime: state.delivery.endTime,
});

const mapDispatchToProps = {
    cartReset,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ShopPageCheckout));
