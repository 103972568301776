import products from "../../data/shopProducts";
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from "./quickviewActionTypes";
import itemsAPI from "../../services/items";
import utilsAPI from "../../services/utils";

const log = require("../../components/shared/helper/MartLogger.js").logger;

export function quickviewOpenSuccess(product) {
    return {
        type: QUICKVIEW_OPEN,
        product,
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(productId) {
    // sending request to server, timeout is used as a stub
    const item = new itemsAPI();
    const utils = new utilsAPI();
    log("quickviewOpen - productId", productId);

    return (dispatch) =>
        new Promise((resolve) => {
            //item.getItemByAssociationId(productId).then(item=>{
            item.getItemByAssociationItemCustomId(productId).then((item) => {
                log("quickviewOpen", item);
                const product = utils.parseProduct(item);

                log("quickviewOpen - product", product);
                if (product) {
                    dispatch(quickviewOpenSuccess(product));
                }

                resolve();
            });
            /*
            
            setTimeout(() => {
                const product = products.find((x) => x.id === productId);
                log("fuckk you ", product)
                if (product) {
                    dispatch(quickviewOpenSuccess(product));
                }

                resolve();
            }, 2000);
                */
        });
}
