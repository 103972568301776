import React from "react";
import { toast } from "react-toastify";
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_RESET,
    CART_RECALCULATE_SHIPPING,
} from "./cartActionTypes";
import utilsAPI from "../../services/utils";
const log = require("../../components/shared/helper/MartLogger.js").logger;

export function cartAddItemSuccess(
    product,
    options = [],
    quantity = 1,
    shippingDetails
) {
    toast.success(`Product "${product.name}" added to cart!`, {
        autoClose: 1500,
    });
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        shippingDetails,
    };
}

export function cartRecalculateShippingSuccess(shippingDetails) {
    return {
        type: CART_RECALCULATE_SHIPPING,
        shippingDetails,
    };
}

export function cartRemoveItemSuccess(itemId, shippingDetails) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
        shippingDetails,
    };
}

export function cartResetSuccess() {
    return {
        type: CART_RESET,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartRecalculateShipping(cart, address) {
    // sending request to server, timeout is used as a stub
    const latitude =
        address && address.latitude
            ? address.latitude
            : process.env.REACT_APP_DEFAULT_LATITUDE;
    const longitude =
        address && address.longitude
            ? address.longitude
            : process.env.REACT_APP_DEFAULT_LONGITUDE;
    const utils = new utilsAPI();
    return (dispatch) =>
        new Promise((resolve) => {
            const distinctBranches = utils.getDistinctBranches(cart);
            log("cartRecalculateShipping", distinctBranches, cart, address);
            if (
                distinctBranches.length !== 0
                //&&
                //address !== null &&
                //address !== undefined &&
                //address.latitude !== null &&
                //address.longitude !== null
            ) {
                utils
                    .calculateDistanceBetweenUserAndBranch(
                        distinctBranches,
                        latitude,
                        longitude
                    )
                    .then((shippingDetails) => {
                        log(
                            "cartRecalculateShipping - response",
                            shippingDetails
                        );
                        dispatch(
                            cartRecalculateShippingSuccess(shippingDetails)
                        );
                        resolve();
                    })
                    .catch((error) => {
                        log("cartRecalculateShipping error", error);
                    });
            } else {
                dispatch(cartRecalculateShippingSuccess([]));
                resolve();
            }
        });
}

export function cartAddItem(
    product,
    options = [],
    quantity = 1,
    cart,
    address
) {
    // sending request to server, timeout is used as a stub
    const latitude =
        address && address.latitude
            ? address.latitude
            : process.env.REACT_APP_DEFAULT_LATITUDE;
    const longitude =
        address && address.longitude
            ? address.longitude
            : process.env.REACT_APP_DEFAULT_LONGITUDE;
    const utils = new utilsAPI();
    return (dispatch) =>
        new Promise((resolve) => {
            const distinctBranches =
                utils.getDistinctBranchesAfterAddingProduct(cart, product);
            log(
                "getDistinctBranchesAfterAddingProduct, product, cart, address",
                distinctBranches,
                product,
                cart,
                address
            );
            if (
                distinctBranches.length !== 0
                //&&
                //address !== null &&
                //address !== undefined &&
                //address.latitude !== null &&
                //address.longitude !== null
            ) {
                utils
                    .calculateDistanceBetweenUserAndBranch(
                        distinctBranches,
                        latitude,
                        longitude
                    )
                    .then((shippingDetails) => {
                        log(
                            "cartAddItem - calcShipping response",
                            shippingDetails,
                            product,
                            options,
                            quantity
                        );
                        dispatch(
                            cartAddItemSuccess(
                                product,
                                options,
                                quantity,
                                shippingDetails
                            )
                        );
                        //dispatch(cartRecalculateShippingSuccess(shippingDetails));
                        resolve();
                    })
                    .then((response) => {
                        log("response on cartAddItem", response);
                        resolve();
                    })
                    .catch((error) => {
                        log("cartAddItem error", error);
                    });
            } else {
                //this will call when we are adding merchant products such as ebay
                dispatch(
                    cartAddItemSuccess(product, options, quantity, [
                        {
                            shippingCost: 0,
                            serviceFee: 0,
                            costPerWeightUnit: 0,
                            currency: product.currency,
                            branch: null,
                        },
                    ])
                );
                resolve();
            }
        });
}

export function cartReset() {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartResetSuccess());
                resolve();
            }, 2000);
        });
}

export function cartRemoveItem(itemId, cart, product, address) {
    // sending request to server, timeout is used as a stub
    const latitude =
        address && address.latitude
            ? address.latitude
            : process.env.REACT_APP_DEFAULT_LATITUDE;
    const longitude =
        address && address.longitude
            ? address.longitude
            : process.env.REACT_APP_DEFAULT_LONGITUDE;
    const utils = new utilsAPI();
    return (dispatch) =>
        new Promise((resolve) => {
            log(
                "cartRemoveItem - itemId, cart, product, address",
                itemId,
                cart,
                product,
                address
            );
            const distinctBranches =
                utils.getDistinctBranchesAfterRemovingProduct(cart, product);
            log(
                "distinctBranches, product, cart, address",
                distinctBranches,
                product,
                cart,
                address
            );
            if (
                distinctBranches.length !== 0
                //&&
                //address.latitude !== null &&
                //address.longitude !== null
            ) {
                utils
                    .calculateDistanceBetweenUserAndBranch(
                        distinctBranches,
                        latitude,
                        longitude
                    )
                    .then((shippingDetails) => {
                        log(
                            "cartRemoveItem - calcShipping response",
                            shippingDetails
                        );
                        dispatch(
                            cartRemoveItemSuccess(itemId, shippingDetails)
                        );
                        resolve();
                    });
            } else {
                dispatch(cartRemoveItemSuccess(itemId, []));
                resolve();
            }

            /*
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 2000);
            */
        });
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 2000);
        });
}
