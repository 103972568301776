 // react
import React, {useState, useEffect} from 'react';

// third party
import { connect } from 'react-redux';
import parse from 'html-react-parser';

//Application
import paymentAPI from '../../services/payment';



function Payson(props) {
    console.log("Payson", props.location.state)
    const payment = new paymentAPI();
    const [src, setSrc] = useState("")
    const [url, setURL] = useState("")
    const {
        cart,
        address,
        locationName,
        currency,
    } = props;


    useEffect(() => {
        paysonCreateCheckout()
    }, []);

    const paysonCreateCheckout = async () =>{
        const data = await payment.paysonCreateCheckout({
            amount: 1,
            orderId: 100
        })
        console.log("paysonCreateCheckout", data)
        const snippet= parse(data.data.snippet)
        console.log("snippet, src", snippet[0], snippet[1].props.src)
        setURL(snippet[0])
        setSrc(snippet[1].props.src)
        return data
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
    },[src])

    return (
        <React.Fragment>
            <div>
                {url}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
    locationName: state.map.locationName,
    currency: state.currency,
});


export default connect(mapStateToProps, null)(Payson);
