// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

// application
import departmentsAria from "../../../services/departmentsArea";
import languages from "../../../i18n";
import StroykaSlick from "../StroykaSlick";

//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Formik, Form, Field, ErrorMessage } from "formik";
import utilsAPI from "../../../services/utils";
import {
    postalCodeVerify,
    setPostalCode,
    setDate,
    setStartTime,
    setEndTime,
} from "../../../store/delivery";
import PostalCode from "../postalcode/PostalCode";
import WidgetTags from "../../widgets/WidgetTags";

const log = require("../helper/MartLogger.js").logger;

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 5.4,
    slidesToScroll: 5,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ],
};
/*
const timeSlots = [
    {
        day: 0,
        homeDeliveryTime: [
            {
                timeStart: "10:00",
                timeEnd: "12:00",
            },
            {
                timeStart: "14:00",
                timeEnd: "16:00",
            },
            {
                timeStart: "2:00",
                timeEnd: "4:00",
            },
        ],
        pickupTime: [
            {
                timeStart: "11:00",
                timeEnd: "16:00",
            },
            {
                timeStart: "18:00",
                timeEnd: "20:00",
            },
        ],
    },
    {
        day: 6,
        homeDeliveryTime: [
            {
                timeStart: "11:00",
                timeEnd: "12:00",
            },
            {
                timeStart: "20:00",
                timeEnd: "22:00",
            },
        ],
    },
];
*/
const timeLapseHome = 24;
const timeLapseStore = 24;

class MarttimeCalendar extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia("(min-width: 992px)");

    getDayName = (dateStr, locale) => {
        var date = new Date(dateStr);
        return date.toLocaleDateString(locale, { weekday: "long" });
    };

    // array to display date on the calendar slider
    getDaysArray = (start, end) => {
        for (
            var arr = [], dt = new Date(start);
            dt <= end;
            dt.setDate(dt.getDate() + 1)
        ) {
            const ye = new Intl.DateTimeFormat("en", {
                year: "numeric",
            }).format(dt);
            const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(
                dt
            );
            const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(
                dt
            );
            arr.push({
                title: this.getDayName(dt, "en"),
                text: `${da}-${mo}-${ye}`,
            });
        }
        return arr;
    };

    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            bgColorDay: "",
            timeslots: [],
            postalCode: null,
            buttonDisabled: true,
            message: "",
        };
        this.utils = new utilsAPI();
    }

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }

        this.setState({
            slides: this.getDaysArray(
                new Date(),
                new Date().setDate(new Date().getDate() + 30)
            ),
        });
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    // set start and end time in redux on selecting the slot
    handleClickSlot = (e, startTime, endTime) => {
        const { setDate, setStartTime, setEndTime } = this.props;
        e.preventDefault();
        log("startTime, endTime", startTime, endTime);
        setStartTime(startTime);
        setEndTime(endTime);
    };

    // show slots when click on particular date on calender
    handleClick = (day, date) => {
        const { setDate } = this.props;
        log("datepicker handleClick", day, date, new Date(date).getDay());
        const timeslot = this.getTimeSlots(day, date, new Date(date).getDay());
        log("timeslot", timeslot);
        setDate(date);
        this.setState({
            timeslots: timeslot,
        });
    };

    timelapse = (currentDate, selectedDate, deliveryMethod) => {
        //create date format
        var timeStart = new Date("01/01/2007 " + "22:00").getTime();
        var timeEnd = new Date("01/01/2007 " + "21:01").getTime();

        log("start time and end time", timeStart, timeEnd);

        var hourDiff = timeStart - timeEnd;

        var resultInMinutes = Math.round(hourDiff / 60000);
        log("difference in minutes", resultInMinutes);
        // var hourDiff = "22:00" - "10:01";
        //  log("time lapse in hour", hourDiff)
        //   if(hourDiff < 12) log("time passed")
    };

    // show slots for a particular day
    getTimeSlots = (day, date, dayIndex) => {
        const { deliverySlots, deliveryMethod, cart } = this.props;
        let newArr = [];
        const today = new Date().getDay();
        log("MarttimeCalendar delivery slots", deliverySlots, date, today);
        //this.timelapse(deliverySlots, today, deliveryMethod);
        /*
        timeSlots.map((slot, index) => {
            if (slot.day === dayIndex) {
                arr =
                    deliveryMethod == "home"
                        ? slot.homeDeliveryTime.slice()
                        : slot.pickupTime.slice();
            }
        });
        */
        //log("time slots available before time lapse", arr);
        //const str = new Date().toLocaleString("en-US", {
        //    timeZone: "Asia/Karachi",
        //});
        const str = new Date();
        var newYork = moment.tz(str, "Asia/Karachi");
        log("timezone converted date", str, newYork.format());
        //new Date(`${date} ${startTime} ${cart.shipping.timezone}`)
        deliverySlots.map((slot, index) => {
            if (slot.day == dayIndex) {
                if (
                    this.timelapse(
                        newYork.format(),
                        `${date} ${slot.timeStartHours}:${slot.timeStartMinutes}`
                    )
                ) {
                    newArr.push({
                        timeStart: `${slot.timeStartHours}:${slot.timeStartMinutes}`,
                        timeEnd: `${slot.timeEndHours}:${slot.timeEndMinutes}`,
                    });
                }
            }
        });
        log("newArr slots", newArr);

        return newArr;
    };

    render() {
        const {
            locale,
            withDepartments,
            status,
            postalCode,
            deliveryMethod,
            date,
            startTime,
            endTime,
        } = this.props;
        const { direction } = languages[locale];
        const { message } = this.state;

        log(
            "delivery status --",
            status,
            postalCode,
            deliveryMethod,
            startTime,
            endTime
        );

        const blockClasses = classNames(
            "block-slideshow block",
            {
                "block-slideshow--layout--datepicker": true,
            }
            /*
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },*/
        );

        const layoutClasses = classNames("col-12", {
            "col-lg-12": !withDepartments,
            "col-lg-9": withDepartments,
        });

        log("this.timeslot", this.state.timeslots);

        const slots = this.state.timeslots.map((slot, index) => {
            log("slot", slot.timeStart);
            return (
                <Link
                    key={index}
                    style={{ fontSize: 20 }}
                    onClick={(e) => {
                        this.handleClickSlot(e, slot.timeStart, slot.timeEnd);
                    }}
                >
                    {" "}
                    {slot.timeStart} - {slot.timeEnd}
                </Link>
            );
        });

        const slides = this.state.slides.map((slide, index) => {
            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-features__item"
                        onClick={(e) => {
                            this.handleClick(slide.title, slide.text);
                        }}
                    >
                        <div
                            className="block-features__content" /*style={{backgroundColor: this.state.bgColorDay}}*/
                        >
                            <div className="block-features__title">
                                {slide.title}
                            </div>
                            <div className="block-features__subtitle">
                                {slide.text}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <PostalCode />
                <div className="container">
                    {status === "verified" && (
                        <div className="row">
                            {withDepartments && (
                                <div
                                    className="col-3 d-lg-block d-none"
                                    ref={this.setDepartmentsAreaRef}
                                />
                            )}
                            <div className={layoutClasses}>
                                <div className="block-slideshow__body">
                                    <StroykaSlick {...slickSettings}>
                                        {slides}
                                    </StroykaSlick>
                                </div>

                                <div className="widget-tags widget">
                                    <div className="tags tags--lg">
                                        <div className="tags__list">
                                            {slots}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

MarttimeCalendar.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

MarttimeCalendar.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    locale: state.locale,
    status: state.delivery.status,
    deliveryMethod: state.delivery.deliveryMethod,
    postalCode: state.delivery.postalCode,
    date: state.delivery.date,
    startTime: state.delivery.startTime,
    endTime: state.delivery.endTime,
});

const mapDispatchToProps = {
    postalCodeVerify,
    setPostalCode,
    setDate,
    setStartTime,
    setEndTime,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarttimeCalendar);
