import { CURRENCY_CHANGE, CURRENCY_INIT } from "./currencyActionTypes";

// eslint-disable-next-line import/prefer-default-export
export const currencyChange = (currency) => ({
    type: CURRENCY_CHANGE,
    currency,
});

export const currencyInitialize = (currency) => ({
    type: CURRENCY_INIT,
    currency,
});
