import React, {createRef} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { connect } from 'react-redux';
import {setAddress,setLocationName} from '../../../store/map/mapActions';
import {cartRecalculateShipping} from '../../../store/cart/cartActions';
import utilsAPI from "../../../services/utils";
const log = require('../helper/MartLogger.js').logger;


class LocationSearchInput extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = { 
      address: null,
    //  locationName: '',
    };
    log("in constructor - LocationSearchInput", this.props.locationName)
    this.utils = new utilsAPI();
    this.inputRef = createRef();
  }

 
  handleChange = (address) => {
    log("handleChange - props", address)
    this.props.setLocationName(address)
  };
 
  handleFocus = (event) => event.target.select();

  handleCurrentLocation =() =>{

    log("handleCurrentLocation")
    this.utils.getCurrentPosition().then(
      position => {
        log("position from utils", position); 
        this.utils.addressFromLatLng(position).then(
          address => {
            log("handleCurrentLocation - address",address)
            //dispatch the action
            this.props.setLocationName(address.formattedAddress)
            this.props.setAddress({
              formattedAddress : address.formattedAddress,
              latitude : address.latitude,
              longitude : address.longitude,
              source :  'searchbar',
            })
            this.recalculateShipping(address);
          },
          (error) => {
            log("error in addressFromLatLng", error)
          }
        )
      },
      (error) => {
        log("error in getCurrentPosition", error)
        switch (error.code) {
          case error.PERMISSION_DENIED:
              alert("Woaah..Please enable location permission in your browser.");
          case error.POSITION_UNAVAILABLE:
              log("Location information is unavailable.");
          case error.TIMEOUT:
              log("The request to get user location timed out.");
          case error.UNKNOWN_ERROR:
              log("An unknown error occurred.");
        }
      }
    )
  }

  //calling action to recalculate shipping based on new address
  recalculateShipping = (address) =>{
    const { cart } = this.props;
    this.props.cartRecalculateShipping(cart, address)
  }
/*
  handleOnBlur = (event) =>{
    console.log("handleOnBlur")
    event.target.blur();
  }

*/
  handleSelect = address => {
    const { cart } = this.props;
    log("handle LocationSelect", address)

    // hide the keyboard on mobile once select a location
    if (this.inputRef.current) {
      this.inputRef.current.blur();
    }

    // get lat and long from address bar and set the location on map
    geocodeByAddress(address)
      .then(results => {
        log("geocodeByAddress", results)
        //dispatch action - setAddress
        this.props.setAddress({
          formattedAddress : results[0].formatted_address,
          latitude : results[0].geometry.location.lat(),
          longitude : results[0].geometry.location.lng(),
          streetName : results[0].streetName,
          city : results[0].city,
          country : results[0].country,
          source :  'searchbar',
        })
        //dispatch action - setLocationName
        this.props.setLocationName(results[0].formatted_address)
        //console.log("handleSelect - address",results, results[0].geometry.location.lat(),results[0].geometry.location.lng())
        this.recalculateShipping({
          latitude : results[0].geometry.location.lat(),
          longitude : results[0].geometry.location.lng(),
        });
      })
      .catch(error => {
        console.error('Error', error);
      })
  };

   
 
  render() {
    const {address, locationName, errors} = this.props;
    const searchOptions = {
      componentRestrictions: { 
        country: ['pk'] 
      },
    }
    const options = {
      types: ['address']
    }
    log("this.props.address LocationSearchInput", address)

    return (
      <div>      
        <PlacesAutocomplete
          value={this.props.locationName}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          shouldFetchSuggestions={true}
          searchOptions={searchOptions}
           
          //onBlur={this.handleOnBlur}
          
          //options={options}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <div className='rowC'>
                <input  
                  style={{marginRight:0}}
                  onClick={this.handleFocus}
                  //onBlur={this.handleOnBlur}
                  ref={this.inputRef}
                //  ref={input => input && input.focus()}
                  {...getInputProps({
                    placeholder: locationName === null || locationName === "" ? "Enter street address" : locationName,
                    className: "form-control " + (errors ? 'is-invalid' : ''),
                    display: "block",
                  })}
                />
                <button 
                  type="button" 
                  className="footer-newsletter__form-button btn btn-primary" 
                  onClick={this.handleCurrentLocation}
                  style={{marginLeft:0}}>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </button>
              </div>
              <div 
                //onBlur={this.handleOnBlur}
                className="autocomplete-dropdown-container">

                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  log("suggestions", suggestion)
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer',zIndex:'2000'}
                    : { backgroundColor: '#ffffff', cursor: 'pointer',zIndex:'2000'};
                  return (
                    <div 

                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

        </PlacesAutocomplete>
      
      </div>
    );
  }
}

const mapStateToProps = (state)=>{
  log("calling mapStateToProps, LocationSearchInput.jsx", state.map.address)
  return {
          address : state.map.address,
          locationName: state.map.locationName, 
          cart: state.cart,   
  }
}

const mapDispatchToProps= (dispatch)=>{
  log("dispatch calling of LocationSearchInput action", dispatch)
  return{
      setAddress: (address)=>{dispatch(setAddress(address))},
      setLocationName: (locationName)=>{dispatch(setLocationName(locationName))},
      cartRecalculateShipping: (cart,address)=>{dispatch(cartRecalculateShipping(cart,address))}
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (LocationSearchInput)