const log = require("../components/shared/helper/MartLogger.js").logger;

class Notifications {
    //send email
    sendEmail = (data) => {
        log("sendEmail data", data);

        /*const data = {
            'orderId': order.orderId,
            'customerFirstName': order.shippingAddress[0].customerFirstName,
            'customerLastName' : order.shippingAddress[0].customerLastName,
            'customerPhone' : order.shippingAddress[0].customerPhone,
            'customerHouseNumber' : order.shippingAddress[0].customerHouseNumber,
            'customerStreet' : order.shippingAddress[0].customerStreet,
            'items' : order.pickedItems,
            'customerEmail' : order.shippingAddress[0].customerEmail,
            'customerLocation' : order.shippingAddress[0].customerLocation,
            'orderCost' : order.cost,
            'customerNotes' :  order.customerNotes,
            'templateId' : templateId,
            'tenantEmail': process.env.REACT_APP_TENANT_EMAIL,
            'tenantPhone': process.env.REACT_APP_TENANT_PHONE,
            'tenantinstagram': process.env.REACT_APP_TENANT_INSTAGRAM_PROFILE_URL,
            'tenantfacebook': process.env.REACT_APP_TENANT_FACEBOOK_PROFILE_URL,
            'tenantBusinessName': process.env.REACT_APP_TENANT_BUSINESS_NAME,
            'tenantBusinessLogo': process.env.REACT_APP_WEBSITE_LOGO,
            'tenantBusinessBanner': process.env.REACT_APP_WEBSITE_BANNER,
            'tenantBusinessUrl': process.env.REACT_APP_TENANT_BUSINESS_SITE_URL,
            'currency': order.currency,
        }*/

        log("sendEmail data", data);
        return fetch(
            process.env.REACT_APP_API + "/v1/notifications/email/sendgrid",
            {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },

                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((response) => {
                log("email send", response);
                return response;
            })
            .catch((error) => {
                log("error in send email", error);
                return error;
            });
    };

    //send sms
    sendSMS = (order, smsType) => {
        const data = {
            orderId: order.orderId,
            customerFirstName: order.shippingAddress[0].customerFirstName,
            customerLastName: order.shippingAddress[0].customerLastName,
            customerPhone: order.shippingAddress[0].customerPhone,
            customerHouseNumber: order.shippingAddress[0].customerHouseNumber,
            customerStreet: order.shippingAddress[0].customerStreet,
            items: order.pickedItems,
            customerEmail: order.shippingAddress[0].customerEmail,
            customerLocation: order.shippingAddress[0].customerLocation,
            orderCost: order.cost,
            customerNotes: order.customerNotes,
            smsType: smsType,
        };

        log("send sms data", data);
        return fetch(process.env.REACT_APP_API + "/v1/notifications/sms/send", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },

            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((response) => {
                log("sms sent", response);
                return response;
            })
            .catch((error) => {
                log("error in send sms", error);
                return error;
            });
    };

    //send push
    sendPush = (data) => {
        log("send push data", data);
        return fetch(
            process.env.REACT_APP_API +
                "/v1/notifications/push/sendNotification",
            {
                method: "POST",

                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },

                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((response) => {
                log("push sent", response);
                return response;
            })
            .catch((error) => {
                log("error in push notification", error);
                return error;
            });
    };
}

export default Notifications;
