// react
import React, { useState, useEffect } from "react";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import { LogoSvg } from "../../svg";
import logo from "../../svg/logo.png";
import googleAnalyticsAPI from "../../services/googleAnalytics";
import ReactGA from "react-ga";

const log = require("../shared/helper/MartLogger.js").logger;

function Header(props) {
    const { layout } = props;
    const googleAnalytics = new googleAnalyticsAPI();
    let bannerSection;

    log("window.pathname", window.location.pathname);
    //ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG);
    //ReactGA.ga("send", "pageview", window.location.pathname);

    //send pageview to google analytics
    /*
    googleAnalytics.pageview({
        hostname: window.location.hostname,
        title: window.location.pathname,
        path: window.location.pathname,
    });
    */

    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/">
                        {/*<LogoSvg />*/}
                        <img
                            src={process.env.REACT_APP_WEBSITE_LOGO}
                            width="100%"
                            height="100%"
                            alt="logo"
                        />
                    </Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                        <FormattedMessage
                            id="header.phoneLabel"
                            defaultMessage="Customer Service"
                        />
                    </div>
                    <div className="site-header__phone-number">
                        <FormattedMessage
                            id="header.phone"
                            defaultMessage={process.env.REACT_APP_TENANT_PHONE}
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <Topbar />
            {bannerSection}

            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

export default Header;
