import axios from "axios";
import googleAnalyticsAPI from "./googleAnalytics";
import ReactGA from "react-ga";
import utilsAPI from "./utils";
const CancelToken = axios.CancelToken;
let cancel;
const googleAnalytics = new googleAnalyticsAPI();
const utils = new utilsAPI();
const log = require("../components/shared/helper/MartLogger.js").logger;

export default class Items {
    //send search data to google analytics
    sendToGoogleAnalytics = (query) => {
        const user = utils.getUserFromLocalStorage();
        const phone = user !== null ? user.phone : "";
        const name = user !== null ? user.fName : "";
        /*
        googleAnalytics.event({
            eventCategory: "search",
            eventAction: "query",
            eventLabel: query + " " + phone + " " + name,
            eventValue: 1,
            path: window.location.pathname,
        });*/
        ReactGA.event({
            category: "search",
            action: "query",
            label: query + " " + phone + " " + name,
            value: 1,
        });
    };

    getAllItems = (categoryId) => {
        log("items.js getAllItems");
        return fetch(process.env.REACT_APP_API + "/v1/item/allitems/fetch", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
        })
            .then((response) => response.json())
            .then((items) => {
                log("getAllItems result", items);
                return items;
            })

            .catch((error) => {
                log(error);
            });
    };

    searchItems = (query, lat, lng, pageNo = 1, perPage = 10) => {
        log("searchItems");

        const url =
            process.env.REACT_APP_API +
            "/v1/item/itembynearestbranch/" +
            `${query}` +
            "/" +
            lat +
            "/" +
            lng +
            "?page=" +
            pageNo +
            "&perPage=" +
            perPage;
        cancel && cancel();

        return axios
            .get(url, {
                headers: { domain: process.env.REACT_APP_DOMAIN },
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                }),
            })
            .then((items) => {
                log("searchItems -- ", items);
                this.sendToGoogleAnalytics(query);
                return items.data;
            })
            .catch((error) => {
                log("error in searchitem - items.js", error);
            });
    };

    /*
    searchItems = (query) =>{   
        log("searchItems")

        const url = process.env.REACT_APP_API + '/v1/item/quickitemsearch/' + `${query}`;
        cancel && cancel();

        return axios
            .get(url, {
                headers: { domain : process.env.REACT_APP_DOMAIN },
                cancelToken: new CancelToken(function executor(c){
                    cancel = c;
                })
            })  
            .then(items => {
                log("searchItems -- ", items)
                this.sendToGoogleAnalytics(query)
                return items.data;
            })
            .catch(error => {
                log("error in searchitem", error)
                
            })  
    }*/
    /*
    searchItems = (query) =>{
        log("items.js getAllItems")
        return fetch(process.env.REACT_APP_API + '/v1/item/searchitemregex/' + `${query}`, {
        
            method : "GET", 
                headers: {
                    "Content-Type": "application/json",
                    "domain" : process.env.REACT_APP_DOMAIN,
                },
        })
        
        .then(response => response.json())
        .then(items => {
            log("searchItems", items)
            return items;
        })

        .catch(error => {
            log(error);
        })  
    }
*/

    getItemsByCategoryId = (categoryId) => {
        log("getItemsByCategoryId", categoryId);
        return fetch(
            process.env.REACT_APP_API +
                "/v1/item/categorywithchildern/" +
                categoryId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("getItemsByCategoryId error", error);
            });
    };

    //return items by category id
    getNearestItemsByCategory = (
        categoryId,
        lat = 31.5540095,
        lng = 74.302654,
        pageNo = 1,
        perPage = 20
    ) => {
        log("getNearestItemsByCategory - categoryId", categoryId);
        return fetch(
            process.env.REACT_APP_API +
                "/v1/item/categorywithchildernnearbranch/" +
                categoryId +
                "/" +
                lat +
                "/" +
                lng +
                "?page=" +
                pageNo +
                "&perPage=" +
                perPage,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((items) => {
                return items;
            })

            .catch((error) => {
                log("items.js", error);
                return error;
            });
    };
    //return item by association id
    getItemByAssociationId = (associationId) => {
        log("getItemByAssociationId");
        return fetch(
            process.env.REACT_APP_API +
                "/v1/item/getnearestitem/" +
                associationId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((items) => {
                //callback(items);
                return items[0];
            })

            .catch((error) => {
                log("catch getItemByAssociationId", error);
            });
    };

    //return item by association id
    getItemByAssociationItemCustomId = (associationItemCustomId) => {
        log("getItemByAssociationItemCustomId");
        return fetch(
            process.env.REACT_APP_API +
                "/v1/item/getNearestItemByItemCustomId/" +
                associationItemCustomId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((items) => {
                //callback(items);
                log("getItemByAssociationItemCustomId", items);
                return items;
            })

            .catch((error) => {
                log("catch getItemByAssociationItemCustomId", error);
            });
    };

    getItemByItemId = (itemId) => {
        log("getItemByItemId");
        return fetch(process.env.REACT_APP_API + "/v1/item/getItem/" + itemId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
        })
            .then((response) => response.json())
            .then((items) => {
                //callback(items);
                return items[0];
            })

            .catch((error) => {
                log("catch getItemByItemId", error);
            });
    };
}
