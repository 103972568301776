// react
import React from "react";

// third-party
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import Megamenu from "./Megamenu";
import Menu from "./Menu";
import { ArrowRoundedRight6x9Svg } from "../../svg";

// data stubs
import departments from "../../data/headerDepartments";
const log = require("../shared/helper/MartLogger.js").logger;

function DepartmentsLinks(props) {
    log("DepartmentsLinks - departments", props.departments);
    let linksList = props.departments.map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = "";

        log("link department", department, index);

        if (department.submenu) {
            //    log("in department.submenu", department.submenu)
            arrow = (
                <ArrowRoundedRight6x9Svg className="departments__link-arrow" />
            );
        }
        //if the department is not active, then it's submenus will not be shown
        if (
            department.submenu &&
            department.submenu.type === "menu" &&
            department.isActive == true
        ) {
            //    log("in department.menu", department)
            if (department.submenu.menu.length > 0) {
                itemClass = "departments__item--menu";
                submenu = (
                    <div className="departments__menu">
                        <Menu items={department.submenu.menu} />
                    </div>
                );
            }
        }

        if (department.submenu && department.submenu.type === "megamenu") {
            //    log("in department.megamenu")
            submenu = (
                <div
                    className={`departments__megamenu departments__megamenu--${department.submenu.menu.size}`}
                >
                    <Megamenu
                        menu={department.submenu.menu}
                        location="department"
                    />
                </div>
            );
        }

        return (
            <React.Fragment>
                <li key={index} className={`departments__item ${itemClass}`}>
                    {department.isActive === true && (
                        <Link
                            //to={department.menuUrl}
                            to={{
                                pathname: department.menuUrl,
                                state: {
                                    departmentId: department._id,
                                    departmentName: department.name,
                                },
                            }}
                        >
                            {department.title}
                            {arrow}
                        </Link>
                    )}
                    {submenu}
                </li>
            </React.Fragment>
        );
    });

    if (linksList.length > 10) {
        linksList = linksList.slice(0, 9);
        linksList.push(
            <li key={5000} className={`departments__item ${""}`}>
                <Link
                    //to={department.menuUrl}
                    to={{
                        pathname: "/",
                        state: {
                            departmentId: "",
                            departmentName: "",
                        },
                    }}
                >
                    <FormattedMessage
                        id="menu.showMore"
                        defaultMessage="Show more"
                    />
                    {null}
                </Link>
            </li>
        );
    }

    return <ul className="departments__links">{linksList}</ul>;
}

export default DepartmentsLinks;
