import React, {useState, useEffect} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';


const MarttimeDayPicker = (props) => {

  const [startDate, setStartDate] = useState(new Date());

  let handleColor = time => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  }

  const isWeekday = date => {
    const day = date.getDay(date);
    return day == 2 || day == 5;
  };

  return (
    <DatePicker
     
      selected={startDate}
      onChange={date => setStartDate(date)}
      timeClassName={handleColor}
      filterDate={isWeekday}
      startOpen={true}
      filterTime={filterPassedTime}
    />
  );

};


const mapStateToProps = (state) => ({
    address: state.map.address,
});


export default connect(mapStateToProps, null)(MarttimeDayPicker);