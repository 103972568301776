import { LOGIN, LOGOUT } from './authActionTypes';
const log = require('../../components/shared/helper/MartLogger.js').logger;


const initState = {
    token: JSON.parse(localStorage.getItem('fkToken')) || null, 
    user: JSON.parse(localStorage.getItem('fkUser')) || null,
}

const authReducer= (state = initState, action)=>{
    
    log("authReducer - initial state:", state)    
    log("authReducer - action:", action.type)

    if(action.type === LOGIN){  
        state.token = action.token;
        state.user = action.user;

        log("state.token, state.user", state.user)
        localStorage.setItem('fkToken', JSON.stringify(state.token));
        localStorage.setItem('fkUser', JSON.stringify(state.user));

        log("authReducer - localStorage",JSON.parse(localStorage.getItem('fkUser')))

        log("action type, updated state: ", state)
        log("JSON.stringify updated state: ", JSON.stringify(state.token))
        return{
                ...state, 
        }
    }else if(action.type === LOGOUT){
        log("authReducer - state:", state) 
        state.token = null;
        state.user = null;
        localStorage.removeItem('fkToken')
        localStorage.removeItem('fkUser')
        return{
                ...state, 
        }
    }
    else{
     return state;   
    }
}
export default authReducer;