const log = require("../components/shared/helper/MartLogger.js").logger;

export default class Configurations {
    getCurrencies = async () => {
        return fetch(
            `${process.env.REACT_APP_API}/v1/configurations/currency`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((list) => {
                log("currency list", list);
                let c = list.map((currency) => {
                    log("currency data", currency);
                    return {
                        title: currency.title,
                        currency: {
                            code: currency.code,
                            symbol: currency.symbol,
                            name: currency.name,
                            convertTo: currency.convertTo,
                        },
                    };
                });

                return c;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };
}
