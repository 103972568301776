// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';

// application
//import products from '../../data/shopProducts';

// data stubs
import BlockProductsCarousel from './BlockProductsCarousel';

//services
import categoriesAPI from '../../services/categories';
import itemsAPI from '../../services/items';
import utilsAPI from '../../services/utils';
const log = require('../shared/helper/MartLogger.js').logger;


export default class BlockTabbedProductsCarousel extends Component {
    timeout;

    constructor(props) {
        super(props);
        this.categories = new categoriesAPI();
        this.items = new itemsAPI();
        this.utils = new utilsAPI();
        this.state = {
            products: [],
            menuUrl: null,
            categoryName: null,
            categoryId: null,
            loading: false,
            groups: [/*
                { id: 1, name: 'All', current: true },
                { id: 2, name: 'Power Tools', current: false },
                { id: 3, name: 'Hand Tools', current: false },
                { id: 4, name: 'Plumbing', current: false },*/
            ],
        };
    }

    componentDidMount(){
        //get categories to show in featured products
        if(this.props.category == "featured"){
            this.parameters = {'isFeatured': true,}
        }else{
            this.parameters = {'isPopular': true,}
        }

        //this.categories.getCategories(this.categoriesAPIResponse, process.env.REACT_APP_MENU_ID)
        this.categories.getCategoriesByParameters(this.parameters)
        .then(categories =>{
            log("getCategoriesByParameters", categories)
            this.categoriesAPIResponse(categories)
        })
        .catch(error =>{
            log("error in getCategoriesByParameters", error)
        })
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    //callback of categories API
    categoriesAPIResponse = (response) =>{  

      //transform response to show the groups
      let data = response.map((item,index) => (
          {
            name: item.hasOwnProperty('name') ? item.name : '',
            id: item.hasOwnProperty('_id') ? item._id : '',
            current: false,
            menuUrl: item.hasOwnProperty('menuUrl') ? item.menuUrl : '',
            //current: (index ==0 ? true : false),
          }
        ))
        
        const index = Math.floor(Math.random()*data.length)     
        log("random index", index)
        data[index].current = true;
        

      log("BlockTabbed - categoriesData", data)
      this.setState({
        groups: data,
      }, function(){
        log("groups assigned", this.state.groups);
        this.handleChangeGroup(this.state.groups[index])
      }) 
    }

    getProducts =(categoryId)=>{

        //this.items.getItemsByCategoryId(categoryId).then(response=>{
            //getItemsByCategoryId
        log("BlockTabbedProductsCarousel category id", categoryId )
        this.items.getNearestItemsByCategory(categoryId).then(response=>{
            log("getProducts-itemsAPIResponse", response)
            //transform response to show the data in carasoul
            const data = this.utils.parseProductArray(response);

            log("getProducts - parsedData", data)

            this.setState({
                products: data,
                categoryId: categoryId,
                loading: false,
            }, function(){
            log("products loaded", this.state.products)
            }) 
        })
    }



    //  handle the active tab and fetch the products of that selected category
    handleChangeGroup = (newCurrentGroup) => {
        clearTimeout(this.timeout);
        const { groups } = this.state;
        const currentGroup = groups.find((group) => group.current);
        log("currentGroup, newCurrentGroup", newCurrentGroup)
        this.setState((state) => (
            {   menuUrl: newCurrentGroup.menuUrl,
                loading: true,
                categoryName: newCurrentGroup.name,
                groups: state.groups.map((group) => (
                    { ...group, current: group.id === newCurrentGroup.id }
                )),
            }
        ),()=>{
            log("handleChangeGroup - menuUrl", this.state.menuUrl)
            // set the selected category id
        });

        log("handleChangeGroup - groups",groups)
        log("newCurrentGroup.id",newCurrentGroup.id)
        this.getProducts(newCurrentGroup.id); //get the products by category
    };
//ddd
    render() {
        return (
            <BlockProductsCarousel
                {...this.props}
                {...this.state}
                menuUrl = {this.state.menuUrl}
                onGroupClick={this.handleChangeGroup}
                categoryName={this.state.categoryName}
            />
        );
    }
}

BlockTabbedProductsCarousel.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(['grid-4', 'grid-4-sm', 'grid-5', 'horizontal']),
    rows: PropTypes.number,
    withSidebar: PropTypes.bool,
};

BlockTabbedProductsCarousel.defaultProps = {
    layout: 'grid-4',
    rows: 1,
    withSidebar: false,
};
