// react
import React, { useState } from 'react';

// third-party
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// application

import { locationviewClose } from '../../../store/locationview/locationviewActions';
import LocationSearchInput from './LocationSearchInput';
import WrappedMap from "./Map";


function LocationView(props) {

//    console.log("locationview - props", props)
    const { open, locationviewClose } = props;

 //   const [modal, setModal] = useState(true);

 //   const toggle = () => setModal(!modal);

    return (
        <Modal isOpen={open} toggle={locationviewClose} centered size="lg">
            <ModalHeader toggle={locationviewClose}>Your Delivery Address</ModalHeader>
            <ModalBody>
                <LocationSearchInput />
                <div style={{height:"8px"}}/>
                <WrappedMap
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}    
                />
            </ModalBody>
        </Modal>
    );
}


/*
//get updated props
const mapStateToProps = state => {
    console.log("calling mapStateToProps, locationview.jsx", state);
    return {
        open: state.locationview.open,
    };
};
*/
const mapStateToProps = (state) => ({
    open: state.locationview.open,
});


const mapDispatchToProps = {
    locationviewClose,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LocationView);