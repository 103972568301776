// react
import React, { useState, useEffect, lazy, Suspense } from "react";

// third-party
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";

// blocks
//import ShopPageCategory from "../shop/ShopPageCategory";

import BlockBanner from "../blocks/BlockBanner";
//import BlockBrands from '../blocks/BlockBrands';
//import BlockCategories from '../blocks/BlockCategories';
//import BlockFeatures from "../blocks/BlockFeatures";
//import BlockPosts from '../blocks/BlockPosts';
//import BlockProductColumns from '../blocks/BlockProductColumns';
//import BlockProducts from '../blocks/BlockProducts';
//import BlockSlideShow from '../blocks/BlockSlideShow';

//import BlockTabbedProductsCarousel from '../blocks/BlockTabbedProductsCarousel';
//import MarttimeSwiper from '../shared/swiper/MarttimeSwiper'
//import MarttimeSwiperInfinite from '../shared/swiper/MarttimeSwiperInfinite'

// data stubs
//import categories from '../../data/shopBlockCategories';
//import posts from '../../data/blogPosts';
//import products from '../../data/shopProducts';
import categories from "../../data/ebayCategories";
import theme from "../../data/theme";
//import itemsAPI from '../../services/items';
import categoriesAPI from "../../services/categories";
import utilsAPI from "../../services/utils";
//import googleAnalyticsAPI from '../../services/googleAnalytics';
import ReactGA from "react-ga";
import { getUA } from "react-device-detect";
const ShopPageEbaySearch = lazy(() =>
    import("../shop/ebay/ShopPageEbaySearch")
);
const ShopPageCategory = lazy(() => import("../shop/ShopPageCategory"));
const BlockFeatures = lazy(() => import("../blocks/BlockFeatures"));
const BlockSlideShow = lazy(() => import("../blocks/BlockSlideShow"));
const MarttimeSwiperInfinite = lazy(() =>
    import("../shared/swiper/MarttimeSwiperInfinite")
);
const log = require("../shared/helper/MartLogger.js").logger;

const renderLoader = () => <p>Loading</p>;

function HomePageOne() {
    const utils = new utilsAPI();
    const [swiper, setSwiper] = useState(null);
    const [randomCategory, setRandomCategory] = useState(null);
    const isBot = getUA.toLowerCase().includes("bot");
    const parameters = {
        isFavorite: true,
    };

    const sendLoadingTime = () => {
        // Feature detects Navigation Timing API support.
        if (window.performance) {
            var timeSincePageLoad = Math.round(performance.now());
            // Sends the timing hit to Google Analytics.
            ReactGA.ga(
                "send",
                "timing",
                "page load",
                "load",
                timeSincePageLoad,
                "Homepage"
            );
        }
    };

    const setEbayCategories = () => {
        const shuffle = utils.shuffleArray(categories);
        log("shuffle Array", shuffle);
        setRandomCategory(shuffle[0]._id);

        const swiper = categories.map((category, index) => (
            <MarttimeSwiperInfinite
                key={category._id}
                category={category._id}
                layout="grid-4"
                title={category.name}
                rows={1}
                categoryUrl={category.menuUrl}
                slidesPerView={5.4}
                marketplace="ebay"
            />
        ));
        setSwiper(swiper);
        sendLoadingTime();
    };
    const setCategories = () => {
        const cat = new categoriesAPI();
        cat.getCategoriesByParameters({ isFeatured: true })
            .then((categories) => {
                log("my categories", categories);
                const swiper = categories.map((category, index) => (
                    <MarttimeSwiperInfinite
                        key={category._id}
                        category={category._id}
                        layout="grid-4"
                        title={category.name}
                        rows={1}
                        categoryUrl={category.menuUrl}
                        slidesPerView={5.4}
                    />
                ));
                setSwiper(swiper);
                sendLoadingTime();
            })
            .catch((error) => {
                log("error in getCategoriesByParameters", error);
            });
    };
    useEffect(() => {
        //setCategories();
        setEbayCategories();
    }, []);

    /*
    useEffect(() => {
        //getting featured products
        cat.getTree(process.env.REACT_APP_MENU_ID).then(response=>{
            log("popular categories response", response.tree)
           // setCategories(response.tree)
        })
        .catch(error =>{
            log("error in getTree - categories", error)
        })

    },[]);
    */

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.longDescription} />
                <meta property="og:title" content={theme.name} />
                <meta
                    property="og:description"
                    content={theme.longDescription}
                />
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_WEBSITE_URL}`}
                />
                <meta
                    property="og:image"
                    content={`${process.env.REACT_APP_WEBSITE_LOGO}`}
                />
                <meta
                    property="fb:app_id"
                    content={`${process.env.REACT_APP_FACEBOOK_APPID}`}
                />
                <meta property="og:type" content="website" />
                <meta name="twitter:card" content="summary"></meta>
            </Helmet>
            <Suspense fallback={renderLoader()}>
                <BlockSlideShow withDepartments parameters={parameters} />
                {/*<BlockFeatures />*/}
                <BlockFeatures />
                
                {/*swiper*/}
                
                {/*<BlockBanner />*/}
                {/*
                <ShopPageCategory
                    columns={4}
                    viewMode="grid"
                    sidebarPosition="start"
                    source="shop"
                    isShopPageCategoryEmbedded={true}
                    title="New Arrivals"
                />
                */}
                {!isBot && (
                    <ShopPageEbaySearch
                        columns={4}
                        viewMode="grid"
                        sidebarPosition="start"
                        source="category"
                        title="New Arrivals from eBay "
                        relatedItems={randomCategory}
                        isShopPageCategoryEmbedded={true}
                    />
                )}
            </Suspense>
            {/*
                <BlockTabbedProductsCarousel 
                title="Featured Products" 
                layout="grid-4" 
                category="featured"/>

            */}

            {/*
               <BlockProducts
                title="Bestsellers"
                layout="large-first"
                featuredProduct={bestSellers[0]}
                products={bestSellers.slice(1, 7)}
                /> 
            */}
            {/*<BlockCategories title="Popular Categories" layout="classic" categories={categories} />*/}
            {/*
                <BlockTabbedProductsCarousel 
                title="Popular" 
                layout="horizontal" 
                rows={2} 
                category="popular" />   
            */}
            {/*<BlockPosts title="Latest News" layout="list-sm" posts={posts} />*/}
            {/*<BlockBrands />*/}
            {/*
                <BlockProductColumns 
                columns={columns1} />
 
            */}
        </React.Fragment>
    );
}

export default HomePageOne;
