// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

// third party
import { FormattedMessage } from 'react-intl';


export default function FooterContacts() {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">
                <FormattedMessage id="topbar.contacts" />
            </h5>
            {/*
            <div className="footer-contacts__text">
                We are here for you.
            </div>
            */}
            <ul className="footer-contacts__contacts">
            {/*
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {theme.contacts.address}
                </li>
            */}
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    <a href={`mailto:${theme.contacts.email}`} >
                        {theme.contacts.email}
                    </a>
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    <a href={`tel:${theme.contacts.phone}`}>
                        {`${theme.contacts.phone}`}
                    </a>
                </li>
                {/*
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    Mon-Sat 10:00am - 7:00pm
                </li>
                */}
            </ul>
        </div>
    );
}
