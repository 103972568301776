// react
import React, { lazy, Suspense } from "react";

// third-party
import PropTypes from "prop-types";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";

// pages
import AccountLayout from "./account/AccountLayout";
import AccountPageLogin from "./account/AccountPageLogin";
import BlogPageCategory from "./blog/BlogPageCategory";
import SitePageAboutUs from "./site/SitePageAboutUs";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageCompare from "./shop/ShopPageCompare";
import SitePageComponents from "./site/SitePageComponents";
import SitePageContactUs from "./site/SitePageContactUs";
import SitePageContactUsAlt from "./site/SitePageContactUsAlt";
import SitePageFaq from "./site/SitePageFaq";
import SitePageNotFound from "./site/SitePageNotFound";
import BlogPagePost from "./blog/BlogPagePost";
//import ShopPageProduct from "./shop/ShopPageProduct";
import SitePageTerms from "./site/SitePageTerms";
import ShopPageTrackOrder from "./shop/ShopPageTrackOrder";
import SitePageTypography from "./site/SitePageTypography";
import PageWishlist from "./shop/ShopPageWishlist";
//import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageSearch from "./shop/ShopPageSearch";
import OrderConfirmation from "./shop/OrderConfirmation";
import SitePagePrivacyPolicy from "./site/SitePagePrivacyPolicy";
import ShopPageBrand from "./shop/ShopPageBrand";
import EasyPaisa from "./payment/Easypaisa";
import MarttimeDaySlider from "./shared/datepicker/MarttimeDaySlider";
import MarttimeDayPicker from "./shared/datepicker/MarttimeDayPicker";
import MarttimeCalendar from "./shared/datepicker/MarttimeCalendar";
import PostalCode from "./shared/postalcode/PostalCode";
import Payson from "./payment/Payson";
import SwedBankPay from "./payment/SwedBankPay";
import SitePageReturns from "./site/SitePageReturns";

// data stubs
import theme from "../data/theme";
//fb  chat
import MessengerCustomerChat from "react-messenger-customer-chat";
// whatsapp chat
import WhatsAppButton from "./shared/WhatsAppButton";
// detect device
import { BrowserView, MobileView } from "react-device-detect";
import useGaTracker from "../services/useGaTracker";
import { ebayUsaUrl, cjUrl } from "../url";

const log = require("./shared/helper/MartLogger.js").logger;

//pages
const ShopPageProduct = lazy(() => import("./shop/ShopPageProduct"));
const ShopPageEbayProduct = lazy(() =>
    import("./shop/ebay/ShopPageEbayProduct")
);
const ShopPageCategory = lazy(() => import("./shop/ShopPageCategory"));

const SearchEbay = lazy(() => import("./merchants/ebay/SearchEbay"));
const ShopPageEbaySearch = lazy(() => import("./shop/ebay/ShopPageEbaySearch"));

//commision junction
const ShopPageCJSearch = lazy(() => import("./shop/cj/ShopPageCJSearch"));
const ShopPageCJProduct = lazy(() => import("./shop/cj/ShopPageCJProduct"));
const SearchCJ = lazy(() => import("./merchants/cj/SearchCJ"));


function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    log("process.env in layout", process.env.REACT_APP_SHOP_PATH);
    log("Layout props", props);
    const renderLoader = () => <p>Loading</p>;
    useGaTracker();
    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <Quickview />

            <MobileMenu />

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader {...props} />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Suspense fallback={renderLoader()}>
                        <Switch>
                            {/*
                        // Home
                        */}
                        {/* commenting out the home page route and enable search page
                            <Route
                                exact
                                path={`${match.path}`}
                                component={homeComponent}
                            />
                        */}
                            <Route
                                exact
                                path={`${match.path}`}
                                render={(props) => <SearchCJ {...props} />}
                            />

                            {/*
                        // Shop
                        */}
                            {/*
                        <Redirect exact from="/shop" to="/shop/category-grid-3-columns-sidebar" />
                        */}
                            {/*
                            <Route
                            path="/location"
                            render={(props) => (
                                <LocationChooser {...props} />
                            )}
                            />

                       */}

                            <Route
                                exact
                                path={`${ebayUsaUrl}/product/:productId`}
                                component={ShopPageEbayProduct}
                            />

                            <Route
                                path={`${ebayUsaUrl}/search/:id`}
                                render={(props) => (
                                    <ShopPageEbaySearch
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="search"
                                        startPage={1}
                                    />
                                )}
                            />

                            <Route
                                path={`${ebayUsaUrl}/brand/:id`}
                                render={(props) => (
                                    <ShopPageEbaySearch
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="brand"
                                        startPage={1}
                                    />
                                )}
                            />

                            <Route
                                path={`${ebayUsaUrl}/category/:id`}
                                render={(props) => (
                                    <ShopPageEbaySearch
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="category"
                                        startPage={1}
                                    />
                                )}
                            />

                            <Route
                                path={`${ebayUsaUrl}/:id`}
                                render={(props) => (
                                    <ShopPageEbaySearch
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="category"
                                        startPage={1}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={ebayUsaUrl}
                                render={(props) => <SearchEbay {...props} />}
                            />

                            <Route
                                path={`${cjUrl}/search/:id`}
                                render={(props) => (
                                    <ShopPageCJSearch
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="search"
                                        startPage={1}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={`${cjUrl}/product/:productId`}
                                component={ShopPageCJProduct}
                            />

                            <Route
                                path="/pickaday"
                                render={(props) => (
                                    <MarttimeDaySlider {...props} />
                                )}
                            />

                            <Route
                                path="/pick"
                                render={(props) => (
                                    <MarttimeDayPicker {...props} />
                                )}
                            />

                            <Route
                                path="/changer"
                                render={(props) => (
                                    <MarttimeCalendar {...props} />
                                )}
                            />

                            <Route
                                path="/postalcode"
                                render={(props) => <PostalCode {...props} />}
                            />

                            <Route
                                path="/confirmation"
                                render={(props) => (
                                    <OrderConfirmation {...props} />
                                )}
                            />

                            <Route
                                path="/payment/payson"
                                render={(props) => <Payson {...props} />}
                            />
                            <Route
                                path="/payment/swedbankpay"
                                render={(props) => <SwedBankPay {...props} />}
                            />

                            <Route
                                path="/payment/easypaisa"
                                render={(props) => <EasyPaisa {...props} />}
                            />

                            <Redirect
                                exact
                                from={`/${process.env.REACT_APP_DEPARTNAME_ALIAS}`}
                                to="/"
                            />
                            <Route
                                path={`/${process.env.REACT_APP_DEPARTNAME_ALIAS}/:id`}
                                //path="/department/:id"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="shop"
                                    />
                                )}
                            />

                            <Route
                                path="/search/:id"
                                render={(props) => (
                                    <ShopPageSearch
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="search"
                                        startPage={1}
                                    />
                                )}
                            />

                            <Route
                                path={`/${process.env.REACT_APP_BRANDS_ALIAS}/:id`}
                                //path="/brands/:id"
                                render={(props) => (
                                    <ShopPageBrand
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                        source="brands"
                                        startPage={1}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path="/shop/category-grid-3-columns-sidebar"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={3}
                                        viewMode="grid"
                                        sidebarPosition="start"
                                    />
                                )}
                            />

                            <Route
                                exact
                                path="/shop/category-grid-4-columns-full"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={4}
                                        viewMode="grid"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/shop/category-grid-5-columns-full"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={5}
                                        viewMode="grid"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/shop/category-list"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={3}
                                        viewMode="list"
                                        sidebarPosition="start"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/shop/category-right-sidebar"
                                render={(props) => (
                                    <ShopPageCategory
                                        {...props}
                                        columns={3}
                                        viewMode="grid"
                                        sidebarPosition="end"
                                    />
                                )}
                            />

                            <Route
                                exact
                                path="/shop/product/:productId"
                                component={ShopPageProduct}
                            />

                            <Route
                                exact
                                path="/shop/cart"
                                component={PageCart}
                            />
                            <Route
                                exact
                                path="/shop/checkout"
                                component={PageCheckout}
                            />
                            <Route
                                exact
                                path="/shop/wishlist"
                                component={PageWishlist}
                            />
                            <Route
                                exact
                                path="/shop/compare"
                                component={PageCompare}
                            />
                            <Route
                                exact
                                path="/shop/track-order"
                                component={ShopPageTrackOrder}
                            />

                            {/*
                        // Blog
                        */}

                            <Redirect
                                exact
                                from="/blog"
                                to="/blog/category-classic"
                            />
                            <Route
                                exact
                                path="/blog/category-classic"
                                render={(props) => (
                                    <BlogPageCategory
                                        {...props}
                                        layout="classic"
                                        sidebarPosition="end"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/blog/category-grid"
                                render={(props) => (
                                    <BlogPageCategory
                                        {...props}
                                        layout="grid"
                                        sidebarPosition="end"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/blog/category-list"
                                render={(props) => (
                                    <BlogPageCategory
                                        {...props}
                                        layout="list"
                                        sidebarPosition="end"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/blog/category-left-sidebar"
                                render={(props) => (
                                    <BlogPageCategory
                                        {...props}
                                        layout="classic"
                                        sidebarPosition="start"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/blog/post-classic"
                                render={(props) => (
                                    <BlogPagePost
                                        {...props}
                                        layout="classic"
                                        sidebarPosition="end"
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/blog/post-full"
                                render={(props) => (
                                    <BlogPagePost {...props} layout="full" />
                                )}
                            />

                            {/*
                        // Account
                        */}
                            <Route
                                exact
                                path="/account/login"
                                component={AccountPageLogin}
                            />
                            <Route path="/account" component={AccountLayout} />

                            {/*
                        // Site
                        */}
                            <Redirect exact from="/site" to="/site/about-us" />
                            <Route
                                exact
                                path="/site/about-us"
                                component={homeComponent}
                            />
                            <Route
                                exact
                                path="/site/components"
                                component={SitePageComponents}
                            />
                            <Route
                                exact
                                path="/site/contact-us"
                                component={SitePageContactUs}
                            />
                            <Route
                                exact
                                path="/site/contact-us-alt"
                                component={SitePageContactUsAlt}
                            />
                            <Route
                                exact
                                path="/site/not-found"
                                component={SitePageNotFound}
                            />
                            <Route
                                exact
                                path="/site/faq"
                                component={SitePageFaq}
                            />
                            <Route
                                exact
                                path="/site/terms"
                                component={SitePageTerms}
                            />
                            <Route
                                exact
                                path="/site/typography"
                                component={SitePageTypography}
                            />
                            <Route
                                exact
                                path="/site/privacy"
                                component={SitePagePrivacyPolicy}
                            />
                            <Route
                                exact
                                path="/site/returns"
                                component={SitePageReturns}
                            />

                            {/*
                        // Page Not Found
                        */}
                            <Route component={SitePageNotFound} />
                        </Switch>
                    </Suspense>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
                {/*
                <BrowserView>
                    <MessengerCustomerChat
                      pageId= {process.env.REACT_APP_FACEBOOK_PAGEID}
                      appId= {process.env.REACT_APP_FACEBOOK_APPID}
                      htmlRef={window.location.href}
                      themeColor="#202c58"
                      loggedInGreeting={"Thank you for contacting us."}
                    />
                </BrowserView>
                */}
                <MobileView>
                    <WhatsAppButton />
                </MobileView>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};

export default Layout;
