export default [
    {
        _id: 183067,
        name: "Virtual Reality",
        menuUrl: "/department/ebay-usa/category/183067",
    },
    {
        _id: 182101,
        name: "Hair Extensions & Wigs",
        menuUrl: "/department/ebay-usa/category/182101",
    },
    {
        _id: 172020,
        name: "Eye Makeup",
        menuUrl: "/department/ebay-usa/category/172020",
    },
    {
        _id: 176985,
        name: "Vinyl Records",
        menuUrl: "/department/ebay-usa/category/176985",
    },
    {
        _id: 180959,
        name: "Vitamins & Lifestyle Supplements",
        menuUrl: "/department/ebay-usa/category/180959",
    },
    {
        _id: 78997,
        name: "Camera Lenses & Filters",
        menuUrl: "/department/ebay-usa/category/78997",
    },
    {
        _id: 33707,
        name: "Auto Parts & Accessories",
        menuUrl: "/department/ebay-usa/category/33707",
    },
    {
        _id: 246,
        name: "Action Figures",
        menuUrl: "/department/ebay-usa/category/246",
    },
    {
        _id: 69851,
        name: "Vintage, Retro, Mid-Century",
        menuUrl: "/department/ebay-usa/category/69851",
    },
    {
        _id: 183448,
        name: "LEGO Bricks & Building Pieces",
        menuUrl: "/department/ebay-usa/category/183448",
    },
    {
        _id: 717,
        name: "Vintage",
        menuUrl: "/department/ebay-usa/category/717",
    },

    {
        _id: 267,
        name: "Books",
        menuUrl: "/department/ebay-usa/category/267",
    },
    {
        _id: 96991,
        name: "Cell Phone Memory Cards",
        menuUrl: "/department/ebay-usa/category/96991",
    },
];
