import googleAnalyticsAPI from "./googleAnalytics";
import ReactGA from "react-ga";
const googleAnalytics = new googleAnalyticsAPI();
const log = require("../components/shared/helper/MartLogger.js").logger;

export default class Auth {
    //send auth data to google analytics
    sendToGoogleAnalytics = (category, action, label) => {
        /*googleAnalytics.event({
            eventCategory: category,
            eventAction: action,
            eventLabel: label.toString(),
            eventValue: 1,
            path: window.location.pathname,
        });*/
        ReactGA.event({
            category: category,
            action: action,
            label: label,
            value: 1,
        });
    };

    login = (phone, password) => {
        return fetch(process.env.REACT_APP_API + "/v1/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify({
                phone: phone,
                password: password,
            }),
        })
            .then((response) => response.json())
            .then((user) => {
                log("login response", user);
                return user;
            })
            .catch((error) => {
                log(error);
            });
    };

    verifyFacebook = (token) => {
        log("verifyFacebook", token);
        return fetch(
            process.env.REACT_APP_API +
                "/v1/auth/facebook/token?access_token=" +
                token,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((token) => {
                log("facebook token response", token);
                this.sendToGoogleAnalytics("login", "facebook", "facebook");
                return token;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    passwordLessLogin = (credentials) => {
        log("passwordLessLogin", credentials.phone);
        return fetch(process.env.REACT_APP_API + "/v1/auth/passwordLessLogin", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(credentials),
        })
            .then((response) => response.json())
            .then((user) => {
                this.sendToGoogleAnalytics("login", "phone", credentials.phone);
                return user;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };
}
