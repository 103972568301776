// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../svg";
import { currencyChange } from "../../store/currency";
import { localeChange } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";

// data stubs
import currencies from "../../data/shopCurrencies";
import mobileMenuLinks from "../../data/mobileMenu";
import categoriesAPI from "../../services/categories";

const log = require("../shared/helper/MartLogger.js").logger;

function MobileMenu(props) {
    const { mobileMenuState, closeMobileMenu, changeLocale, changeCurrency } =
        props;

    const categories = new categoriesAPI();
    const [mobileMenuLinks, setMobileMenu] = useState([]);

    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.open,
    });

    useEffect(() => {
        //get Mobile Menu
        categories
            .getTree(process.env.REACT_APP_MENU_ID)
            .then((response) => {
                //            log("treeAPIResponse - navlinks", response.tree)
                if (response.tree) setMobileMenu(response.tree.children);
            })
            .catch((error) => {
                log("error getTree mobilemenu", error);
            });
    }, []);

    //callback of department API
    /*
    const treeAPIResponse = (response) =>{  
        log("treeAPIResponse - navlinks", response.tree.children)
        setMobileMenu(response.tree.children)
    }*/

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === "language") {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === "currency") {
                const currency = currencies.find(
                    (x) => x.currency.code === item.data.code
                );

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }

        if (item.type === "link") {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button
                        type="button"
                        className="mobilemenu__close"
                        onClick={closeMobileMenu}
                    >
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks
                        links={mobileMenuLinks}
                        onItemClick={handleItemClick}
                    />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
