export default {
    name: process.env.REACT_APP_TENANT_SEO_PAGE_NAME,
    fullName: process.env.REACT_APP_TENANT_SEO_PAGE_FULLNAME,
    shortTitle: process.env.REACT_APP_TENANT_SEO_PAGE_SHORT_TITLE,
    longDescription: process.env.REACT_APP_TENANT_SEO_PAGE_LONG_DESCRIPTION,
    url: '',
    author: {
        name: 'red',
        profile_url: '',
        facebook_profile_url: process.env.REACT_APP_TENANT_FACEBOOK_PROFILE_URL,
        instagram_profile_url: process.env.REACT_APP_TENANT_INSTAGRAM_PROFILE_URL,
    },
    contacts: {
        address: process.env.REACT_APP_TENANT_ADDRESS,
        email: process.env.REACT_APP_TENANT_EMAIL,
        phone: process.env.REACT_APP_TENANT_PHONE,
    },
};
