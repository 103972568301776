const log = require("../components/shared/helper/MartLogger.js").logger;

class Order {
    saveOrder = (order) => {
        log("saveOrder", order);
        return fetch(process.env.REACT_APP_API + "/v1/order/create", {
            method: "POST",

            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },

            body: JSON.stringify(order),
        })
            .then((response) => response.json()) // convert to plain text
            .then((order) => {
                log("saved order", order);
                return order;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    //get Order
    getOrderByOrderId = (orderId) => {
        log("getOrderByOrderId,", orderId);
        var bearer =
            "Bearer " + JSON.parse(localStorage.getItem("fkToken")).accessToken;
        return fetch(
            process.env.REACT_APP_API +
                "/v1/order/getOrderByOrderId/" +
                orderId,
            {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    log("error getOrderByOrderId", response);
                    throw new Error(response.status);
                }
            })
            .then((order) => {
                return order;
            })
            .catch((error) => {
                log("error in getOrderByOrderId ", error.name, error.message);
                return error.message;
            });
    };

    //get all the orders by parentOrderId
    getOrderByParentOrderId = (parentOrderId) => {
        log("getOrderByParentOrderId", parentOrderId);
        return fetch(
            process.env.REACT_APP_API +
                "/v1/order/getOrderByParentOrderId/" +
                parentOrderId,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => response.json())
            .then((orders) => {
                return orders;
            })
            .catch((error) => {
                log("error in getOrderByOrderId ", error);
                return error;
            });
    };

    getOrderByUserId = (userId) => {
        var bearer =
            "Bearer " + JSON.parse(localStorage.getItem("fkToken")).accessToken;
        // var bearer = 'Bearer ' + "4324";
        return fetch(
            process.env.REACT_APP_API + "/v1/order/getOrderByUserId/" + userId,
            {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    log("error in response getOrderByUserId", response);
                    throw new Error(response.status);
                }
            })
            .then((order) => {
                return order;
            })
            .catch((error) => {
                log("error in getOrderByUserId ", error.name, error.message);
                return error.message;
            });
    };
}

export default Order;
