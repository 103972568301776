// react
import React, { useState, useEffect } from "react";

// third-party
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import dataAddresses from "../../data/accountAddresses";
import theme from "../../data/theme";
import utilsAPI from "../../services/utils";

const log = require("../shared/helper/MartLogger.js").logger;

export default function AccountPageAddresses() {
    const utils = new utilsAPI();
    const user = utils.getUserFromLocalStorage();
    log("ADDRESSES: user", user);
    log("user token", JSON.parse(localStorage.getItem("fkToken")));
    const [userAddress, setAddresses] = useState([]);

    useEffect(() => {
        setAddresses(user.address);
    }, []);

    const addresses = userAddress.map((address) => (
        <React.Fragment key={address._id}>
            <div className="addresses-list__item card address-card">
                {address.default && (
                    <div className="address-card__badge">Default</div>
                )}

                <div className="address-card__body">
                    <div className="address-card__name">{`${address.customerFirstName} ${address.customerLastName}`}</div>
                    <div className="address-card__row">
                        {address.customerCountry}
                        <br />
                        {address.customerPostCode}

                        {address.customerCity}
                        <br />
                        {address.customerAddress}
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">
                            Phone Number
                        </div>
                        <div className="address-card__row-content">
                            {address.customerPhone}
                        </div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">
                            Email Address
                        </div>
                        <div className="address-card__row-content">
                            {address.customerEmail}
                        </div>
                    </div>
                    <div className="address-card__footer">
                        <Link
                            to={{
                                pathname: "/account/addresses/address",
                                state: {
                                    addressId: address._id,
                                },
                            }}
                        >
                            Edit
                        </Link>
                        &nbsp;&nbsp;
                        <Link to="/">Remove</Link>
                    </div>
                </div>
            </div>
            <div className="addresses-list__divider" />
        </React.Fragment>
    ));

    return (
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${theme.name}`}</title>
            </Helmet>

            <Link
                to="/account/addresses/address/"
                className="addresses-list__item addresses-list__item--new"
            >
                <div className="addresses-list__plus" />
                <div className="btn btn-secondary btn-sm">Add New</div>
            </Link>
            <div className="addresses-list__divider" />
            {addresses}
        </div>
    );
}
