import React, { useState, useEffect } from "react";
import {
  auth,
  googleProvider,
  facebookProvider,
} from "../../../configs/firebase.js";
import authAPI from "../../../services/auth";
import queryString from "query-string";
import { connect } from "react-redux";
import { login } from "../../../store/auth/authActions";

const log = require("../../shared/helper/MartLogger.js").logger;

function FacebookAuth(props) {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const authentication = new authAPI();

  const loginFacebook = () => {
    log("login");
    auth.signInWithRedirect(facebookProvider);
  };

  const loginGoogle = () => {
    log("login");
    auth.signInWithRedirect(googleProvider);
  };

  const logout = () => {
    log("logout");
    auth.signOut().then((result) => {
      setUser(null);
      setToken(null);
    });
  };

  //redirect page
  const redirect = () => {
    log("redirect");
    const values = queryString.parse(props.location.search);
    log("Login Page - redirect to:", values); // "top"
    if (values.source == "checkout") {
      props.history.push("/shop/checkout");
    } else {
      props.history.push("/");
    }
  };

  const verifyFacebook = (result) => {
    var token = result.credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    authentication
      .verifyFacebook(token)
      .then((response) => {
        log("facebook token verified", response);
        setUser(response.user);
        setToken(response.token);
        redirect();
      })
      .catch(function (error) {
        log("facebook token verification failed.", error);
      });
  };

  // call the redux when token is recevied
  useEffect(() => {
    log("useEffect", user, token);
    props.login(token, user); //calling redux action
  }, [user, token]);

  auth
    .getRedirectResult()
    .then(function (result) {
      log("getRedirectResult", result);
      if (result.credential && result.credential.providerId == "facebook.com") {
        verifyFacebook(result);
      }
    })
    .catch(function (error) {
      log("error in facebook verify", error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });

  return (
    <div>
      <button type="text" onClick={loginFacebook} className="btn btn-facebook">
        <i class="fab fa-facebook-f"></i>
        &nbsp; facebook connect
      </button>
      {/*
        <button 
            type="text" 
            onClick= {loginGoogle}
            className="btn btn-facebook">
            <i class="fab fa-facebook-f"></i>
            &nbsp; google connect
        </button>
    */}
    </div>
  );
}

const mapStateToProps = (state) => {
  log("calling mapStateToProps - AccountPageLogin.js,  - state", state);
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  log("dispatch calling of Register action", dispatch);
  return {
    login: (token, user) => {
      dispatch(login(token, user));
    },
  };
};
export default FacebookAuth;
