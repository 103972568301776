// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";

//utils
import utilsAPI from "../../services/utils";

function Currency(props) {
    let { value, currency, currentCurrency, marketplace } = props;
    console.log("currency - value, currency, currentCurrency, marketplace", value, currency, currentCurrency, marketplace )
    let { symbol } = currentCurrency;
    const utils = new utilsAPI();
    if (currency && currentCurrency && currentCurrency.code !== currency.code && marketplace !== 'cj' && marketplace !== "ebay") {
        value = utils.convertCurrency(currentCurrency, currency, value);
    }
    
    if(marketplace === 'cj' || marketplace === 'ebay' ){
        symbol = currency.symbol
    }

    return (
        <React.Fragment>
            {value === undefined || value === null
                ? `${symbol}${0}`
                : `${symbol}${parseFloat(value)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
        </React.Fragment>
    );
}

Currency.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
