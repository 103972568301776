// react
import React from "react";

export default function WhatsAppButton() {
	return (
		<a
			href={
				"https://api.whatsapp.com/send?phone=" +
				process.env.REACT_APP_WHATSAPP +
				"&text=" +
				window.location.href
			}
			target="_blanks"
		>
			<button type="button" className="whatsapp-button">
				<i className="fab fa-whatsapp" />
			</button>
		</a>
	);
}
