// application
import enMessages from './messages/en';
import ruMessages from './messages/ru';
import rtlMessages from './messages/rtl';
import svMessages from './messages/sv';


export default {
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    
    ru: {
        messages: ruMessages,
        direction: 'ltr',
    },
    ar: {
        messages: rtlMessages,
        direction: 'rtl',
    },
    sv: {
        messages: svMessages,
        direction: 'ltr',
    }
    
};
