// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import CartIndicator from "./IndicatorCart";
import Departments from "./Departments";
import Indicator from "./Indicator";
import NavLinks from "./NavLinks";
import IndicatorSearch from "./IndicatorSearch";
import { Heart20Svg, LogoSmallSvg } from "../../svg";

function NavPanel(props) {
    const { layout, wishlist } = props;

    let logo = null;
    let departments = null;
    let searchIndicator;

    if (layout === "compact") {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/">
                    <LogoSmallSvg />
                </Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === "default") {
        departments = (
            <div className="nav-panel__departments">
                <Departments />
            </div>
        );
    }

    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row">
                    {logo}
                    {departments}

                    <div className="nav-panel__nav-links nav-links">
                        <NavLinks />
                    </div>

                    <div className="nav-panel__indicators">
                        {searchIndicator}

                        <Indicator
                            url="/shop/wishlist"
                            value={wishlist.length}
                            icon={<Heart20Svg />}
                        />

                        <CartIndicator />
                        {/*


                        <Indicator
                            url="/site/contact-us"
                            icon={<i class="far fa-comment-dots fa-lg"></i>}
                        />
                    */}
                        {
                            <a
                                style={{ marginTop: 7 }}
                                href={
                                    "https://api.whatsapp.com/send?phone=" +
                                    process.env.REACT_APP_WHATSAPP +
                                    "&text=Hi there.."
                                }
                                target="_blanks"
                            >
                                <Indicator
                                    className="indicator--mobile"
                                    icon={
                                        <i
                                            className="fab fa-whatsapp"
                                            style={{
                                                fontSize: "23px",
                                                color: "white",
                                            }}
                                        />
                                    }
                                />
                            </a>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

NavPanel.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

NavPanel.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanel);
