import axios from "axios";
const CancelToken = axios.CancelToken;
let cancel;
const log = require("../components/shared/helper/MartLogger.js").logger;

export default class GoogleAnalytics {
    pageview = (data) => {
        log("googleanalytics event environment", data, process.env.NODE_ENV);
        if (process.env.NODE_ENV !== "production") return;
        return fetch(
            process.env.REACT_APP_API + "/v1/googleAnalytics/pageview",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    domain: process.env.REACT_APP_DOMAIN,
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((pageview) => {
                log("pageview posted", pageview);
                return pageview;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };

    event = (data) => {
        log("googleanalytics event", data);
        if (process.env.NODE_ENV !== "production") return;
        return fetch(process.env.REACT_APP_API + "/v1/googleAnalytics/event", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                domain: process.env.REACT_APP_DOMAIN,
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((event) => {
                log("event posted", event);
                return event;
            })
            .catch((error) => {
                log(error);
                return error;
            });
    };
}
