 // react
import React, {useState, useEffect} from 'react';

// third-party

import { Helmet } from 'react-helmet';


function Easypaisa(props) {
    console.log("Easypaisa", props)

/*
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "./easy.js";
        script.async = true;

        document.body.appendChild(script);
        console.log("script", script)

        return () => {
            document.body.removeChild(script);
        }
    }, []);
    const lloadIframe = () =>{
        this.loadIframe()
    }*/


    return (
        <React.Fragment>
            <iframe id="easypay-iframe" name="easypay-iframe" src="https://easypaystg.easypaisa.com.pk/tpg/?storeId=1&orderId=6&transactionAmount=500.0&mobileAccountNo=03698965698&emailAddress=test@test.com&transactionType=InitialRequest&tokenExpiry=20201231%20111111&bankIdentificationNumber=& encryptedHashRequest=f4jnS1GpY97qR0KjjNutzdIbMhrtmgHObhMGWi9Sp7DS%2Bk%2FVM7usiV3K%2Fssyo8ULftZBUXkv%2FzduHaFcxq7dMRK6%2BgBppP61ZUQ%2BaOZzcXmKw6CgGCh71Vz3UuDulpDN" width="100%" height="500px"></iframe>
                <Helmet 
                  script={[{ 
                    type: 'text/javascript', 
                    innerHTML: `function loadIframe(iframeName, url) {
                    var storeID = document.getElementById("storeId").value;
                    var amount = document.getElementById("amount").value;
                    var orderID = document.getElementById("orderId").value;
                    var email = document.getElementById("email").value;
                    var cellNo = document.getElementById("cellNo").value;
                    var token = document.getElementById("token").value;
                    var bankId = document.getElementById("bankId").value;
                    var postBackURL = document.getElementById("postBackURL").value;
                    var merchantPaymentMethod = document.getElementById("merchantPaymentMethod").value;
                    var signature = document.getElementById("signature").value;
                    var params = { storeId: storeID, orderId: orderID, transactionAmount: amount, mobileAccountNo: cellNo, emailAddress: email, transactionType: "InitialRequest", tokenExpiry: token, bankIdentificationNumber: bankId, encryptedHashRequest: encryptedHashRequest,merchantPaymentMethod : merchantPaymentMethod, postBackURL:postBackURL,signature:signature};
                    var $iframe = $('#' + iframeName);
                    if ( $iframe.length ) {
                        if(params.storeId != "" && params.orderId !="") {
                            var str = jQuery.param( params);
                            $iframe.attr('src',url+'?'+str); // here you can change src
                        }
                        return false;
                    }
                    return true;
                }
                $( "#submitPaymentMethod" ).click(function() {
                    $("#iframe-class").addClass("show-iframe");
                    return loadIframe('easypay-iframe','http://localhost:3000/:0');
                });`
                  }]} />
            {/*
            <script dangerouslySetInnerHTML={{ __html:`
                function loadIframe(iframeName, url) {
                    var storeID = document.getElementById("storeId").value;
                    var amount = document.getElementById("amount").value;
                    var orderID = document.getElementById("orderId").value;
                    var email = document.getElementById("email").value;
                    var cellNo = document.getElementById("cellNo").value;
                    var token = document.getElementById("token").value;
                    var bankId = document.getElementById("bankId").value;
                    var postBackURL = document.getElementById("postBackURL").value;
                    var merchantPaymentMethod = document.getElementById("merchantPaymentMethod").value;
                    var signature = document.getElementById("signature").value;
                    var params = { storeId: storeID, orderId: orderID, transactionAmount: amount, mobileAccountNo: cellNo, emailAddress: email, transactionType: "InitialRequest", tokenExpiry: token, bankIdentificationNumber: bankId, encryptedHashRequest: encryptedHashRequest,merchantPaymentMethod : merchantPaymentMethod, postBackURL:postBackURL,signature:signature};
                    var $iframe = $('#' + iframeName);
                    if ( $iframe.length ) {
                        if(params.storeId != "" && params.orderId !="") {
                            var str = jQuery.param( params);
                            $iframe.attr('src',url+'?'+str); // here you can change src
                        }
                        return false;
                    }
                    return true;
                }
                $( "#submitPaymentMethod" ).click(function() {
                    $("#iframe-class").addClass("show-iframe");
                    return loadIframe('easypay-iframe','http://localhost:3000/:0');
                });`
            }} />*/}
            <input name="storeId" id="storeId" value="43" hidden = "true"/>
            <input name="amount" id="amount" value="10" hidden = "true"/>
            <input name="orderId" id="orderId" value="10" hidden = "true"/>
            <input name="token" id="token" value="10" hidden = "true"/>
            <input name="postBackURL" id="postBackURL" value="10" hidden = "true"/>
            <input name="timeStamp" id="timeStamp" value="10" hidden = "true"/>
            <input name="encryptedHashRequest" id="encryptedHashRequest" value="10" hidden = "true"/>


        </React.Fragment>
    );
}


export default Easypaisa;
