// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';


function SitePageTerms() {
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Terms And Conditions', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Terms And Conditions — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="document">
                        <div className="document__header">
                            <h1 className="document__title">Terms of Use</h1>
                            <div className="document__subtitle">This Agreement was last modified on 25 Jan 2020.</div>
                        </div>
                        <div className="document__content typography">

              
                            <p>These Terms of Use (“<strong>Terms</strong>”) govern your use of the websites and mobile applications provided by {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} (or referred to as “us”) (collectively the “<strong>Platforms</strong>”). Please read these Terms carefully. By accessing and using the Platforms, you agree that you have read, understood and accepted the Terms including any additional terms and conditions and any policies referenced herein, available on the Platforms or available by hyperlink. If you do not agree or fall within the Terms, please do not use the Platforms.</p>
                            <p>The Platforms may be used by (i) natural persons who have reached 18 years of age and (ii) corporate legal entities, e.g companies. Where applicable, these Terms shall be subject to country-specific provisions as set out herein.&nbsp;</p>
                            <p>Users below the age of 18 must obtain consent from parent(s) or legal guardian(s), who by accepting these Terms shall agree to take responsibility for your actions and any charges associated with your use of the Platforms and/or purchase of Goods. If you do not have consent from your parent(s) or legal guardian(s), you must stop using/accessing the Platforms immediately.&nbsp;</p>
                            <p>{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right to change or modify these Terms (including our policies which are incorporated into these Terms) at any time. You are strongly recommended to read these Terms regularly. You will be deemed to have agreed to the amended Terms by your continued use of the Platforms following the date on which the amended Terms are posted.</p>
                            <p><strong>1. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}</strong></p>
                            <p>1.1 Who we are&nbsp;</p>
                            <p>Depending on which Platform you access and use, these Terms form the contract between you and the following {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} entities:</p>
                            <ol>  
                           
                            <li><a href={`https://www.${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}>www.{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}</a></li>
                            
                            </ol>
                            <p>1.2 What we do&nbsp;</p>
                            <p>Through our Platforms, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} links you to the vendors (“<strong>Vendors</strong>”) for you to order a variety of goods including prepared meals, non-prepared food and miscellaneous non-food items (hereinafter collectively referred to as "<strong>Goods</strong>") to be delivered to you. When you place an order for Goods from our Vendors (“<strong>Order</strong>”), {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} acts as an agent on behalf of that Vendor to facilitate, process and conclude the order and subsequently for either us or the Vendor to deliver your Order to you. Vendors may be owned and operated by third party vendors, our affiliate companies, or us.</p>
                            <p>1.3 How to contact us</p>
                            <p>For customer support, you may reach out to us via email or through our in-app customer support chat feature.&nbsp;</p>
                            <p><strong>2 Use of the Platforms and {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} Account</strong></p>
                            <p>2.1 You will need to register for a {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account for you to use the Platform. When you register for a {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account we will ask you to provide your personal information including a valid email address, a mobile phone number and a unique password. To purchase an Order, depending on which payment method you opt for, you may need to provide us with your credit card details. Your unique password should not be shared with anyone and you agree to keep it secret at all times. You are solely responsible for keeping your password safe. Save for cases of fraud or abuse which are not your fault, you accept that all Orders placed under your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account are your sole responsibility.&nbsp;</p>
                            <p>2.2 {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} shall not be liable for Orders that encounter delivery issues due to incomplete, incorrect or missing information provided by you. You are obliged to provide information that is complete, accurate and truthful for the proper processing of the Order, including your delivery address and contact information.</p>
                            <p>2.3 If you wish to delete your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account, please send us an email requesting the same. We may restrict, suspend or terminate your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account and/or use of the Platforms, if we reasonably believe that:&nbsp;</p>
                            <p>2.3.1 someone other than you is using your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account; or&nbsp;</p>
                            <p>2.3.2 where you are suspected or discovered to have been involved in any activity or conduct that is in breach of these Terms, our policies and guidelines, or involved in activity or conduct which we deem in our sole discretion to be an abuse of the Platforms.</p>
                            <p><strong>3 Restrictions</strong></p>
                            <p><strong>3.1</strong> Activities Prohibited on the Platforms</p>
                            <p>The following is a non-exhaustive list of the types of conduct that are illegal or prohibited on the Platforms. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right to investigate and take appropriate legal action against anyone who, in {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}'s sole discretion, engages in any of the prohibited activities. Prohibited activities include, but are not limited to the following:</p>
                            <p>3.1.1 using the Platforms for any purpose in violation of local, state, or federal laws or regulations;</p>
                            <p>3.1.2 posting any content that infringes the intellectual property rights, privacy rights, publicity rights, trade secret rights, or any other rights of any party;</p>
                            <p>3.1.3 posting content that is unlawful, obscene, defamatory, threatening, harassing, abusive, slanderous, hateful, or embarrassing to any other person or entity as determined by {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} in its sole discretion or pursuant to local community standards;</p>
                            <p>3.1.4 posting content that constitutes cyber-bullying, as determined by {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} in its sole discretion;</p>
                            <p>3.1.5 posting content that depicts any dangerous, life-threatening, or otherwise risky behavior;</p>
                            <p>3.1.6 posting telephone numbers, street addresses, or last names of any person;</p>
                            <p>3.1.7 posting URLs to external websites or any form of HTML or programming code;</p>
                            <p>3.1.8 posting anything that may be “spam,” as determined by {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} in its sole discretion;</p>
                            <p>3.1.9 impersonating another person when posting content;</p>
                            <p>3.1.10 harvesting or otherwise collecting information about others, including e-mail addresses, without their consent;</p>
                            <p>3.1.11 allowing any other person or entity to use your identification for posting or viewing comments;</p>
                            <p>3.1.12 harassing, threatening, stalking, or abusing any person on the Platforms;</p>
                            <p>3.1.13 engaging in any other conduct that restricts or inhibits any other person from using or enjoying the Websites, or which, in the sole discretion of {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}, exposes {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} or any of its customers, suppliers, or any other parties to any liability or detriment of any type; or</p>
                            <p>3.1.14 encouraging other people to engage in any prohibited activities as described herein.&nbsp;</p>
                            <p>3.2 {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right but is not obligated to do any or all of the following:</p>
                            <p>3.2.1 investigate an allegation that any content posted on the Platforms does not conform to these 3.2.2 Terms and determine in its sole discretion to remove or request the removal of the content;</p>
                            <p>remove content which is abusive, illegal, or disruptive, or that otherwise fails to conform with these Terms;</p>
                            <p>3.2.3 suspend or terminate a user’s access to the Platforms or their {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} Account upon any breach of these Terms;</p>
                            <p>3.2.4 monitor, edit, or disclose any content on the Platforms; and</p>
                            <p>3.2.5 edit or delete any content posted on the Platforms, regardless of whether such content violates these standards.</p>
                            <p><strong>4 Intellectual Property&nbsp;</strong></p>
                            <p>All trademarks, logos, images, and service marks, including these Terms as displayed on the Platforms or in our marketing material, whether registered or unregistered, are the intellectual property of {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} and/or third parties who have authorised us with the use (collectively the “Trademarks”). You may not use, copy, reproduce, republish, upload, post, transmit, distribute, or modify these Trademarks in any way without our prior express written consent. The use of {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}'s trademarks on any other website not approved by us is strictly prohibited. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} will aggressively enforce its intellectual property rights to the fullest extent of the law, including criminal prosecution. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} neither warrants nor represents that your use of materials displayed on the Platforms will not infringe rights of third parties not owned by or affiliated with {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}. Use of any materials on the Platforms is at your own risk.</p>
                            <p><strong>5 Restrictions on Goods&nbsp;</strong></p>
                            <p>5.1 Some of the Goods we offer on our Platforms are subject to restrictions for purchase (“<strong>Restricted Goods</strong>”), depending on the applicable laws of the country you purchase the Restricted Goods from. &nbsp;</p>
                            <p>5.2 Cigarettes/Tobacco Products (“<strong>Tobacco</strong>”)&nbsp;</p>
                            <p>5.2.1 We may offer Tobacco on some of our Platforms where the laws allow. By offering Tobacco for sale on our Platforms, we do not purport to advertise, promote or encourage the purchase or use of Tobacco in any way.&nbsp;</p>
                            <p>5.2.2 To purchase Tobacco, you must be of the statutory legal age. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}, the Vendor and their delivery riders, as the case may be, reserve the right in their sole discretion:</p>
                            <p>(i) to ask for valid proof of age (e.g. ID card) to any persons before they deliver Tobacco;</p>
                            <p>(iii) to refuse delivery if you are unable to prove you are of legal age; and/or</p>
                            <p>(iii )to refuse delivery to any persons for any reason whatsoever.</p>
                            <p><strong>6 Orders&nbsp;</strong></p>
                            <p>6.1 When you place an Order with {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} will confirm your order by sending you a confirmation email containing the Order receipt. Where applicable, Orders will include delivery fees and any applicable tax (e.g. goods and services tax, value-added tax, etc.).&nbsp;</p>
                            <p>6.2 Minimum Order Value - Some of our Vendors require a minimum order value (“<strong>MOV</strong>”) before an Order can be placed and delivered to you. Where an applicable Order fails to meet the MOV, you will have the option of paying the difference to meet the MOV or to add more Goods to your Order.</p>
                            <p>6.3 Special Instructions – {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} and the Vendor (as the case may be) reasonably endeavour to comply with your special instructions for an Order. However in some cases where this is not feasible, possible or commercially reasonable, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} and/or the Vendor reserve the right to proceed to prepare the Order in accordance with standard operating procedures. &nbsp;</p>
                            <p>6.4 Allergens – {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} is not obligated to provide ingredient information or allergen information on the Platforms. Further, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} does not guarantee that the Goods sold by Vendors are free of allergens. If you have allergies, allergic reactions or dietary restrictions and requirements, please contact the Vendor before placing an Order on our Platforms.</p>
                            <p>6.5 Please note that your Order may be subject to additional terms and conditions provided by the Vendor.&nbsp;</p>
                            <p>6.6 Prior to placing the Order</p>
                            <p>6.6.1 You are required to provide the delivery address in order for the Platform to display the Vendors available in your delivery area.</p>
                            <p>6.6.2 Once you select a Vendor, you will be taken to that Vendor’s menu page for you to select and add your Goods to the cart.&nbsp;</p>
                            <p>6.7 Placing the Order</p>
                            <p>To complete an Order, please follow the onscreen instructions after clicking ‘Checkout’. You may be required to provide additional details for us to complete your Order. You are required to review and confirm that all the information you provide, including the amounts, delivery details, personal details, payment information, and voucher codes (if applicable) is true, accurate and complete before you click “<em>PLACE ORDER</em>”. An Order is successfully placed when you receive an email confirmation containing your Order receipt from us.</p>
                            <p>6.8 Cancelling an Order&nbsp;</p>
                            <p>6.8.1 Please contact us immediately via our in-app customer support chat feature if you wish to cancel your Order after it has been placed. You have the right to cancel your Order provided a Vendor has not yet accepted your Order.&nbsp;</p>
                              <p>6.9 {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right to cancel any Order and/or suspend, deactivate or terminate your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account in its sole discretion if it reasonably suspects or detects fraudulent behavior or activity associated with your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account and/or with your Order.&nbsp;</p>
                            <p><strong>7 Prices and Payments </strong></p>
                            <p>7.1 Prices quoted on the Platform shall be displayed in the applicable country’s national currency and subject to applicable tax. Prices and offers on the Platforms may vary from the prices and you accept that offers offered by our Vendors (either on their own websites, mobile applications, or at their brick-and-mortar outlets).&nbsp;</p>
                            <p>7.2 The way we display the prices of our Goods may vary depending on the Vendor, and the prices reflected on our Platforms may:&nbsp;</p>
                            <p>7.2.1 include GST, VAT or such other equivalent tax; or&nbsp;</p>
                            <p>7.2.2 exclude GST, VAT or such other equivalent tax.&nbsp;</p>
                            <p>A breakdown of the prices and additional charges are displayed before Checkout. When you place an Order, you agree to all amounts, additional charges and the final ‘Total’ amount which is displayed to you.&nbsp;</p>
                            <p>7.3.1 Delivery fees are chargeable on every Order unless:&nbsp;</p>
                            <p>7.3.2 you opt to collect your Order directly from the Vendor (“<strong>Pick-Up</strong>”);&nbsp;</p>
                            <p>7.3.3 you have a valid promotional or discount voucher and apply it at Checkout; or&nbsp;</p>
                            <p>unless stated otherwise.</p>
                            <p>7.4 Prices indicated on the Platforms are as at the time of each Order and may be subject to change.&nbsp;</p>
                            <p>7.5 You can choose to pay for an Order using any of the different payment methods offered on the Platforms including:&nbsp;</p>
                            <p>7.5.1 Our payment partners: Visa, Mastercard, American Express, Google Pay, PayPal, Apple Pay;&nbsp;</p>
                            <p>7.5.2 Cash-on-Delivery; or&nbsp;</p>
                            <p>7.5.3 Such other payment method we offer from time to time.&nbsp;</p>
                            <p>7.6 If you have existing credit in your {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} account or valid promotional or discount vouchers, you can use this pay for part or all of your Order as the case may be.&nbsp;</p>
                            <p>7.7 After an Order is successfully placed, you will receive an email confirmation from us with your Order receipt. Delivery fees will not appear in your Order receipt if you opt for Pick-Up.</p>
                            <p>7.8 Payment Methods&nbsp;</p>
                            <p>{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right to offer additional payment methods and/or remove existing payment methods at anytime in its sole discretion. If you choose to pay using an online payment method, the payment shall be processed by our third party payment service provider(s). With your consent, your credit card / payment information will be stored with our third party payment service provider(s) for future orders. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} does not store your credit card or payment information.&nbsp;</p>
                            <p><strong>8 Delivery, Pick-Up and Vendor Delivery&nbsp;</strong></p>
                            <p>8.1 Delivery Areas</p>
                            <p>You understand that our Vendors offer their Goods in specific delivery areas and our Vendors vary from delivery area to delivery area. By entering your delivery address on the Platforms, you will see the Vendors that we make available to you at that time. Delivery areas may expand, shrink or change depending on weather and traffic conditions and situations of force majeure.&nbsp;</p>
                            <p>8.2 Delivery Time</p>
                            <p>8.2.1 {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} shall deliver your Order to the delivery address provided by You. You may choose for your Order to be delivered “ASAP” or scheduled for a specific time. An estimated delivery time will be provided to you in your email confirmation but delivery times shall vary depending on factors that are not controlled by us (e.g. order quantity, distance, time of day (peak periods), weather conditions, traffic conditions, etc.). You can view the remaining delivery time of an Order when you click on ‘My orders’ on the Platforms. You acknowledge that the delivery time we provide is only an estimate and Orders may arrive earlier or later. To ensure that you do not miss a delivery of an Order, you should ensure that either you or someone is at the delivery location to receive the Order once an Order is placed. If your Order contains Tobacco (if applicable) and you or the recipient is or appears to be below the legal age, or fails to provide a valid proof of ID, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right not to deliver your Order to you.</p>
                            <p>8.3 Unsuccessful or Failed Deliveries&nbsp;</p>
                            <p>In cases where we attempt to deliver an Order but we are unable to do so due to the reasons caused by you, including but not limited to:</p>
                            <p>(i) no one was present or available to receive the Order; or&nbsp;</p>
                            <p>(ii) customer was uncontactable despite attempts to reach the customer via the phone number provided; or</p>
                            <p>(iii) lack of appropriate or sufficient access to deliver the Order successfully;&nbsp;</p>
                            <p>(iv) lack of a suitable or secure location to leave the Order; or</p>
                            <p>(v) in the case of Restricted Goods, customer did not meet the statutory age requirements or delivery did not deem it safe or appropriate for the customer to receive the Restricted Goods.</p>
                            <p>8.3.2 No-show Cancellations&nbsp;</p>
                            <p>If you remain uncontactable or fail to receive the Order within ten (10) minutes from the time the Order arrives at your delivery address, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right to cancel the Order without refund or remedy to you.&nbsp;</p>
                            <p>8.4 Wrong Order, Missing Items, Defective Goods&nbsp;</p>
                            <p>Upon receipt of your Order, if you discover that there are issues with your Order (e.g. wrong order, defective order, or missing items) please contact customer support via one of the methods indicated in Clause 1.3 above immediately. In some cases, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} may request for photographic proof and/or additional information to properly investigate the issue with your Order. If we determine that the Order and/or Goods you received are not of satisfactory condition or quality, we will compensate you for your Order or parts of your Order.</p>
                            <p>8.5 Order Pick-Up&nbsp;</p>
                            <p>8.5.1 Where available, you will have the option of collecting your Order in-person directly from the Vendor’s premises ("<strong>Pick-Up</strong>") instead of having the Order delivered to You. Your email confirmation will indicate the time for you to Pick-Up the Order (“<strong>Collection Time</strong>”). The Vendor will prepare the Order by the Collection Time. In some cases, a reasonable delay may be expected. The Vendor agrees to hold the Order for you at the Vendor’s premises for no more than a reasonable period of twenty (20) minutes from the Collection Time (“<strong>Holding Time</strong>”) and shall not be obliged to provide the Order to you if you fail to Pick-Up your Order within the Holding Time.&nbsp;</p>
                            <p>8.5.2 In the event of unreasonable delays in Pick-Up attributable to you, you bear the risk of any damage or loss of Goods or any deterioration in quality or change in condition of the Goods (e.g. changes in the temperature fit for consumption). In this case, you shall not be entitled to a replacement, refund or replacement of the Goods. You alone are responsible for inspecting the Goods/Order when you Pick-Up your Order and shall report any issues and/or defects to the Vendor before leaving the Vendor’s premises.</p>
                            <p>8.6 Vendor Delivery</p>
                            <p>In some cases, our Vendors will deliver the Order to you (“<strong>Vendor Delivery</strong>”). While we will use reasonable efforts to provide prior notice to you on Vendor Delivery, this may not always be possible. Where Vendor Delivery applies, we may ask you to contact the Vendor directly in the event of issues or delays in your delivery. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} shall not be responsible in any way for Orders or Goods that are delivered by Vendors.&nbsp;</p>
                            <p><strong>9 Vouchers, Discounts and Promotions</strong></p>
                            <p>9.1 From time to time, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} may run marketing and promotional campaigns which offer voucher codes, discounts, and other promotional offers to be used on the Platforms (“Vouchers”). Vouchers are subject to validity periods, redemption periods, and in certain cases, may only be used once.</p>
                            <p>9.2 Vouchers may not be valid when used in conjunction with other promotions, discounts or other vouchers. Additional terms and conditions may apply to Vouchers.&nbsp;</p>
                            <p>9.3 Unless otherwise stated, Vouchers can only be used on our Platforms.&nbsp;</p>
                            <p>9.4 Vouchers cannot be exchanged for cash.</p>
                            <p>9.5 {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} reserves the right to void, discontinue or reject the use of any Voucher without prior notice Individual restaurants terms &amp; conditions apply</p>
                            <p>9.6 We may exclude certain Vendors from the use of Vouchers at any time without prior notice to you.&nbsp;</p>
                            <p><strong>10 Representations, Warranties and Limitation of Liabilities </strong></p>
                            <p>10.1 Representations and Warranties&nbsp;</p>
                            <p>You acknowledge and agree that the content on the Platforms are provided on an “as is” and “as available” basis, and that your use of or reliance upon the Platforms and any content, goods, products or services accessed or obtained thereby is at your sole risk and discretion. While {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} makes reasonable efforts to ensure the provision of the Platforms and the services we offer, are available at all times, we do not warrant or represent that the Platforms shall be provided in a manner which is secure, timely, uninterrupted, error-free, free of technical difficulties, defects or viruses. Please expect temporary interruptions of the Platform due to scheduled or regular system maintenance work, downtimes attributable to internet or electronic communications or events of force majeure.</p>
                            <p>10.2 Limitation of Liability&nbsp;</p>
                            <p>To the extent permitted by law, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} (which shall include its employees, directors, agents, representatives, affiliates and parent company) exclude all liability (whether arising in contract, in negligence or otherwise) for loss or damage which you or any third party may incur in connection with our Platforms, our services, and any website linked to our Platforms and any content or material posted on it. Your exclusive remedy with respect to your use of the Platforms is to discontinue your use of the Platforms. The {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} entities, their agents, representatives, and service providers shall not be liable for any indirect, special, incidental, consequential, or exemplary damages arising from your use of the Platforms or for any other claim related in any way to your use of the Platforms. These exclusions for indirect, special, consequential, and exemplary damages include, without limitation, damages for lost profits, lost data, loss of goodwill, work stoppage, work stoppage, computer failure, or malfunction, or any other commercial damages or losses, even if the {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} entities, their agents, representatives, and service providers have been advised of the possibility thereof and regardless of the legal or equitable theory upon which the claim is based. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}, the {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} entities, its agents, representatives and service providers' liability shall be limited to the extent permitted by law.</p>
                            <p>10.3 Vendor’s representations</p>
                            <p>{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} shall neither be liable for actions or omissions of the Vendor nor you in regard to provision of the Goods and where Vendor Delivery applies to your Order. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} does not assume any liability for the quantity, quality, condition or other representations of the Goods and/or services provided by Vendors or guarantee the accuracy or completeness of the information (including menu information, photos and images of the Goods) displayed on the Vendor’s listing/offering on the Platform. Nothing in these Terms shall exclude Vendor’s liability for death or personal injury arising from Vendor’s gross negligence or willful misconduct.</p>
                            <p><strong>11 Vendor Liability&nbsp;</strong></p>
                            <p>Vendors are responsible for the preparation, condition and quality of Goods. In cases of Vendor Delivery, Vendors are responsible for delivery of the Goods and/or Orders. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} shall not be liable for any loss or damage arising from your contractual relationship with the Vendor.&nbsp;</p>
                            <p><strong>12 Personal Data (Personal Information) Protection&nbsp;</strong></p>
                            <p>You agree and consent to {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} and any of its affiliate companies collecting, using, processing and disclosing your Personal Data in accordance with these Terms and as further described in our Privacy Policy. Our Privacy Policy is available via the links on our Platforms, and shall form a part of these Terms.&nbsp;</p>
                            <p><strong>13 Indemnity&nbsp;</strong></p>
                            <p>You agree to indemnify, defend, hold harmless {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}, its directors, officers, employees, representatives, agents, and affiliates, from any and all third party claims, liability, damages and/or costs (including but not limited to, legal fees) arising from your use of the Platforms or your breach of these Terms.&nbsp;</p>
                            <p><strong>14 Third Party Links and Websites</strong></p>
                            <p>The Platforms may contain links to other third party websites and by clicking on these links, you agree to do so at your own risk. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} does not control or endorse these third party websites or links and shall not be responsible for the content of these linked pages. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} accepts no liability or responsibility for any loss or damage which may be suffered by you in relation to your access and use of these third party links and websites.</p>
                            <p><strong>15 Termination</strong></p>
                            <p>{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} has the right to terminate, suspend or delete your account and access to the Platforms, including any delivery service we provide to you in respect of an Order, for any reason, including, without limitation, if {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}, in its sole discretion, considers your use to be unacceptable, or in the event of any breach by you of the Terms. {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} may, but shall be under no obligation to, provide you a warning prior to termination of your use of the Websites.</p>
                            <p><strong>16 Amendments&nbsp;</strong></p>
                            <p>{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} may amend these Terms at any time in its sole discretion. The amended Terms shall be effective immediately upon posting and you agree to the new Terms by continued use of the Platforms. It is your responsibility to check the Terms regularly. If you do not agree with the amended Terms, whether in whole or in part, you must stop using the Platforms immediately.</p>
                            <p><strong>17 Severability&nbsp;</strong></p>
                            <p>If any provision of these Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms of Use, which shall remain in full force and effect. No waiver of any provision in these Terms of Use shall be deemed a further or continuing waiver of such provision or any other provision.</p>
                            <p><strong>18 Governing Law</strong></p>
                            <p>These Terms shall be governed and construed in accordance with the laws of the country / courts of jurisdiction in which the relevant {`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} entity is incorporated as set out in the table below. The following courts will have jurisdiction over any dispute or claim arising out of or in connection with the use of the Platforms:&nbsp;</p>
                            <ol>
                            
                            <li>{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`} Pakistan (<a href={`https://www.${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}>www.{`${process.env.REACT_APP_TENANT_BUSINESS_NAME}`}</a>); Islamic Republic of Pakistan</li>
                            
                            </ol>
                            <p><strong>19 Contact Us&nbsp;</strong></p>
                            <p>If you wish to contact us regarding any questions or comments you may have, please send an email to our customer support email or via our in-app customer support chat feature.</p>
                            <p><strong>20 Prevailing Language&nbsp;</strong></p>
                            <p>In the event of a dispute as to the Terms, the English version shall prevail. The English language version of these Terms shall control in all respects and shall prevail in case of any inconsistencies with translated versions.&nbsp;</p>
                            <p><br /><br /></p>


                        
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageTerms;
