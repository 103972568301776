// react
import React from "react";

function ProductTabDescription(props) {
    const { product } = props;

    return (
        <div className="typography">
            <div dangerouslySetInnerHTML={{ __html: product }} />
        </div>
    );
}

export default ProductTabDescription;
