import {
    LOCATIONVIEW_CLOSE,
    LOCATIONVIEW_OPEN,
} from "./locationviewActionTypes";

const log = require("../../components/shared/helper/MartLogger.js").logger;

export function locationviewOpen() {
    log("locationviewOpen action");
    return {
        type: LOCATIONVIEW_OPEN,
    };
}

export function locationviewClose() {
    log("locationviewClose action");
    return {
        type: LOCATIONVIEW_CLOSE,
    };
}
