// react
import React from 'react';

// third-party
//import ReactDOM from 'react-dom';
import { hydrate, render } from 'react-dom'; // for pre-rendering
import { Provider } from 'react-redux';
import firebase from "firebase/app";

// application
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';

// styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
//import './scss/style.scss';
import { subscribeUser } from './services/subscription';

process.env.REACT_APP_THEME_COLOR 
  ? require(`./scss/themes/${process.env.REACT_APP_THEME_COLOR}`)
  : require('./scss/style.scss')

if (process.env.REACT_APP_THEME_FONT) require(`./scss/fonts/${process.env.REACT_APP_THEME_FONT}`)

//loading .env file
require('dotenv').config();

//console.log("in index.js")
/*
ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={store}>
        <Root />
    </Provider>
), document.getElementById('root'));
*/

// For Pre-rendering
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(
      <Provider store={store}>
        <Root />
      </Provider>, 
      rootElement);
} else {
    render(
      <Provider store={store}>
        <Root />
      </Provider>, 
      rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});

subscribeUser()
//serviceWorker.register();

