import { LOGIN, LOGOUT } from "./authActionTypes";
const log = require("../../components/shared/helper/MartLogger.js").logger;

export const login = (token = false, user = null) => {
	log("action - login - token, user", token, user);
	return {
		type: LOGIN,
		token: token,
		user: user,
	};
};

export const logout = () => {
	return {
		type: LOGOUT,
	};
};
