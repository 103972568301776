// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { wishlistAddItem } from "../../store/wishlist";
import Indicator from "../header/Indicator";
import utilsAPI from "../../services/utils";
import TagManager from "react-gtm-module";
const log = require("../shared/helper/MartLogger.js").logger;

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        wishlistAddItem,
        compareAddItem,
        cart,
        address,
        marketplace,
    } = props;
    const utils = new utilsAPI();
    const [loading, setLoading] = useState(true);
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm":
            layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl":
            layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg":
            layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    let badges = [];
    let image;
    let price;
    let features;
    log("containerClasses", containerClasses);
    log("ProductCard", product, marketplace);
    let currency = {
        code: product.currency,
        symbol: product.currency,
        name: product.currency,
    };

    if (product.badges !== undefined && product.badges.includes("sale")) {
        badges.push(
            <div
                key="sale"
                className="product-card__badge product-card__badge--sale"
            >
                Sale
            </div>,
        );
    }
    if (product.badges !== undefined && product.badges.includes("hot")) {
        badges.push(
            <div
                key="hot"
                className="product-card__badge product-card__badge--hot"
            >
                Hot
            </div>,
        );
    }
    if (product.badges !== undefined && product.badges.includes("new")) {
        badges.push(
            <div
                key="new"
                className="product-card__badge product-card__badge--new"
            >
                New
            </div>,
        );
    }

    badges = badges.length ? (
        <div className="product-card__badges-list">{badges}</div>
    ) : null;

    if (product.images && product.images.length > 0) {
        image = (
            <div className="product-card__image">
                <Link
                    to={{
                        pathname:
                            marketplace === undefined
                                ? `/shop/product/${product.itemCustomId}`
                                : marketplace === "cj"
                                ? `/department/luxury/product/${product.id}`
                                : `/department/ebay-usa/product/${product.id}`,
                        state: {
                            title: product.name,
                            description: product.description,
                            image:
                                product.images[0] != undefined
                                    ? product.images[0][0]
                                    : `${process.env.REACT_APP_WEBSITE_LOGO}`,
                        },
                    }}
                >
                    <img
                        //style={{ maxHeight: 180 }}
                        src={
                            product.images[0] != undefined
                                ? product.images[0][0]
                                : ""
                        }
                        alt={product.name}
                    />
                </Link>
            </div>
        );
    }

    if (product.price !== undefined && product.salePrice) {
        price = (
            <div className="product-card__prices">
                {product.price <= product.salePrice && (
                    <span className="product-card__new-price">
                        <Currency
                            value={product.price}
                            currency={currency}
                            marketplace={marketplace}
                        />
                    </span>
                )}
                {product.price > product.salePrice && (
                    <div>
                        <span className="product-card__new-price">
                            <Currency
                                value={product.salePrice}
                                currency={currency}
                                marketplace={marketplace}
                            />
                        </span>
                        &nbsp;
                        <span className="product-card__sale-price">
                            <Currency
                                value={product.price}
                                currency={currency}
                                marketplace={marketplace}
                            />
                        </span>
                        &nbsp;
                        {/*
                        <span className="product-card__percentage-price">
                            {" "}
                            (
                            {100 -
                                (
                                    (product.salePrice / product.price) *
                                    100
                                ).toFixed()}
                            % off)
                        </span>
                    */}
                    </div>
                )}
                {product.freeShipping && (
                    <span className="product-card__percentage-price">
                        &nbsp; + free shipping from US
                    </span>
                )}
            </div>
        );
    } else if (product.price !== undefined) {
        price = (
            <div className="product-card__prices">
                <span className="product-card__new-price">
                    <Currency
                        value={product.price}
                        currency={currency}
                        marketplace={marketplace}
                    />
                </span>
            </div>
        );
    }

    if (product.features && product.features.length) {
        features = (
            <ul className="product-card__features-list">
                {product.features.map((feature, index) => (
                    <li key={index}>{`${feature.name}: ${feature.value}`}</li>
                ))}
            </ul>
        );
    }

    return (
        <div className={containerClasses}>
            <AsyncAction
                //action={() => quickviewOpen(product.id)}
                action={() => quickviewOpen(product.itemCustomId)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames("product-card__quickview", {
                            "product-card__quickview--preload": loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            {badges}
            {image}
            <div className="product-card__info">
                <div className="product-card__name">
                    <Link
                        to={{
                            pathname:
                                marketplace === undefined
                                    ? `/shop/product/${product.itemCustomId}`
                                    : `/department/ebay-usa/product/${product.id}`,
                            state: {
                                title: product.name,
                                description: product.description,
                                image:
                                    product.hasOwnProperty("images") &&
                                    product.images !== null &&
                                    product.images[0] !== undefined
                                        ? product.images[0][0]
                                        : `${process.env.REACT_APP_WEBSITE_LOGO}`,
                            },
                        }}
                    >
                        {utils.truncateString(product.name, 100)}
                    </Link>
                    {/*
                    <Link to={`/shop/product/${product.itemCustomId}`}>
                        {utils.truncateString(product.name, 100)}
                    </Link>
                */}
                </div>
                {/*
                    <div className="product-card__rating">
                        <Rating value={product.rating} />
                        <div className=" product-card__rating-legend">{`${product.reviews} Reviews`}</div>
                    </div>
                    */}

                {features}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    Availability:
                    <span className="text-success">In Stock</span>
                </div>
                {price}
                <div className="product-card__buttons">
                    {marketplace !== "cj" && marketplace !== "ebay" && (
                        <AsyncAction
                            action={() =>
                                cartAddItem(product, [], 1, cart, address)
                            }
                            render={({ run, loading }) => (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={classNames(
                                            "btn btn-primary product-card__addtocart",
                                            {
                                                "btn-loading": loading,
                                            },
                                        )}
                                    >
                                        <FormattedMessage
                                            id="product.addToCart"
                                            defaultMessage="Add to cart"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={classNames(
                                            "btn btn-secondary product-card__addtocart product-card__addtocart--list",
                                            {
                                                "btn-loading": loading,
                                            },
                                        )}
                                    >
                                        <FormattedMessage
                                            id="product.addToCart"
                                            defaultMessage="Add to cart"
                                        />
                                    </button>
                                </React.Fragment>
                            )}
                        />
                    )}
                    {(marketplace === "cj" || marketplace === "ebay") && (
                        <AsyncAction
                            render={({ run, loading }) => (
                                <React.Fragment>
                                    <button
                                        type="button"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            window.open(
                                                product.itemWebUrl,
                                                "_blank",
                                            );
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    event:
                                                        marketplace === "cj"
                                                            ? "cjVisitStore"
                                                            : "ebayVisitStore",
                                                    productLink:
                                                        product.itemWebUrl,
                                                },
                                            });
                                        }}
                                        className={classNames(
                                            "btn btn-primary product-card__addtocart",
                                            {
                                                "btn-loading": loading,
                                            },
                                        )}
                                    >
                                        <FormattedMessage
                                            id="product.buyNow"
                                            defaultMessage="Buy Now"
                                        />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            window.open(
                                                product.itemWebUrl,
                                                "_blank",
                                            );
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    event:
                                                        marketplace === "cj"
                                                            ? "cjVisitStore"
                                                            : "ebayVisitStore",
                                                    productLink:
                                                        product.itemWebUrl,
                                                },
                                            });
                                        }}
                                        className={classNames(
                                            "btn btn-secondary product-card__addtocart product-card__addtocart--list",
                                            {
                                                "btn-loading": loading,
                                            },
                                        )}
                                    >
                                        <FormattedMessage
                                            id="product.buyNow"
                                            defaultMessage="Buy Now"
                                        />
                                    </button>
                                </React.Fragment>
                            )}
                        />
                    )}

                    <AsyncAction
                        action={() => wishlistAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames(
                                    "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                    {
                                        "btn-loading": loading,
                                    },
                                )}
                            >
                                <Wishlist16Svg />
                            </button>
                        )}
                    />
                    {/*
                    <a 
                        href={"https://api.whatsapp.com/send?phone=923033330902&text=Hi there..I am interested in " + window.location.protocol + "//" +window.location.hostname + "/shop/product/" +product.id}
                        target="_blanks" style={{color: '#4fce5d'}}>
                        <Indicator
                            className="indicator--mobile"
                            icon={<i className="fab fa-whatsapp-square" style={{ fontSize: '25px', marginTop:0, }} />}
                        />  
                    </a>
                */}

                    {/*
                    <AsyncAction
                        action={() => compareAddItem(product)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                className={classNames('btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Compare16Svg />
                            </button>
                        )}
                    />
                    */}
                </div>
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf([
        "grid-sm",
        "grid-nl",
        "grid-lg",
        "list",
        "horizontal",
    ]),
};

//const mapStateToProps = () => ({});

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
