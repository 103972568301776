// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import InstagramEmbed from "react-instagram-embed";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { Wishlist16Svg, Compare16Svg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";
import Indicator from "../header/Indicator";
import { ebayUsaUrl } from "../../url";
import TagManager from "react-gtm-module";
import MediaQuery from "react-responsive";

//import TextareaAutosize from 'react-textarea-autosize'
const log = require("../shared/helper/MartLogger.js").logger;

class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantity: 1,
        };
    }

    handleChangeQuantity = (quantity) => {
        this.setState({ quantity });
    };

    render() {
        const {
            product,
            layout,
            wishlistAddItem,
            compareAddItem,
            cartAddItem,
            cart,
            address,
            marketplace,
        } = this.props;

        log("product.jsx, product", product);
        log("cart items", cart);
        var currency = {
            code: product.currency,
            symbol: product.currency,
            name: product.currency,
        };
        log("whats currency", currency, product.price);
        log("marketplace is ", marketplace);

        const { quantity } = this.state;
        let prices;

        if (product.salePrice) {
            prices = (
                <React.Fragment>
                    {product.price <= product.salePrice && (
                        <span className="product__new-price">
                            <Currency
                                value={product.price}
                                currency={currency}
                                marketplace={marketplace}
                            />
                        </span>
                    )}

                    {product.price > product.salePrice && (
                        <div>
                            <span className="product__new-price">
                                <Currency
                                    value={product.salePrice}
                                    currency={currency}
                                    marketplace={marketplace}
                                />
                            </span>{" "}
                            &nbsp;
                            <span className="product__sale-price">
                                <Currency
                                    value={product.price}
                                    currency={currency}
                                    marketplace={marketplace}
                                />
                            </span>{" "}
                            &nbsp;
                            {/*
                                <span className="product__percentage-price">
                                    (
                                    {100 -
                                        (
                                            (product.salePrice /
                                                product.price) *
                                            100
                                        ).toFixed()}
                                    % off)
                                </span>
                            */}
                        </div>
                    )}
                    {/*product.freeShipping && (
                        <span className="product__percentage-price">
                            &nbsp; + free shipping from US
                        </span>
                    )*/}
                    {"\n"}
                    {marketplace !== "cj" && marketplace !== "ebay" && (
                        <div>
                            + Shipping from USA to Pakistan is{" "}
                            {process.env.REACT_APP_PER_KG_COST}
                        </div>
                    )}
                </React.Fragment>
            );
        } else {
            prices = (
                <span className="product__new-price">
                    <Currency
                        value={product.price}
                        currency={currency}
                        marketplace={marketplace}
                    />
                </span>
            );
        }

        return (
            <div className={`product product--layout--${layout}`}>
                <div className="product__content">
                    {marketplace === "ebay" && (
                        <div className="block">
                            <a href="https://ebay.us/dBeGIf" target="_blank">
                                <img
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        "object-fit": "contain",
                                    }}
                                    src="images/ebay/ebayFashion728x90.jpg"
                                />
                            </a>
                        </div>
                    )}
                    <ProductGallery
                        layout={layout}
                        images={product.images[0]}
                        alt={product.name}
                    />

                    <div className="product__info">
                        <div className="product__wishlist-compare">
                            <AsyncAction
                                action={() => wishlistAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Wishlist"
                                        onClick={run}
                                        className={classNames(
                                            "btn btn-sm btn-light btn-svg-icon",
                                            {
                                                "btn-loading": loading,
                                            },
                                        )}
                                    >
                                        <Wishlist16Svg />
                                    </button>
                                )}
                            />

                            {/*
                            <AsyncAction
                                action={() => compareAddItem(product)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title="Compare"
                                        onClick={run}
                                        className={classNames('btn btn-sm btn-light btn-svg-icon', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <Compare16Svg />
                                    </button>
                                )}
                            />
                            */}
                        </div>
                        <h1 className="product__name">{product.name}</h1>
                        <span>
                            {" "}
                            <Link
                                target="_blank"
                                style={{ color: "white" }}
                                to={{ pathname: product.itemWebUrl }}
                            >
                                {" "}
                                Link{" "}
                            </Link>
                        </span>

                        {product.hasOwnProperty("review") && (
                            <div className="product__rating">
                                <div className="product__rating-stars">
                                    <Rating value={product.rating} />
                                </div>
                                <div className="product__rating-legend">
                                    <Link to="/">{`${product.reviews} Reviews`}</Link>
                                    <span>/</span>
                                    <Link to="/">Write A Review</Link>
                                </div>
                            </div>
                        )}
                        {/*
                            product.hasOwnProperty("description") && (
                                <TextareaAutosize readOnly={true} style={{border:'none', width:'300px', resize: 'none'}}>
                                    {product.description}
                                </TextareaAutosize>
                            )
                        */}
                        {
                            <div
                                className="product__description"
                                style={{ whiteSpace: "pre-wrap" }}
                            >
                                {product.description}
                            </div>
                        }
                        {product.hasOwnProperty("features") && (
                            <ul className="product__features">
                                <li>Speed: 750 RPM</li>
                                <li>Power Source: Cordless-Electric</li>
                                <li>Battery Cell Type: Lithium</li>
                                <li>Voltage: 20 Volts</li>
                                <li>Battery Capacity: 2 Ah</li>
                            </ul>
                        )}
                        <ul className="product__meta">
                            {product.hasOwnProperty("availability") &&
                                product.availability !== undefined && (
                                    <li className="product__meta-availability">
                                        <FormattedMessage
                                            id="product.availability"
                                            defaultMessage="Availability"
                                        />
                                        :{" "}
                                        <span className="text-success">
                                            {product.availability}
                                        </span>
                                    </li>
                                )}
                            {product.hasOwnProperty("condition") &&
                                product.condition !== undefined && (
                                    <li className="product__meta-availability">
                                        <FormattedMessage
                                            id="product.condition"
                                            defaultMessage="Condition"
                                        />
                                        :{" "}
                                        <span className="product-condition">
                                            {product.condition}
                                        </span>
                                    </li>
                                )}
                            {/*
                            <InstagramEmbed
                                url="https://www.instagram.com/shiptohome.pk/"
                                clientAccessToken="379723333852701|3069d919ec148782ef63191113ccb4f9"
                                maxWidth={320}
                                hideCaption={false}
                                containerTagName="div"
                                protocol=""
                                injectScript
                                onLoading={() => {}}
                                onSuccess={() => {}}
                                onAfterRender={() => {}}
                                onFailure={() => {}}
                            />
                        */}
                            {product.hasOwnProperty("brand") &&
                                product.brand !== undefined && (
                                    <li>
                                        Brand: &nbsp;
                                        {window.location.pathname.includes(
                                            ebayUsaUrl,
                                        ) ? (
                                            <Link
                                                to={`${ebayUsaUrl}/brand/${product.brand.title}`}
                                            >
                                                {" "}
                                                <span className="product-brand">
                                                    {product.brand.title}
                                                </span>{" "}
                                            </Link>
                                        ) : (
                                            <Link
                                                to={`${/brands/}${
                                                    product.brand.title
                                                }`}
                                            >
                                                <span className="product-brand">
                                                    {product.brand.title}
                                                </span>
                                            </Link>
                                        )}
                                    </li>
                                )}
                            {product.hasOwnProperty("barcode") && (
                                <li>Barcode: {product.barcode}</li>
                            )}
                        </ul>
                    </div>

                    <div className="product__sidebar">
                        <div className="product__availability">
                            <FormattedMessage
                                id="product.availability"
                                defaultMessage="Availability"
                            />
                            : <span className="text-success">In Stock</span>
                        </div>

                        <div className="product__prices">{prices}</div>

                        <form className="product__options">
                            {product.hasOwnProperty("color") && (
                                <div className="form-group product__option">
                                    <div className="product__option-label">
                                        Color
                                    </div>
                                    <div className="input-radio-color">
                                        <div className="input-radio-color__list">
                                            <label
                                                className="input-radio-color__item input-radio-color__item--white"
                                                style={{ color: "#fff" }}
                                                data-toggle="tooltip"
                                                title="White"
                                            >
                                                <input
                                                    type="radio"
                                                    name="color"
                                                />
                                                <span />
                                            </label>
                                            <label
                                                className="input-radio-color__item"
                                                style={{ color: "#ffd333" }}
                                                data-toggle="tooltip"
                                                title="Yellow"
                                            >
                                                <input
                                                    type="radio"
                                                    name="color"
                                                />
                                                <span />
                                            </label>
                                            <label
                                                className="input-radio-color__item"
                                                style={{ color: "#ff4040" }}
                                                data-toggle="tooltip"
                                                title="Red"
                                            >
                                                <input
                                                    type="radio"
                                                    name="color"
                                                />
                                                <span />
                                            </label>
                                            <label
                                                className="input-radio-color__item input-radio-color__item--disabled"
                                                style={{ color: "#4080ff" }}
                                                data-toggle="tooltip"
                                                title="Blue"
                                            >
                                                <input
                                                    type="radio"
                                                    name="color"
                                                    disabled
                                                />
                                                <span />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {product.hasOwnProperty("weight") &&
                                product.weight !== null && (
                                    <div className="form-group product__option">
                                        <div className="product__option-label">
                                            <FormattedMessage
                                                id="product.weight"
                                                defaultMessage="Weight"
                                            />
                                        </div>
                                        <div className="input-radio-label">
                                            <div className="input-radio-label__list">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        name="material"
                                                    />
                                                    <span>
                                                        {product.weight}{" "}
                                                        {product.weightScale}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            {product.hasOwnProperty("material") && (
                                <div className="form-group product__option">
                                    <div className="product__option-label">
                                        Material
                                    </div>
                                    <div className="input-radio-label">
                                        <div className="input-radio-label__list">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="material"
                                                />
                                                <span>Metal</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="material"
                                                />
                                                <span>Wood</span>
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="material"
                                                    disabled
                                                />
                                                <span>Plastic</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="form-group product__option">
                                {marketplace !== "cj" &&
                                    marketplace !== "ebay" && (
                                        <label
                                            htmlFor="product-quantity"
                                            className="product__option-label"
                                        >
                                            <FormattedMessage
                                                id="product.quantity"
                                                defaultMessage="Quantity"
                                            />
                                        </label>
                                    )}
                                <div className="product__actions">
                                    {marketplace !== "cj" &&
                                        marketplace !== "ebay" && (
                                            <div className="product__actions-item">
                                                <InputNumber
                                                    id="product-quantity"
                                                    aria-label="Quantity"
                                                    className="product__quantity"
                                                    size="lg"
                                                    min={1}
                                                    value={quantity}
                                                    onChange={
                                                        this
                                                            .handleChangeQuantity
                                                    }
                                                />
                                            </div>
                                        )}
                                    {marketplace !== "cj" &&
                                        marketplace !== "ebay" && (
                                            <div className="product__actions-item product__actions-item--addtocart">
                                                <AsyncAction
                                                    actionType="addToCart"
                                                    action={() =>
                                                        cartAddItem(
                                                            product,
                                                            [],
                                                            quantity,
                                                            cart,
                                                            address,
                                                        ).then((response) => {
                                                            log("item added");
                                                        })
                                                    }
                                                    render={({
                                                        run,
                                                        loading,
                                                    }) => (
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            className={classNames(
                                                                "btn btn-primary btn-lg",
                                                                {
                                                                    "btn-loading":
                                                                        loading,
                                                                },
                                                            )}
                                                        >
                                                            <FormattedMessage
                                                                id="product.addToCart"
                                                                defaultMessage="Add to cart"
                                                            />
                                                        </button>
                                                    )}
                                                />
                                            </div>
                                        )}
                                    {(marketplace === "cj" ||
                                        marketplace === "ebay") && (
                                        <div className="product__actions-item product__actions-item--addtocart">
                                            <AsyncAction
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Visit Store"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            window.open(
                                                                product.itemWebUrl,
                                                                "_blank",
                                                            );
                                                            TagManager.dataLayer(
                                                                {
                                                                    dataLayer: {
                                                                        event:
                                                                            marketplace ===
                                                                            "cj"
                                                                                ? "cjVisitStore"
                                                                                : "ebayVisitStore",
                                                                        productLink:
                                                                            product.itemWebUrl,
                                                                    },
                                                                },
                                                            );
                                                        }}
                                                        className={classNames(
                                                            "btn btn-primary btn-lg",
                                                            {
                                                                "btn-loading":
                                                                    loading,
                                                            },
                                                        )}
                                                    >
                                                        <FormattedMessage
                                                            id="product.buyNow"
                                                            defaultMessage="Buy Now"
                                                        />
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    )}
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <AsyncAction
                                            action={() =>
                                                wishlistAddItem(product)
                                            }
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Wishlist"
                                                    onClick={run}
                                                    className={classNames(
                                                        "btn btn-secondary btn-svg-icon btn-lg",
                                                        {
                                                            "btn-loading":
                                                                loading,
                                                        },
                                                    )}
                                                >
                                                    <Wishlist16Svg />
                                                </button>
                                            )}
                                        />
                                    </div>
                                    {
                                        <div className="product__actions-item product__actions-item--wishlist">
                                            <a
                                                href={
                                                    "https://api.whatsapp.com/send?phone=" +
                                                    process.env
                                                        .REACT_APP_WHATSAPP +
                                                    "&text=" +
                                                    window.location.protocol +
                                                    "//" +
                                                    window.location.hostname +
                                                    window.location.pathname
                                                }
                                                target="_blanks"
                                                style={{ color: "#4fce5d" }}
                                            >
                                                <Indicator
                                                    className="indicator--mobile"
                                                    icon={
                                                        <i
                                                            className="fab fa-whatsapp-square"
                                                            style={{
                                                                fontSize:
                                                                    "50px",
                                                                marginTop: 5,
                                                            }}
                                                        />
                                                    }
                                                />
                                            </a>
                                        </div>
                                    }
                                    <div className="product__actions-item product__actions-item--wishlist">
                                        <Indicator
                                            className="indicator--mobile"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    window.location.href,
                                                );
                                                toast.success(
                                                    `Product link copied to clipboard`,
                                                );
                                            }}
                                            icon={
                                                <i
                                                    className="fas fa-link"
                                                    style={{
                                                        fontSize: "35px",
                                                        marginTop: 5,
                                                    }}
                                                />
                                            }
                                        />
                                    </div>
                                    <div className="product__actions-item product__actions-item--compare">
                                        {/*
                                        <AsyncAction
                                            action={() => compareAddItem(product)}
                                            render={({ run, loading }) => (
                                                <button
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Compare"
                                                    onClick={run}
                                                    className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                                                        'btn-loading': loading,
                                                    })}
                                                >
                                                    <Compare16Svg />
                                                </button>
                                            )}
                                        />
                                    */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    {product.hasOwnProperty("tags") && (
                        <div className="product__footer">
                            <div className="product__tags tags">
                                <div className="tags__list">
                                    <Link to="/">Mounts</Link>
                                    <Link to="/">Electrodes</Link>
                                    <Link to="/">Chainsaws</Link>
                                </div>
                            </div>

                            <div className="product__share-links share-links">
                                <ul className="share-links__list">
                                    <li className="share-links__item share-links__item--type--like">
                                        <Link to="/">Like</Link>
                                    </li>
                                    <li className="share-links__item share-links__item--type--tweet">
                                        <Link to="/">Tweet</Link>
                                    </li>
                                    <li className="share-links__item share-links__item--type--pin">
                                        <Link to="/">Pin It</Link>
                                    </li>
                                    <li className="share-links__item share-links__item--type--counter">
                                        <Link to="/">4K</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    address: state.map.address,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
//export default connect(() => ({}), mapDispatchToProps)(Product);
