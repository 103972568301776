// react
import React, { useState, useEffect } from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Waypoint } from "react-waypoint";

// application
import CategorySidebar from "./CategorySidebar";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import { sidebarClose } from "../../store/sidebar";
import BlockHeader from "../shared/BlockHeader";

// data stubs

import products from "../../data/shopProducts";
import theme from "../../data/theme";
import itemsAPI from "../../services/items";
import categoriesAPI from "../../services/categories";
import utilsAPI from "../../services/utils";
import googleAnalyticsAPI from "../../services/googleAnalytics";
const log = require("../shared/helper/MartLogger.js").logger;

function ShopPageCategory(props) {
    const {
        columns,
        viewMode,
        sidebarPosition,
        source,
        match,
        address,
        isShopPageCategoryEmbedded,
        title,
    } = props;

    //const { id } = props.match.params;
    const departmentId =
        props.location && props.location.state !== undefined
            ? props.location.state.departmentId
            : isShopPageCategoryEmbedded
            ? process.env.REACT_APP_MENU_ID
            : null;
    const departmentName =
        props.location && props.location.state !== undefined
            ? props.location.state.departmentName
            : isShopPageCategoryEmbedded
            ? "New Products"
            : null;
    const googleAnalytics = new googleAnalyticsAPI();
    log(
        "console - ShopPageCategory, props.location.state, address",
        props.location,
        address
    );
    log(
        "ShopPageCategory, handle, departmentId, departmentName",
        departmentId,
        departmentName
    );

    const [breadcrumb, setBreadcrumb] = useState([{ title: "Home", url: "/" }]);
    let content;
    const [sideBarCategories, setSideBarCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [category, setCategory] = useState(null);
    const [pageDescription, setPageDescription] = useState(
        theme.longDescription
    );
    const [categoryName, setCategoryName] = useState(theme.shortTitle);
    const [url, setUrl] = useState(null);
    const item = new itemsAPI();
    const categories = new categoriesAPI();
    const utils = new utilsAPI();
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [page, setPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const ITEMS_PER_PAGE = 12;

    log(
        "match, source",
        match,
        source,
        window.location,
        window.location.pathname
    );

    //load breadcrumb
    const loadBreadCrumb = (category) => {
        log("loadBreadCrumb", category);
        categories
            .breadcrumb(category)
            .then((response) => {
                log("returned breadcrumb", response);
                const data = response.map((item) => ({
                    url: item.hasOwnProperty("menuUrl") ? item.menuUrl : "",
                    title: item.hasOwnProperty("title") ? item.title : "",
                }));
                log("breadcrumb - product", data);
                setBreadcrumb(data);
                //  setCategoryName(data.slice(-1).pop().title)
            })
            .catch((error) => {
                log("ShopPageCategory - breadcrumb error", error);
            });
    };

    useEffect(() => {
        log("useEffect category..", category);
        if (category !== null) {
            loadBreadCrumb(category);
            //getItemsByCategoryId(category)
            setLoading(true);
            setHasNextPage(true);
            log("and the category is: ", category);
            log("hasNextPage", hasNextPage);
            loadData()
                .then((product) => {
                    log("response of load more data", product);
                    const distinctCategoriesTitle =
                        utils.getDistinctCategoriesFromProducts(
                            product,
                            "title"
                        );
                    const distinctCategoriesId =
                        utils.getDistinctCategoriesFromProducts(product);
                    setPageDescription(
                        process.env.REACT_APP_SEO_DESCRIPTION_CATEGORY_PAGE +
                            " " +
                            distinctCategoriesTitle.join()
                    );
                    log(
                        "distinctCategories",
                        distinctCategoriesTitle,
                        distinctCategoriesId
                    );
                    setSideBarCategories(distinctCategoriesId);
                    setLoading(false);
                })
                .catch((error) => {
                    log("error in load more data", error);
                    setHasNextPage(false);
                    setLoading(false);
                });
        }
    }, [category]);

    const loadData = async () => {
        try {
            log("calling load data", category);
            const response = await item.getNearestItemsByCategory(
                category,
                address
                    ? address.latitude
                    : process.env.REACT_APP_DEFAULT_LATITUDE,
                address
                    ? address.longitude
                    : process.env.REACT_APP_DEFAULT_LONGITUDE,
                page,
                ITEMS_PER_PAGE
            );
            if (!response) {
                throw Error(response);
            }
            log("show products on shop page", response);
            log(
                "total page, current page",
                response.count,
                response.count / ITEMS_PER_PAGE,
                page
            );
            if (page >= response.count / ITEMS_PER_PAGE) {
                setHasNextPage(false);
            }
            const product = utils.shuffleArray(
                utils.parseProductArray(response)
            );
            log("parsed product array", product, products);
            setProducts([...products, product]);
            setPage((page) => page + 1);
            return Promise.resolve(product);
        } catch (error) {
            return Promise.reject(new Error(error));
        }
    };

    //infinite scroll
    const loadMoreData = () => {
        log("calling loadMoreData", category);
        log("hasNextPage", hasNextPage);
        loadData()
            .then((response) => {
                log("response of load more data", response);
            })
            .catch((error) => {
                log("error in load more data", error);
            });
    };

    //this method will fetch category id from url
    const getCategoryByURL = (url) => {
        log("ShopPageCategory - getCategoryIdbyUrl", url);
        return categories
            .getCategoryByURL(url)
            .then((category) => {
                log("getCategoryByURL categoryId", category);
                return {
                    departmentId: category.id,
                    departmentName: category.name,
                };
            })
            .catch((error) => {
                log("error in getCategoryByURL", error);
                return error;
            });
    };

    // this effect will call everytime the component
    // render and set the value of url path
    useEffect(() => {
        log("useEffect - HIT SOURCE", source, window.location.pathname);
        setPage(1);
        const categoryURL = utils.parseCategoryURL(window.location.pathname); //remove the last forward slash if there is a slash
        if (departmentId !== null && departmentName !== null) {
            setCategory(departmentId);
            setCategoryName(departmentName);
            setProducts([]);
        } else {
            getCategoryByURL(categoryURL)
                .then((response) => {
                    log("getCategoryByURL response", response);
                    setCategory(response.departmentId);
                    setCategoryName(response.departmentName);
                    setProducts([]);
                })
                .catch((error) => {
                    log("error in getCategoryByURL", error);
                });
        }
    }, [window.location.pathname]);

    useEffect(() => {
        log("products useEffect", products);
    }, [products]);

    if (columns > 3) {
        content = (
            <div className="container">
                {isShopPageCategoryEmbedded && <BlockHeader title={title} />}
                <div className="block">
                    <ProductsView
                        products={products}
                        layout={viewMode}
                        grid={`grid-${columns}-full`}
                        limit={15}
                        offcanvas={offcanvas}
                        loading={loading}
                        isShopPageCategoryEmbedded={isShopPageCategoryEmbedded}
                    />
                </div>
                {
                    <CategorySidebar
                        offcanvas={offcanvas}
                        loading={loading}
                        source={source}
                        category={sideBarCategories}
                        loading={loading}
                    />
                }
            </div>
        );
    } else {
        const sidebar = (
            <div className="shop-layout__sidebar">
                {
                    <CategorySidebar
                        offcanvas={offcanvas}
                        category={sideBarCategories}
                        source={source}
                        loading={loading}
                    />
                }
            </div>
        );

        content = (
            <div className="container">
                {isShopPageCategoryEmbedded && (
                    <BlockHeader title="New Arrivals" />
                )}
                <div
                    className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}
                >
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">
                            {products !== null && (
                                <ProductsView
                                    products={products}
                                    layout={viewMode}
                                    grid="grid-3-sidebar"
                                    limit={15}
                                    offcanvas={offcanvas}
                                    loading={loading}
                                    searchQuery={searchQuery}
                                    isShopPageCategoryEmbedded={
                                        isShopPageCategoryEmbedded
                                    }
                                />
                            )}
                        </div>
                        {products.length == 0 && loading == false && (
                            <div>
                                <div>No results for {searchQuery}</div>
                                <div>
                                    Try checking your spelling or &nbsp;
                                    <a
                                        href={
                                            "https://api.whatsapp.com/send?phone=" +
                                            process.env.REACT_APP_WHATSAPP +
                                            "&text=Hi there.."
                                        }
                                        target="_blanks"
                                        style={{ color: "blue" }}
                                    >
                                        contact us
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>

                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            {!isShopPageCategoryEmbedded && (
                <Helmet>
                    <title>{`Best prices of ${categoryName}: ${process.env.REACT_APP_TENANT_BUSINESS_DOMAIN_NAME}`}</title>
                    <meta
                        name="description"
                        content={`${categoryName} - ${pageDescription}`}
                    />
                    <meta
                        property="og:title"
                        content={`${categoryName} - ${theme.name}`}
                    />
                    <meta
                        property="og:description"
                        content={`${categoryName} - ${pageDescription}`}
                    />
                    <meta
                        property="og:url"
                        content={`${process.env.REACT_APP_WEBSITE_URL}${window.location.pathname}`}
                    />
                    <meta
                        property="og:image"
                        content={`${process.env.REACT_APP_WEBSITE_LOGO}`}
                    />
                    <meta
                        property="fb:app_id"
                        content={`${process.env.REACT_APP_FACEBOOK_APPID}`}
                    />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary"></meta>
                </Helmet>
            )}
            {!isShopPageCategoryEmbedded && (
                <PageHeader header={categoryName} breadcrumb={breadcrumb} />
            )}

            {content}
            {hasNextPage && (
                <div
                    className="container"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "grey",
                    }}
                >
                    <Waypoint onEnter={loadMoreData}>
                        <h4>
                            {" "}
                            Loading more products &nbsp;
                            <i
                                class="fa fa-spinner fa-spin"
                                style={{ color: "black" }}
                            ></i>
                        </h4>
                    </Waypoint>
                </div>
            )}
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    address: state.map.address,
});

const mapDispatchToProps = {
    sidebarClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
