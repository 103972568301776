// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// application
import departmentsAria from "../../../services/departmentsArea";
import languages from "../../../i18n";
import StroykaSlick from "../StroykaSlick";

//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Formik, Form, Field, ErrorMessage } from "formik";
import utilsAPI from "../../../services/utils";
import {
    postalCodeVerify,
    setPostalCode,
    setDeliveryMethod,
} from "../../../store/delivery";

const log = require("../helper/MartLogger.js").logger;

const postCodes = [123, 456];

class PostalCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonDisabled: true,
            message: "",
        };
        this.utils = new utilsAPI();
    }

    handlePostalCode = (event) => {
        const { postalCodeVerify, setPostalCode } = this.props;
        const { value } = event.target;
        setPostalCode(value);
        this.verifyPostalCode(value)
            ? postalCodeVerify("verified")
            : postalCodeVerify("not_verified");
    };

    handleStoreClick = () => {
        this.props.setDeliveryMethod("store");
    };

    onSubmitPostalCode = () => {
        const { postalCodeVerify, setPostalCode } = this.props;
        const { postalCode } = this.state;

        if (this.verifyPostalCode(postalCode)) {
            postalCodeVerify("verified");
            setPostalCode(this.state.postalCode);
        }
    };

    verifyPostalCode = (postalCode) => {
        log("verifying postal code", postCodes, postalCode);
        if (postCodes.includes(parseInt(postalCode))) {
            log("found", postalCode);
            this.setState({
                buttonDisabled: false,
                message: "We deliver in your area",
            });
            return true;
        } else {
            this.setState({
                buttonDisabled: true,
                message: "",
            });
            return false;
        }
    };

    render() {
        const { locale, withDepartments, status, postalCode, deliveryMethod } =
            this.props;
        const { direction } = languages[locale];
        const { message } = this.state;

        log("delivery status", status, postalCode, deliveryMethod);

        return (
            <div className="container">
                <div className="postal-code">
                    Enter your postal code to see if we deliver in your area
                </div>

                <Formik
                    enableReinitialize
                    validateOnChange={false}
                    initialValues={{
                        postalCode: this.state.postalCode,
                    }}
                    validate={(values) => {
                        const errors = {};
                        this.verifyPostalCode(values.postalCode);
                        log("validating postal code..", values, errors);
                        if (!values.postalCode) {
                            errors.postalCode = "Required";
                        }
                        return errors;
                    }}
                    onSubmit={(values, { validate, setSubmitting }) => {
                        log("onsubmit..", values, setSubmitting);
                        this.onSubmitPostalCode(); //handle on submit
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: "1.1rem",
                                    paddingBottom: "1.1rem",
                                }}
                            >
                                <input
                                    type="text"
                                    style={{
                                        fontSize: 60,
                                        width: "400px",
                                        height: "90px",
                                        textAlign: "center",
                                        maxWidth: "100%",
                                    }}
                                    className={
                                        "form-control " +
                                        (errors.postalCode ? "is-invalid" : "")
                                    }
                                    id="postalCode"
                                    name="postalCode"
                                    onChange={(e) => {
                                        handleChange(e);
                                        this.handlePostalCode(e);
                                    }}
                                    value={postalCode}
                                />
                            </div>
                            <div
                                className="row"
                                style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingBottom: "1.1rem",
                                }}
                            >
                                <button
                                    style={{ width: "200px" }}
                                    type="submit"
                                    className="btn btn-primary btn-xl btn-block"
                                    disabled={this.state.buttonDisabled}
                                >
                                    Confirm
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
                <div className="postal-code">
                    {status === "verified" && (
                        <div className="alert alert-success mb-3">
                            <FormattedMessage id="delivery.weDeliver" /> &nbsp;
                            <i
                                class="fa fa-check-circle"
                                aria-hidden="true"
                            ></i>
                        </div>
                    )}
                    {postalCode.length > 4 && status === "not_verified" && (
                        <div>
                            <div className="alert alert-danger mb-3 postal-code">
                                <i class="fas fa-exclamation-circle"></i>
                                &nbsp;
                                <FormattedMessage id="delivery.weDontDeliver" />
                            </div>
                            <div
                                className="alert alert-secondary mb-3 mb-md-5 postal-code"
                                style={{ display: "flex" }}
                            >
                                <div
                                    style={{ display: "flex" }}
                                    onClick={this.handleStoreClick}
                                >
                                    <FormattedMessage id="delivery.selectStore" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

PostalCode.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

PostalCode.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
    status: state.delivery.status,
    deliveryMethod: state.delivery.deliveryMethod,
    postalCode: state.delivery.postalCode,
});

const mapDispatchToProps = {
    postalCodeVerify,
    setPostalCode,
    setDeliveryMethod,
};

export default connect(mapStateToProps, mapDispatchToProps)(PostalCode);
