// react
import React, { useState, useEffect, useRef } from "react";

// third-party
//import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";

// data stubs
import theme from "../../data/theme";
import FacebookLogin from "react-facebook-login";
import authAPI from "../../services/auth";
import { connect } from "react-redux";
import { login } from "../../store/auth/authActions";
import firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import queryString from "query-string";
import {
    isPossiblePhoneNumber,
    parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import FacebookAuth from "../shared/account/FacebookAuth";
const log = require("../shared/helper/MartLogger.js").logger;

function AccountPageLogin(props) {
    log("AccountPageLogin - props", props);

    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "My Account", url: "" },
    ];
    let recaptcha = useRef();
    const [verificationError, setVerficationError] = useState(null);
    const [message, setMessage] = useState(null);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [credential, setCredential] = useState([
        {
            phone: "",
            country: "",
            password: "",
            domain: "",
            verifyCode: "",
        },
    ]);
    const auth = new authAPI();

    //initialize reCaptcha for phone verification
    useEffect(() => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            recaptcha,
            {
                size: "invisible",
                callback: function (response) {
                    log("reCAPTCHA response", response);
                },
            }
        );
    }, []);

    // resend the code
    const phoneReverify = (event) => {
        event.preventDefault();
        setMessage(null);
        setVerficationError(null);
        setConfirmationResult(null);
    };

    //integrate heap.io on user login
    const setHeapAnalytics = (user) => {
        if (process.env.NODE_ENV === "production") {
            window.heap.identify(user._id);
            window.heap.addUserProperties({ phone: user.phone });
            window.heap.addUserProperties({ role: user.role });
            window.heap.addUserProperties({ createdAt: user.createdAt });
            window.heap.addUserProperties({ facebookId: user.facebookId });
        }
    };
    // verify the code
    const phoneVerify = (event) => {
        event.preventDefault();
        log("phoneVerify..", credential.verifyCode);
        confirmationResult
            .confirm(credential.verifyCode)
            .then(function (result) {
                // User signed in successfully.
                log("user signed in", result);
                var user = result.user;
                auth.passwordLessLogin({ phone: user.phoneNumber }).then(
                    (response) => {
                        log("verification code verified, response", response);
                        //setHeapAnalytics(response.user);
                        setUser(response.user);
                        setToken(response.token);
                        redirect();
                    }
                );
                // ...
            })
            .catch(function (error) {
                log("user error", error);
                if (error.code == "auth/invalid-verification-code") {
                    setMessage("Invalid code. Please enter the code again.");
                    setVerficationError(error.code);
                } else if (error.code == "auth/code-expired") {
                    setMessage(
                        "The SMS code has expired. Please re-send the verification code to try again."
                    );
                    setVerficationError(error.code);
                } else {
                    setMessage(error.message);
                }
                // User couldn't sign in (bad verification code?)
                // ...
            });
    };

    // send code
    const phoneConnect = (event) => {
        event.preventDefault();
        log("phoneConnect..");

        if (isPossiblePhoneNumber(credential.phone) == false) {
            if (credential.country === "PK") {
                setMessage("The phone number format should be +923XXXXXXXXX");
            } else {
                setMessage("Invalid phone number");
            }
            return;
        }
        var phoneNumber = credential.phone;
        //'+1 650-555-3211';
        var appVerifier = window.recaptchaVerifier;
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (result) {
                log("confirmationResult", result, credential.country);
                setConfirmationResult(result);
                setMessage("Code has been sent to your mobile.");
            })
            .catch(function (error) {
                log("phone error", error, credential.country);
                if (error.code === "auth/invalid-phone-number") {
                    if (credential.country === "PK") {
                        setMessage(
                            "The phone number format should be +923XXXXXXXXX"
                        );
                    } else {
                        setMessage(error.message);
                    }
                } else {
                    setMessage(error.message);
                }
            });
    };

    // login with facebook
    /*
    const facebookConnect = () =>{

        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            log("result from firebase", result)
            log("facebookConnect")
            log("token", token)
            log("user", user.uid, user)
            
            auth.verifyFacebook(token).then(response => {
                log("facebook token verified", response)
                setUser(response.user)
                setToken(response.token)
                redirect()
                
            })
            .catch(function(error){
                log("facebook token verification failed.", error)
            })  
           
            // ...
        }).catch(function(error) {
                log("facebookConnect error", error)
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
        });
    }
*/
    //redirect page
    const redirect = () => {
        const values = queryString.parse(props.location.search);
        log("Login Page - redirect to:", values); // "top"
        if (values.source == "checkout") {
            props.history.push("/shop/checkout");
        } else {
            props.history.push("/");
        }
    };

    // login with phone and password
    const handleLogin = (event) => {
        event.preventDefault();
        log("in hangleLogin");
        auth.login(credential.phone, credential.password).then((response) => {
            log("handleLogin, response", response);
            if (response.hasOwnProperty("token")) {
                setUser(response.user);
                setToken(response.token);
                redirect();
            }
        });
    };

    const phoneInputHandle = (e) => {
        log("e.target.value", e);
        if (e && parsePhoneNumber(e.toString()))
            credential["country"] = parsePhoneNumber(e).country;
        credential["phone"] = e;
        log("phoneChangeHandle", credential);
        setCredential(credential);
    };

    //input change handle
    const inputChangeHandle = (e) => {
        log("e.target.id", e.target.id, e.target.value);
        credential[e.target.id] = e.target.value;
        log("inputChangeHandle", credential);
        setCredential(credential);
    };

    // call the redux when token is recevied
    useEffect(() => {
        log("useEffect", user, token);
        props.login(token, user); //calling redux action
    }, [user, token]);

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            {/* this div is needed for firebase reCaptcha initialization */}
            <div ref={(ref) => (recaptcha = ref)}></div>

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        Sign in with Phone Number
                                    </h3>
                                    {!confirmationResult && (
                                        <form onSubmit={phoneConnect}>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="login-email"
                                                    style={{ color: "red" }}
                                                >
                                                    {message}
                                                </label>
                                                <PhoneInput
                                                    flags={flags}
                                                    defaultCountry="PK"
                                                    onChange={(e) =>
                                                        phoneInputHandle(e)
                                                    }
                                                    type="tel"
                                                    placeholder="Enter phone number"
                                                    value={credential.phone}
                                                />
                                                {/*<div className="invalid-feedback">{message}</div>*/}
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                                            >
                                                Send Code
                                            </button>
                                        </form>
                                    )}
                                    {(confirmationResult ||
                                        verificationError) && (
                                        <form>
                                            <div className="form-group">
                                                <label
                                                    htmlFor="login-email"
                                                    style={{
                                                        color: verificationError
                                                            ? "red"
                                                            : "blue",
                                                    }}
                                                >
                                                    {message}
                                                </label>
                                                <input
                                                    id="verifyCode"
                                                    onChange={inputChangeHandle}
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Type the code here"
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="btn btn-primary mt-2 mt-md-3 mt-lg-4"
                                                onClick={phoneVerify}
                                            >
                                                Verify code
                                            </button>
                                            {
                                                <button
                                                    type="submit"
                                                    className="btn btn-secondary mt-2 mt-md-3 mt-lg-4"
                                                    onClick={phoneReverify}
                                                    style={{ float: "right" }}
                                                >
                                                    Resend code
                                                </button>
                                            }
                                        </form>
                                    )}

                                    {/*
                                        (confirmationResult || verificationError) &&
                                        <form onSubmit={phoneReverify} style={{display: 'inline'}}>
                                            <button 
                                                type="submit" 
                                                className="btn btn-secondary mt-2 mt-md-3 mt-lg-4">
                                                Send code again
                                            </button>
                                        </form>
                                    */}
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        Sign in with Facebook
                                    </h3>
                                    <FacebookAuth {...props} />
                                </div>
                            </div>
                        </div>
                    */}

                        {/*
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">Register</h3>
                                    <form>
                                        <div className="form-group">
                                            <label htmlFor="register-email">Email address</label>
                                            <input
                                                id="register-email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-password">Password</label>
                                            <input
                                                id="register-password"
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="register-confirm">Repeat Password</label>
                                            <input
                                                id="register-confirm"
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                            Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    log("calling mapStateToProps - AccountPageLogin.js,  - state", state);
    return {
        token: state.auth.token,
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    log("dispatch calling of Register action", dispatch);
    return {
        login: (token, user) => {
            dispatch(login(token, user));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLogin);
